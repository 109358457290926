import React from 'react';
import { connect } from 'react-redux';
//import { Spinner } from 'react-activity';
import Logo from '../assets/logo.svg';

const mapStateToProps = (state, ownProps) => ({
  campaignLogo: state.user.campaignLogo
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class SplashScreen extends React.Component {
  render() {
    const { state } = this.props;
    const className = state ? `screen splash ${state}` : "screen splash";

    return (
      <div className={className}>
        <div className="splash-center-wrapper">
          {/* <Spinner speed={0.8} color="#ffffff" size={20} /> */}
          <img className="logo-big" src={Logo} alt="Sounditi logo" />
          {/* <h2>what does your company<br />sound like?</h2> */}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen)
