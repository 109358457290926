import React from 'react';
import { connect } from 'react-redux';
import { Circle } from 'rc-progress';
import { Spinner } from 'react-activity';
import { TesterAccess } from '@sounditi/ft2-api';

import T from '../components/Translate';

import { setRedirect, setFullWidth } from '../reducers/navigation';
import {
  finishActiveModule,
  setResourcesLoaded,
  setFilesToSend,
  setFailedFiles,
  setAllModulesFinished,
  setUploadingError,
  setUploadedFiles,
  loadActiveModule
} from '../reducers/user';

import { blobToFile } from '../utils/globals';

import 'react-activity/dist/react-activity.css';

import {
  ANIMATION_SLIDE_IN
} from '../config/transitions';

import {
  URL_MODULES_DISPATCHER,
  URL_THANKS,
  URL_BILENDI_COMPLETE
} from '../config/urls';

import Link from '../components/Link';
import ModuleCameraPermission from '../containers/modules/ModuleCameraPermission';
import ModuleCameraCalibration from '../containers/modules/ModuleCameraCalibration';
import ModuleLargeVideo from '../containers/modules/ModuleLargeVideo';
import ModuleVideo from '../containers/modules/ModuleVideo';
import ModuleAudio from '../containers/modules/ModuleAudio';
import ModuleImage from '../containers/modules/ModuleImage';
import ModuleQA from '../containers/modules/ModuleQA';
import Thanks from '../containers/Thanks';

const mapStateToProps = (state, ownProps) => ({
  campaign: state.user.campaign,
  campaignData: state.user.campaignData,
  campaignLogo: state.user.campaignLogo,
  activeModule: state.user.activeModule,
  resourcesLoaded: state.user.resourcesLoaded,
  campaignPreviewToken: state.user.campaignPreviewToken,
  filesToSend: state.user.filesToSend,
  failedFiles: state.user.failedFiles,
  allModulesFinished: state.user.allModulesFinished,
  uploadingError: state.user.uploadingError,
  uploadedFiles: state.user.uploadedFiles,
  moduleLoaded: state.user.moduleLoaded,
  linkData: state.user.linkData,
  externalProviderID: state.user.externalProviderID,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadActiveModule: val => dispatch(loadActiveModule(val)),
  setUploadingError: val => dispatch(setUploadingError(val)),
  setUploadedFiles: val => dispatch(setUploadedFiles(val)),
  setFilesToSend: val => dispatch(setFilesToSend(val)),
  setFailedFiles: val => dispatch(setFailedFiles(val)),
  setAllModulesFinished: val => dispatch(setAllModulesFinished(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  finishActiveModule: val => dispatch(finishActiveModule(val)),
  setResourcesLoaded: val => dispatch(setResourcesLoaded(val)),
  setFullWidth: val => dispatch(setFullWidth(val)),
  dispatch,
})

class ModulesDispatcher extends React.Component {
  _uploadTextAnimation = undefined;
  _mount = true;
  _finishEventSended = false;

  constructor(props) {
    super(props);
    this.state = {
      activeModule: null,
      loadedPercent: 0,
      loadingTextAnimation: "",
      loadingError: false,
      uploadedPercent: 0,
      sessionEnd: false
    };
  }

  componentWillMount() {
    const { activeModule, resourcesLoaded } = this.props;

    this.setState({ activeModule }, () => {
      if (!resourcesLoaded) {
        this.postAction('tester_download_start');
        this.loadCampaignResources();
        // setTimeout(() => this.loadCampaignResources(), 1000);
        return false;
      }
    });

    if (this._uploadTextAnimation === undefined) {
      this._uploadTextAnimation = setInterval(() => {
        let loadingTextAnimation = this.state.loadingTextAnimation + ".";

        if (loadingTextAnimation.length > 3)
          loadingTextAnimation = ""

        if (this._mount)
          this.setState({ loadingTextAnimation });
      }, 500);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    // console.log("componentWillUpdate");

    const {
      resourcesLoaded,
      allModulesFinished,
      setAllModulesFinished,
      moduleLoaded,
      loadActiveModule,
      setFullWidth,
      dispatch,
      filesToSend, 
      uploadedFiles
    } = nextProps;
    const { uploadedPercent, sessionEnd } = nextState;

    if (resourcesLoaded && (this.state.activeModule !== nextProps.activeModule)) {
      if (moduleLoaded === false) {
        loadActiveModule();
      }

      // console.log({ uploadedFiles, filesToSend });
      // console.log( uploadedFiles && uploadedFiles.length );
      // console.log( filesToSend && filesToSend.length );

      if (nextProps.activeModule === undefined && uploadedPercent <= 0 && !allModulesFinished && !sessionEnd) {
        this.setState({ sessionEnd: true }, () => {
          const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;
          if (isFullscreen && document.exitFullscreen) {
            document.exitFullscreen();
          }

          dispatch(setAllModulesFinished()).then(() => {
            setFullWidth(false);
            //("componentWillUpdate incrementUploadPercent");
            this.incrementUploadPercent()
          });
        });
      }

      /*
      if (nextProps.activeModule && nextProps.activeModule !== {}) {
        this.goModulesDispatcher();
      } else {
        if (!nextState.allModulesFinished)
          dispatch(setAllModulesFinished()).then(() => {
            this.goModulesDispatcher()
          });

        // if (uploadedFiles.length >= filesToSend.length)
        //    this.goThanks();
      }
      */
    }
  }

  componentWillUnmount() {
    this._mount = false;
    clearInterval(this._uploadTextAnimation);
    this._uploadTextAnimation = undefined;
  }

  getResourcesCount() {
    const { campaignData } = this.props;
    const { campaignModules } = campaignData.modules;

    campaignModules.map(module => {
      module.files = Object.values({ ...module.videos, ...module.audios, ...module.images });
    });

    let resources = [];

    campaignModules.map(module => {
      module.files.map(file => {
        resources.push(file);
      });
    });

    return resources.length;
  }

  async downloadResource(file, resourcesLoadedPercentage, type) {
    const { campaignPreviewToken, linkData, campaign } = this.props;
    const testerAcessParams = campaignPreviewToken ? [ true, campaignPreviewToken ] : [];
    const testerAccess = new TesterAccess(...testerAcessParams);

    // console.log(file.fileName + "-" + linkData.linkUrl);
    let fileInformation;

    if (type === 'film') {
      // const getFileName = linkData?.linkUrl ? file.fileName + "-" + linkData.linkUrl : file.fileName;
      const compressed = linkData?.linkUrl ? false : true;

      fileInformation = await testerAccess.getOfflineFilmFile({
        fileName: file?.fileName,
        compressed,
        linkUrl: linkData?.linkUrl,
        campaignId: campaign
      }, progress => {
        const percentage =  parseInt((progress.transferred * 100) / progress.total);
        resourcesLoadedPercentage[file.fileName] = percentage;
        this.incrementLoadedPercent(resourcesLoadedPercentage);
      });
    } else {
      fileInformation = await testerAccess.getOfflineFile(file.fileName, progress => {
        const percentage =  parseInt((progress.transferred * 100) / progress.total);
        resourcesLoadedPercentage[file.fileName] = percentage;
        this.incrementLoadedPercent(resourcesLoadedPercentage);
      });
    }

    resourcesLoadedPercentage[file.fileName] = 100;
    this.incrementLoadedPercent(resourcesLoadedPercentage);

    return { ...fileInformation };
  }

  async loadCampaignResources() {
    const { campaignData, setResourcesLoaded } = this.props;
    const { campaignModules } = campaignData.modules;
    let resources = [];
    let resourcesLoadedPercentage = {};

    campaignModules.map(module => {
      module.files = Object.values({ ...module.videos, ...module.audios, ...module.images });
    });

    if (this.getResourcesCount() <= 0){
      if (!this._finishEventSended) {
        this._finishEventSended = true;
        this.postAction('tester_download_finish');
      }
      setResourcesLoaded();
    }

    try {
      await Promise.all(campaignModules.map(async module => {
        await Promise.all(module.files.map(async file => {
          try {
            resourcesLoadedPercentage[file.fileName] = 0;
            const resource = await this.downloadResource(file, resourcesLoadedPercentage, module.type);
            resources.push(resource);
          } catch { // Second try ...
            try {
              resourcesLoadedPercentage[file.fileName] = 0;
              const resource = await this.downloadResource(file, resourcesLoadedPercentage, module.type);
              resources.push(resource);
            } catch(error) { // Error
              console.log(error);
              this.setState({ loadingError: true });
            }
          }
        }));
      }));
    } catch(error) {
      console.log(error);
      this.setState({ loadingError: true });
    };
  }

  retryLoad() {
    this.setState({
      loadingError: false,
      loadedPercent: 0,
      loadingTextAnimation: ""
    }, () => this.loadCampaignResources());
  }

  incrementLoadedPercent(resourcesLoadedPercentage) {
    const { setResourcesLoaded } = this.props;
    let loadedTotalPercent = 0;

    Object.values(resourcesLoadedPercentage).map(resourse => {
      loadedTotalPercent = loadedTotalPercent + resourse;
    });

    const resourcesCount = this.getResourcesCount();
    const totalPercentage = 100 * resourcesCount;
    const loadedPercent = loadedTotalPercent / resourcesCount;

    if (loadedTotalPercent >= totalPercentage) {
      setTimeout(() => {
        if (!this._finishEventSended) {
          this._finishEventSended = true;
          this.postAction('tester_download_finish');
        }
        setResourcesLoaded();
      }, 2000);
    }

    this.setState({ loadedPercent });
  }

  goModulesDispatcher() {
    const { setRedirect } = this.props;
    const backScreen = {
      route: URL_MODULES_DISPATCHER,
      animation: ANIMATION_SLIDE_IN
    };
    setRedirect(backScreen);
  }

  goThanks() {
    const { setRedirect } = this.props;
    const backScreen = {
      route: URL_THANKS,
      animation: ANIMATION_SLIDE_IN
    };
    setRedirect(backScreen);
  }

  async sendEvent(action, activeModule, interactionId = "", currentTime = undefined, q_id, question, answeropt) {
    // console.log(activeModule);
    const isPostQuestion = action === "postquestion";
    const {
      dispatch,
      campaignPreviewToken,
      failedFiles,
      setFailedFiles,
      filesToSend,
      setUploadingError,
      setUploadedFiles,
      uploadedFiles,
      setFilesToSend
    } = this.props;

    const testerAcessParams = campaignPreviewToken ? [ true, campaignPreviewToken ] : [];
    const testerAccess = new TesterAccess(...testerAcessParams);

    const eventInformation = isPostQuestion ? { action, activeModule, interactionId, q_id, question, answeropt } : { action, activeModule, interactionId };

    if (filesToSend.filter(event => event.interactionId && event.interactionId === eventInformation.interactionId).length <= 0) {
      filesToSend.push(JSON.parse(JSON.stringify(eventInformation)));
      setFilesToSend(filesToSend);
    }

    try {
      const createdAt = new Date().getTime();
      const sessionId = sessionStorage.getItem('sessionID-info');
      const browser = sessionStorage.getItem('browser-info');
      const device = sessionStorage.getItem('device-info');

      if (isPostQuestion) {
        await testerAccess.postQuestion(activeModule.id, interactionId, q_id, question, answeropt, sessionId, createdAt);
        console.log({ postQuestion: { moduleId: activeModule.id, interactionId, q_id, question, answeropt, sessionId, createdAt } });
      } else {
        await testerAccess.postAction(action, activeModule.id, interactionId, device, browser, sessionId, createdAt, currentTime);
        console.log({ postAction: { type: action, moduleId: activeModule.id, interactionId, device, browser, sessionId, createdAt, currentTime } });
      }
      
      if (uploadedFiles.filter(event => event.interactionId && event.interactionId === eventInformation.interactionId).length <= 0) {
        uploadedFiles.push(JSON.parse(JSON.stringify(eventInformation)));
        dispatch(setUploadedFiles(uploadedFiles)).then(() => {
          this.incrementUploadPercent()
        });
      }

      const updatedFailedFiles = failedFiles.filter(event => event.interactionId && event.interactionId !== eventInformation.interactionId);
      setFailedFiles(updatedFailedFiles);
    } catch(error) {
      console.log(error);
      if (failedFiles.filter(event => event.interactionId && event.interactionId === eventInformation.interactionId).length <= 0) {
        failedFiles.push(eventInformation);
        setFailedFiles(failedFiles);
      }
      setUploadingError(true);
    };
  }

  async uploadVideoRecord(uploadClip, activeModule, fileBlob) {
    // console.log("uploadVideoRecord");
    const {
      dispatch,
      campaignPreviewToken,
      failedFiles,
      setFailedFiles,
      filesToSend,
      setUploadingError,
      uploadedFiles,
      setUploadedFiles,
      setFilesToSend
    } = this.props;

    const testerAcessParams = campaignPreviewToken ? [ true, campaignPreviewToken ] : [];
    const testerAccess = new TesterAccess(...testerAcessParams);

    if (
      activeModule &&
      activeModule.tech &&
      activeModule.tech === "recognition"
    ) {
      const fileInformation = { uploadClip, activeModule, fileBlob };
      const fileBlobName = uploadClip.interactionId;
      const interactionFile = blobToFile(fileBlob, fileBlobName);
      const interactionModuleId = uploadClip.moduleId;

      if (uploadClip && uploadClip.fileNameWithFragment)
        uploadClip.fileName = uploadClip.fileNameWithFragment;

      const interactionFileName = uploadClip.fileName;

      // console.log(filesToSend);

      // if (largeVideoFragment) {
      //   if (filesToSend.filter(file => file.uploadClip && file.uploadClip.fileNameWithFragment === largeVideoFragment).length <= 0) {
      //     filesToSend.push(JSON.parse(JSON.stringify(fileInformation)));
      //     setFilesToSend(filesToSend);
      //   }  
      // } else {
        if (filesToSend.filter(file => file.uploadClip && file.uploadClip.fileName === fileInformation.uploadClip.fileName).length <= 0) {
          filesToSend.push(JSON.parse(JSON.stringify(fileInformation)));
          setFilesToSend(filesToSend);
        }
      // }

      try {
        const createdAt = new Date().getTime();
        const sessionId = sessionStorage.getItem('sessionID-info');
        // if (Math.random() >= 0.5)
        //    throw new window.Exception();

        await testerAccess.createInteraction(interactionFile, interactionModuleId, interactionFileName, sessionId, createdAt);
        console.log({ postInteraction: { interactionFile, interactionModuleId, interactionFileName } });
        
        // if (largeVideoFragment) {
        //   if (uploadedFiles.filter(file => file.uploadClip && file.uploadClip.fileNameWithFragment === largeVideoFragment).length <= 0) {
        //     uploadedFiles.push(JSON.parse(JSON.stringify(fileInformation)));
        //     dispatch(setUploadedFiles(uploadedFiles)).then(() => {
        //       this.incrementUploadPercent()
        //     });
        //   }
        // } else {
          if (uploadedFiles.filter(file => file.uploadClip && file.uploadClip.fileName === fileInformation.uploadClip.fileName).length <= 0) {
            uploadedFiles.push(JSON.parse(JSON.stringify(fileInformation)));
            dispatch(setUploadedFiles(uploadedFiles)).then(() => {
              this.incrementUploadPercent()
            });
          }
        // }

        const updatedFailedFiles = failedFiles.filter(file => file.uploadClip && file.uploadClip.fileName !== fileInformation.uploadClip.fileName);
        setFailedFiles(updatedFailedFiles);
      } catch(error) {
        if (failedFiles.filter(file => file.uploadClip && file.uploadClip.fileName === fileInformation.uploadClip.fileName).length <= 0) {
          failedFiles.push(fileInformation);
          setFailedFiles(failedFiles);
        }
        setUploadingError(true);
      };
    }
  }

  retryUpload() {
    const { failedFiles, setUploadingError } = this.props;
    setUploadingError(false);

    failedFiles.map(file => {
      if (file.uploadClip) {
        if (file.largeVideoFragment) {
          this.uploadVideoRecord( file.uploadClip, file.activeModule, file.fileBlob, file.largeVideoFragment );
        } else {
          this.uploadVideoRecord( file.uploadClip, file.activeModule, file.fileBlob );
        }
      } else if (file.action) {
        this.sendEvent( file.action, file.activeModule, file.interactionId );
      }
    });
  }

  incrementUploadPercent() {
    const { filesToSend, allModulesFinished, uploadedFiles } = this.props;
    // const uploadedFiles = this._uploadedFiles;

    // console.log("incrementUploadPercent");

    if (allModulesFinished) {
      const percentStep = 100 / filesToSend.length;
      const uploadedPercent = uploadedFiles.length * percentStep;

      if (uploadedPercent >= 100) {
        const { externalProviderID } = this.props;
        // console.log("uploadedPercent >= 100");
        clearInterval(this._uploadTextAnimation);
        this._uploadTextAnimation = undefined;

        // External audience needs to be processed before end session
        if (!externalProviderID)
          this.postAction('tester_session_end');
      }

      this.setState({ uploadedPercent });
    }
  }

  async postAction(action, interactionId = "") {
    const { campaign, campaignPreviewToken } = this.props;
    const testerAcessParams = campaignPreviewToken ? [true, campaignPreviewToken] : [];
    const testerAccess = new TesterAccess(...testerAcessParams);
    const createdAt = new Date().getTime();
    const sessionId = sessionStorage.getItem('sessionID-info');
    const browser = sessionStorage.getItem('browser-info');
    const device = sessionStorage.getItem('device-info');

    await testerAccess.postAction(action, `${campaign}-core-finish`, interactionId, device, browser, sessionId, createdAt);
    console.log({ postAction: { type: action, moduleId: `${campaign}-core-finish`, interactionId, device, browser, sessionId, createdAt } });
  }

  render() {
    const {
      loadedPercent,
      uploadedPercent,
      loadingTextAnimation,
      loadingError,
    } = this.state;
    const {
      campaignData,
      resourcesLoaded,
      campaignLogo,
      activeModule,
      allModulesFinished,
      uploadingError,
      moduleLoaded,
      filesToSend,
      livePreviewEmbedded,
      campaignPreviewToken,
    } = this.props;

    // console.log({
    //   resourcesLoaded,
    //   activeModule
    // })

    if (!resourcesLoaded) {
      return (
        <div className="screen loading-resources">
          <div className="screen-inner-wrapper">
            <div className="flexible-top">
              {campaignData.design.generalLogoType === "image" && campaignLogo && (
                <img className="logo-big" src={campaignLogo} alt="Sounditi logo" />
              )}
              {campaignData.design.generalLogoType === "text" &&
              campaignData.design.generalLogoText && (
                <div className="flexible-center">
                  <div className="logo-text">{campaignData.design.generalLogoText}</div>
                </div>
              )}
            </div>
            <div className="fixed-bottom">
              {!loadingError && (
                <div className="loading-resources-box">
                  <div className="loading-resources-text">
                    {loadedPercent < 100 && (
                      <>
                        <h1><T text="ModuleDispatcher_Loading_Title" /> {parseInt(loadedPercent)}%</h1>
                        <p><T text="ModuleDispatcher_Loading_Text" /></p>
                        <p className="animated">{loadingTextAnimation}</p>
                      </>
                    )}
                    {loadedPercent >= 100 && (
                      <h1><T text="ModuleDispatcher_Loading_Finish" /></h1>
                    )}
                  </div>
                  <Circle percent={loadedPercent} strokeWidth="4" trailWidth="4" strokeColor="#278DF4" />
                </div>
              )}
              {loadingError && (
                <>
                  <h1><T text="ModuleDispatcher_Loading_Error_Title" /></h1>
                  <p><T text="ModuleDispatcher_Loading_Error_Text" /></p>
                  <Link onClick={() => this.retryLoad()}><T text="ModuleDispatcher_Loading_Error_CTA" /></Link>
                </>
              )}
            </div>
          </div>
        </div>
      )
    }
    if (!campaignPreviewToken && (uploadedPercent < 100 && allModulesFinished)) {
      return (
        <div className="screen loading-resources">
          <div className="screen-inner-wrapper">
            <div className="flexible-top">
              {campaignData.design.generalLogoType === "image" && campaignLogo && (
                <img className="logo-big" src={campaignLogo} alt="Sounditi logo" />
              )}
              {campaignData.design.generalLogoType === "text" &&
              campaignData.design.generalLogoText && (
                <div className="flexible-center">
                  <div className="logo-text">{campaignData.design.generalLogoText}</div>
                </div>
              )}
            </div>
            <div className="fixed-bottom">
              {!uploadingError && (
                <div className="loading-resources-box">
                  <div className="loading-resources-text">
                    {uploadedPercent < 100 && (
                      <>
                        <h1><T text="ModuleDispatcher_Uploading_Title" /> {parseInt(uploadedPercent)}%</h1>
                        <p><T text="ModuleDispatcher_Uploading_Text" /></p>
                        <p className="animated">{loadingTextAnimation}</p>
                      </>
                    )}
                    {uploadedPercent >= 100 && (
                      <h1><T text="ModuleDispatcher_Uploading_Finish" /></h1>
                    )}
                  </div>
                  <Circle percent={uploadedPercent} strokeWidth="4" trailWidth="4" strokeColor="#278DF4" />
                </div>
              )}
              {uploadingError && (
                <>
                  <h1><T text="ModuleDispatcher_Uploading_Error_Title" /></h1>
                  <p><T text="ModuleDispatcher_Uploading_Error_Text" /></p>
                  <Link onClick={() => this.retryUpload()}><T text="ModuleDispatcher_Uploading_Error_CTA" /></Link>
                </>
              )}
            </div>
          </div>
        </div>
      )
    }

    if (moduleLoaded && activeModule && activeModule.type === "camera_permission") {
      return <ModuleCameraPermission 
        campaignData={campaignData} 
        onEventSend={
          (action, activeModule, interactionId) =>
            this.sendEvent(action, activeModule, interactionId)
        }
      />
    }

    if (moduleLoaded && activeModule && activeModule.type === "camera_calibration") {
      return <ModuleCameraCalibration 
        campaignData={campaignData} 
        onEventSend={
          (action, activeModule, interactionId) =>
            this.sendEvent(action, activeModule, interactionId)
        }
      />
    }

    if (moduleLoaded && activeModule && activeModule.type === "video") {
      return <ModuleVideo
        onUploadVideoRecord={
          (uploadClip, activeModule, fileBlob) =>
            this.uploadVideoRecord(uploadClip, activeModule, fileBlob)
        }
        onEventSend={
          (action, activeModule, interactionId, currentTime, q_id, question, answeropt) =>
            this.sendEvent(action, activeModule, interactionId, currentTime, q_id, question, answeropt)
        } />
    }

    if (moduleLoaded && activeModule && activeModule.type === "film") {
      return <ModuleLargeVideo
        onUploadVideoRecord={
          (uploadClip, activeModule, fileBlob, largeVideoFragment) =>
            this.uploadVideoRecord(uploadClip, activeModule, fileBlob, largeVideoFragment)
        }
        onEventSend={
          (action, activeModule, interactionId, currentTime) =>
            this.sendEvent(action, activeModule, interactionId, currentTime)
        } />
    }

    if (moduleLoaded && activeModule && activeModule.type === "audio") {
      return <ModuleAudio
        onUploadVideoRecord={
          (uploadClip, activeModule, fileBlob) =>
            this.uploadVideoRecord(uploadClip, activeModule, fileBlob)
        }
        onEventSend={
          (action, activeModule, interactionId, currentTime, q_id, question, answeropt) =>
            this.sendEvent(action, activeModule, interactionId, currentTime, q_id, question, answeropt)
        } />
    }

    if (moduleLoaded && activeModule && activeModule.type === "image") {
      return <ModuleImage
        onUploadVideoRecord={
          (uploadClip, activeModule, fileBlob) =>
            this.uploadVideoRecord(uploadClip, activeModule, fileBlob)
        }
        onEventSend={
          (action, activeModule, interactionId, currentTime, q_id, question, answeropt) =>
            this.sendEvent(action, activeModule, interactionId, currentTime, q_id, question, answeropt)
        } />
    }

    if (moduleLoaded && activeModule && activeModule.type === "qa") {
      return <ModuleQA
        onEventSend={
          (action, activeModule, interactionId, currentTime, q_id, question, answeropt) =>
            this.sendEvent(action, activeModule, interactionId, currentTime, q_id, question, answeropt)
        } />
    }

    if (livePreviewEmbedded === "true" || ((uploadedPercent >= 100 && allModulesFinished) || (allModulesFinished && filesToSend <= 0) || campaignData.modules.campaignModules.length < 1)) {
      return <Thanks />
    }

    return (
      <div className="screen loader">
        <Spinner speed={0.8} color="#ffffff" size={20} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModulesDispatcher)
