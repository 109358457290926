const color = 'rgba(0, 255, 255, 1)';
const lineWidth = 1;
const pointSize = 2;

export function drawPoint(ctx, pt) {
  ctx.beginPath();
  ctx.arc(pt.x, pt.y, pointSize, 0, 2 * Math.PI);
  ctx.fill();
};

export function drawLine(ctx, pointStart, pointEnd) {
  ctx.beginPath();
  ctx.moveTo(pointStart.x, pointStart.y);
  ctx.lineTo(pointEnd.x, pointEnd.y);
  ctx.stroke();
}

export function drawContour(ctx, points, isClosed) {
  if (isClosed === void 0) { isClosed = false; }
  ctx.beginPath();
  points.slice(1).forEach((_a, index) => {
    let x = _a.x, y = _a.y;
    let from = points[index];
    ctx.moveTo(from.x, from.y);
    ctx.lineTo(x, y);
  });
  if (isClosed) {
    let from = points[points.length - 1];
    let to = points[0];
    if (!from || !to) {
        return;
    }
    ctx.moveTo(from.x, from.y);
    ctx.lineTo(to.x, to.y);
  }
  ctx.stroke();
}

export function canvasClear(ctx, videoOverlay) {
  ctx.clearRect(0, 0, videoOverlay.width, videoOverlay.height);
}

export function canvasInitialize(videoOverlay) {
  const ctx = videoOverlay.getContext('2d');
  ctx.strokeStyle = color;
  ctx.fillStyle = color;
  ctx.lineWidth = lineWidth;

  return ctx;
}