import React from 'react';
import { connect } from 'react-redux';
import { setRedirect } from '../reducers/navigation';
import Tracker from '../api/tracker';

import {
  ANIMATION_SLIDE_OUT
} from '../config/transitions';

import iconBack from '../assets/icon-left-arrow.png';
import '../styles/components/Back.scss';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val))
})

class Back extends React.Component {
  onClick(e) {
    const { onClick, to, animation, setRedirect } = this.props;
    const redirect = {
      route: to,
      animation: animation
    };
    Tracker.action("back_click");

    if (!redirect.animation)
      redirect.animation = ANIMATION_SLIDE_OUT;

    this.addRippleEffect(e);

    if (onClick) {
      onClick(e);
      if (!to)
        return false;
    }

    setRedirect(redirect);
  }

  addRippleEffect(e) {
    let target = e.target;
    if (target.tagName.toLowerCase() !== 'button') return false;
    let rect = target.getBoundingClientRect();
    let ripple = target.querySelector('.ripple');
    if (!ripple) {
      ripple = document.createElement('span');
      ripple.className = 'ripple';
      ripple.style.height = ripple.style.width = Math.max(rect.width, rect.height) + 'px';
      target.appendChild(ripple);
    }
    ripple.classList.remove('show');
    let top = e.pageY - rect.top - ripple.offsetHeight / 2 - document.body.scrollTop;
    let left = e.pageX - rect.left - ripple.offsetWidth / 2 - document.body.scrollLeft;
    ripple.style.top = top + 'px';
    ripple.style.left = left + 'px';
    ripple.classList.add('show');
    return false;
  }

  render() {
    return (
      <button
        type="button"
        className="button-back"
        onClick={e => this.onClick(e)}
      ><i className="icon-left-open-big"></i></button>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Back)