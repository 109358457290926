import React from 'react';
import { connect } from 'react-redux';
import { clearNotification } from '../reducers/notifications';
import T from '../components/Translate';

import '../styles/components/Notification.scss';
import tracker from '../api/tracker';

const mapStateToProps = (state, ownProps) => ({
  showNotification: state.notifications.showNotification,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  clearNotification: val => dispatch(clearNotification(val)),
  dispatch,
})
class Notifications extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      visible: false,
      notification: undefined,
    }
  }

  componentDidUpdate() {
    const { showNotification, clearNotification } = this.props;
    const notification = showNotification;

    if (showNotification) {
      this.props.dispatch(clearNotification()).then(()=>{
        this.show(notification);
      })
    }
  }

  show(notification) {
    this.setState({ visible: true, notification });

    tracker.event(notification)

    setTimeout(() => {
      this.setState({ visible: false });
    }, 3000)
  }

  renderNotificationText() {
    const { notification } = this.state;

    switch (notification) {
      case "invalidData": {
        return <p><T text="ErrorNotification_InvalidData" /></p>
      }
      case "invalidEmail": {
        return <p><T text="ErrorNotification_InvalidEmail" /></p>
      }
      case "connectionError": {
        return <p><T text="ErrorNotification_ConnectionError" /></p>
      }
      case "genericError": {
        return <p><T text="ErrorNotification_GenericError" /></p>
      }
      case "invalidCode": {
        return <p><T text="ErrorNotification_InvalidCode" /></p>
      }
      case "invalidToken": {
        return <p><T text="ErrorNotification_InvalidToken" /></p>
      }
      case "backRestriction": {
        return <p><T text="ErrorNotification_BackRestriction" /></p>
      }
      default:
        return ""
    }
  }

  render() {
    const { showNotification } = this.props;
    const { visible } = this.state;
    const componentClass = visible ? "notification visible" : "notification";

    return (
      <div className={componentClass}>
        <div className="hide">{showNotification}</div>
        {this.renderNotificationText()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)