import React from 'react';

import '../styles/components/ExperienceProgress.scss';

class ExperienceProgress extends React.Component {
  renderClassStep(step) {
    const { activeStep, choose } = this.props;
    let stepClass = "step";

    if (activeStep === step)
      stepClass = stepClass + " active";

    if (activeStep > step && !choose)
      stepClass = stepClass + " finished";

      return stepClass;
  }

  renderSteps() {
    const { totalSteps } = this.props;
    const steps = []

    for (let i = 1; i <= totalSteps; i++) {
      steps.push(<div key={i} className={this.renderClassStep(i)}></div>)
    }

    return steps;
  }

  render() {
    return (
      <div className="experience-progress">
        {this.renderSteps()}
      </div>
    );
  }
}

export default ExperienceProgress