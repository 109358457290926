const initState = {
  redirect: undefined,
  fullWidth: false,
};

export const setFullWidth = payload => ({
  type: 'SET_FULLWIDTH',
  payload,
});
export const setRedirect = payload => ({
  type: 'SET_REDIRECT',
  payload,
});
export const clearRedirect = () => ({
  type: 'CLEAR_REDIRECT',
});

const navigation = (state = initState, action) => {
  switch (action.type) {
    case 'SET_FULLWIDTH':
      const fullWidth = action.payload;
      return { state, fullWidth }
    case 'SET_REDIRECT':
      const redirect = action.payload;
      return { state, redirect }
    case 'CLEAR_REDIRECT':
      return { state, redirect: undefined }
    default:
      return state
  }
}

export default navigation