// TODO: add callback and renovate enviroments... remove production until ready.
const mock = {
  AZURE_FUCTIONS_HOST: 'http://127.0.0.1:3002',
  AZURE_TRACKER_HOST: 'http://127.0.0.1:3002',
  STORAGE_ACCOUNT: 'sounditiclips',
  USERS_STORAGE_ACCOUNT: 'sounditiusersdata',
  STORAGE_CLIPS_CONTAINER: 'songs'
};
const dev = {
  AZURE_FUCTIONS_HOST: 'https://localhost:7071/api',
  AZURE_TRACKER_HOST: 'https://localhost:7071/api',
  STORAGE_ACCOUNT: 'sounditiclips',
  USERS_STORAGE_ACCOUNT: 'sounditiusersdata',
  STORAGE_CLIPS_CONTAINER: 'songs'
};
const stag = {
  AZURE_FUCTIONS_HOST: 'https://snd-ib-mvp.azurewebsites.net/api',
  AZURE_TRACKER_HOST: 'https://snd-ib-mvp.azurewebsites.net/api',
  STORAGE_ACCOUNT: 'sounditiclips',
  USERS_STORAGE_ACCOUNT: 'sounditiusersdata',
  STORAGE_CLIPS_CONTAINER: 'songs'
};

const prod = {
  AZURE_FUCTIONS_HOST: 'https://snd-ib.azurewebsites.net/api',
  AZURE_TRACKER_HOST: 'https://snd-ib.azurewebsites.net/api',
  USERS_STORAGE_ACCOUNT: 'sounditiusersdata',
  STORAGE_ACCOUNT: 'sounditiclips',
  STORAGE_CLIPS_CONTAINER: 'songs'
};

// let config = dev;
let config = mock;
/*
switch (process.env.REACT_APP_STAGE) {
  case 'stag':
    config = stag;
    break;
  case 'prod':
    config = prod;
    break;
  default:
    break;
}
*/

module.exports = config;
