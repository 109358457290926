import React from 'react';
import { connect } from 'react-redux';
import { setRedirect } from '../reducers/navigation';

import {
  ANIMATION_SLIDE_IN
} from '../config/transitions';

import iconEmail from '../assets/icon-email.png';
import iconFacebook from '../assets/icon-facebook.png';
import iconGoogle from '../assets/icon-google.png';

import '../styles/components/Link.scss';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val))
})

class Link extends React.Component {
  onClick(e) {
    const { onClick, to, animation, setRedirect } = this.props;
    const redirect = {
      route: to,
      animation: animation
    };

    if (!redirect.animation)
      redirect.animation = ANIMATION_SLIDE_IN;

    this.addRippleEffect(e);

    if (onClick) {
      onClick(e);
      if (!to)
        return false;
    }

    setRedirect(redirect);
  }

  addRippleEffect(e) {
    let target = e.target;
    if (target.tagName.toLowerCase() !== 'button') return false;
    let rect = target.getBoundingClientRect();
    let ripple = target.querySelector('.ripple');
    if (!ripple) {
      ripple = document.createElement('span');
      ripple.className = 'ripple';
      ripple.style.height = ripple.style.width = Math.max(rect.width, rect.height) + 'px';
      target.appendChild(ripple);
    }
    ripple.classList.remove('show');
    let top = e.pageY - rect.top - ripple.offsetHeight / 2 - document.body.scrollTop;
    let left = e.pageX - rect.left - ripple.offsetWidth / 2 - document.body.scrollLeft;
    ripple.style.top = top + 'px';
    ripple.style.left = left + 'px';
    ripple.classList.add('show');
    return false;
  }

  render() {
    const {
      children,
      className,
      icon,
      disabled,
      type,
      inline
    } = this.props;

    let buttonClass = type ? type : "button-primary"

    if (inline)
      buttonClass = "inline";

    buttonClass = className ? `${buttonClass} ${className}` : buttonClass;

    if (icon) {
      return (
        <button
          disabled={disabled}
          type="button"
          className={buttonClass}
          onClick={e => this.onClick(e)}
        >
          <div className="button-icon">
            {icon === 'email' && (
              <img src={iconEmail} alt="icono email" />
            )}
            {icon === 'facebook' && (
              <img src={iconFacebook} alt="icono facebook" />
            )}
            {icon === 'google' && (
              <img src={iconGoogle} alt="icono google" />
            )}
          </div>
          {children}
        </button>
      );
    }

    return (
      <button
        disabled={disabled}
        type="button"
        className={buttonClass}
        onClick={e => this.onClick(e)}
      ><div className="button-text">{children}</div></button>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Link)