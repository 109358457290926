import React from 'react';
import { connect } from 'react-redux';
import { setRedirect, setFullWidth } from '../../reducers/navigation';
import { finishActiveModule } from '../../reducers/user';
import { rgba_to_grayscale, loadModels, getFace, isModelsLoaded } from '../../utils/faceRecognition';
import { canvasInitialize } from '../../utils/canvasFunctions';
import { printMesh } from '../../utils/canvasPrintMesh';
import { Spinner } from 'react-activity';
import Link from '../../components/Link';
import Crosshair from '../../components/Crosshair';
import T from '../../components/Translate';
import FaceFinder from '../../assets/cascades/facefinder';

import {
  ANIMATION_SLIDE_IN,
  ANIMATION_SLIDE_OUT
} from '../../config/transitions';

import {
  URL_REQUEST_ACCESS_CAMERA,
  URL_EXPERIENCE,
  URL_THANKS,
  URL_INCOMPATIBLE_BROWSER
} from '../../config/urls';

const mapStateToProps = (state, ownProps) => ({
  campaignLogo: state.user.campaignLogo,
  activeModule: state.user.activeModule,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  setFullWidth: val => dispatch(setFullWidth(val)),
  finishActiveModule: val => dispatch(finishActiveModule(val)),
})

let pictureCountdown = undefined;
class ModuleCameraCalibration extends React.Component {
  _mounted = true;
  _video = undefined;
  _requestLoop = undefined;
  _swiper = undefined;
  _loop = () => {};
  _update_memory = window.pico.instantiate_detection_memory(5);
  _facefinder_classify_region = function(r, c, s, pixels, ldim) {return -1.0;};

  constructor(props) {
    super(props);
    this.state = {
      permissionDenied: false,
      recognizingFace: false,
      pictureTaken: false,
      videoHeight: 720,
      videoWidth: 1280,
      videoRatio: 'landscape',
      loading: true,
      loadingVideoRatio: true,
      processCanvasWidth: 480,
      processCanvasHeight: 480,
      loadingMesh: false,
      recognitionLoopRunning: false,
      videoContainerWidth: null,
      videoContainerHeight: null,
      videoHeightRaw: null,
      videoWidthRaw: null
    };
  }

  componentWillMount() {
    const { setFullWidth } = this.props;

    loadModels();
    this.loadRecognitionModels();
    setFullWidth(true);
  }

  componentDidMount() {
    setTimeout(() => {
      this.startVideo();
    }, 1000);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  async postAction(action, interactionId = "") {
    const { activeModule, onEventSend } = this.props;
    onEventSend(action, activeModule, interactionId);
  }

  stopAll() {
    const { finishActiveModule } = this.props;
    this._mounted = false;
    window.cancelAnimationFrame(this._loop);
    this.stopPictureCountdown();
    this.stopVideo(() => {
      this.setState({
        permissionDenied: false,
        recognizingFace: false,
        pictureTaken: false,
        videoHeight: 720,
        videoWidth: 1280,
        videoRatio: 'landscape',
      }, () => {
        this.postAction('tester_calibration_finish');
        finishActiveModule();
      });
    });
  }

  startRecognitionLoop() {
    const { recognitionLoopRunning } = this.state;

    if (recognitionLoopRunning) {
      this._loop = () => {
        if (recognitionLoopRunning){
          this.runFaceRecognition();

          this._requestLoop = requestAnimationFrame(this._loop);
        }
      }
      this._requestLoop = requestAnimationFrame(this._loop);
    }
  }

  startVideo() {
    if (this._mounted) {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        this.postAction('tester_calibration_incompatible_browser');
        this.goCameraInaccessible();
        return false;
      }

      const { loading } = this.state;
      const videoSettings = { audio: false, video: true };
      this._video = document.getElementById('camera');

      if (this._video) {
        navigator.mediaDevices.getUserMedia(videoSettings).then(stream => {
          window.localStream = stream;
          this._video = document.getElementById('camera');
          this._video.srcObject = stream;

          this.setState({ recognitionLoopRunning: true }, () => this.startRecognitionLoop());

          if (loading)
            this.setState({ loading: false });

          this._video.oncanplay = () => {
            this.adjustCanvasSize();
          }

          this.postAction('tester_calibration_start');
        })
        .catch(error => {
          this.goCameraInaccessible();
        });
      }
    }
  }

  loadRecognitionModels() {
    var cascadeurl = FaceFinder;
    fetch(cascadeurl).then(response => {
      response.arrayBuffer().then(buffer => {
        var bytes = new Int8Array(buffer);
        this._facefinder_classify_region = window.pico.unpack_cascade(bytes);
      })
    })
  }

  runFaceRecognition() {
    const {
      pictureTaken,
      loadingMesh,
      processCanvasHeight,
      processCanvasWidth
    } = this.state;
    const processCanvas = document.getElementById('process-canvas');

    if (this._video && this._video.offsetWidth && this._video.offsetHeight){
      const videoWidth = this._video.offsetWidth;
      const videoHeight = this._video.offsetHeight;

      if (this._video && processCanvas && !pictureTaken && !loadingMesh) {
        const ctx = processCanvas.getContext('2d');
        ctx.drawImage(this._video, 0, 0, processCanvasWidth, processCanvasHeight);
        var rgba = ctx.getImageData(0, 0, videoWidth, videoHeight).data;

        let image = {
          "pixels": rgba_to_grayscale(rgba, videoHeight, videoWidth),
          "nrows": videoHeight,
          "ncols": videoWidth,
          "ldim": videoWidth
        }
        let params = {
          "shiftfactor": 0.1,
          "minsize": 100,
          "maxsize": 1000,
          "scalefactor": 1.1
        }

        let dets = window.pico.run_cascade(image, this._facefinder_classify_region, params);
        dets = this._update_memory(dets);
        dets = window.pico.cluster_detections(dets, 0.2); // set IoU threshold to 0.2

        let detected = false;
        if (dets.length) {
          for (let i = 0; i < dets.length; ++i) {
            if (dets[i][3] > 50.0) {
              detected = true;
            }
          }
        }

        if (detected) {
          this.setState({ recognizingFace: true });
          this.startPictureCountdown();
        } else {
          this.setState({ recognizingFace: false });
          this.stopPictureCountdown();
        }
      }
    }
  }

  stopVideo(callback) {
    if (this._video) {
      this._video.pause();
      this._video.src = null;
    }
    if (window.localStream) {
      window.localStream.getTracks().forEach((track) => {
        track.stop();
      })
    }

    if (callback)
      callback()
  }

  adjustCanvasSize = (callback) => {
    if (this._mounted) {
      let videoWidth = window.innerWidth + "px";
      let videoHeight = "auto";

      this.setState({ videoWidth, videoHeight }, () => {
        if (window.innerHeight > this._video.offsetHeight) {
          videoWidth = "auto";
          videoHeight = window.innerHeight + "px";

          this.setState({ videoWidth, videoHeight }, () => {
            this.adjustCanvasSizeB(callback);
          });
        } else {
          this.adjustCanvasSizeB(callback);
        }
      });
    }
  }

  adjustCanvasSizeB = (callback) => {
    const { processCanvasHeight, processCanvasWidth } = this.state;

    if (this._video && this._video.offsetWidth && this._video.offsetHeight){
      const videoWidth = this._video.offsetWidth;
      const videoHeight = this._video.offsetHeight;

      if (this._video.videoHeight > this._video.videoWidth) {
        const ratio = processCanvasWidth / videoWidth;
        this.setState({
          loadingVideoRatio: false,
          videoRatio: "portrait",
          processCanvasHeight: videoHeight * ratio,
          videoContainerWidth: videoWidth,
          videoContainerHeight: videoHeight * ratio,
          videoHeightRaw: videoHeight,
          videoWidthRaw: videoWidth
        });
      } else {
        const ratio = processCanvasHeight / videoHeight;
        this.setState({
          loadingVideoRatio: false,
          processCanvasWidth: videoWidth * ratio,
          videoContainerWidth: videoWidth * ratio,
          videoContainerHeight: videoHeight,
          videoHeightRaw: videoHeight,
          videoWidthRaw: videoWidth
        });
      }

      if (callback)
        callback();
    }
  }



  startPictureCountdown() {
    if (this._mounted) {
      if (pictureCountdown === undefined) {
        pictureCountdown = setTimeout(() => {
          // this.takePicture();
          this.stopAll();
        }, 5000);
      }
    }
  }

  stopPictureCountdown() {
    clearInterval(pictureCountdown);
    pictureCountdown = undefined;
  }

  takePicture() {
    if (this._mounted) {
      const pictureOverlay = document.getElementById('picture-canvas');
      const pictureOverlayMesh = document.getElementById('overlay-mesh');

      if (!isModelsLoaded)
        this.takePicture();

      if (this._video && pictureOverlay && pictureOverlayMesh){
        this.adjustCanvasSize(() => {
          const ctx = pictureOverlay.getContext("2d");
          ctx.drawImage(this._video, 0, 0, pictureOverlay.width, pictureOverlay.height);

          cancelAnimationFrame(this._requestLoop);

          this.setState({ loadingMesh: true, recognitionLoopRunning: false }, () => {
            setTimeout(() => {
              getFace(pictureOverlay, pictureOverlayMesh, 512).then(result => {
                const ctxMesh = canvasInitialize(pictureOverlayMesh);

                if (result && result.landmarks && result.landmarks.positions) {
                  const points = result.landmarks.positions;
                  printMesh(ctxMesh, points);
                  // this.stopVideo();
                }

                this.setState({ pictureTaken: true, loadingMesh: false });
              }).catch(() => {
                this.setState({ pictureTaken: true, loadingMesh: false });
              });
            }, 100);
          });
        });
      }
    }
  }

  /* SILLY FUNCTIONS */

  goRequestAccessCamera() {
    const { setRedirect } = this.props;
    const screen = {
      route: URL_REQUEST_ACCESS_CAMERA,
      animation: ANIMATION_SLIDE_OUT
    };
    setRedirect(screen);
  }

  goCameraInaccessible() {
    const { setRedirect } = this.props;
    const screen = {
      route: URL_INCOMPATIBLE_BROWSER,
      animation: ANIMATION_SLIDE_OUT
    };
    setRedirect(screen);
  }

  render() {
    const {
      recognizingFace,
      pictureTaken,
      videoWidth,
      videoHeight,
      videoWidthRaw,
      videoHeightRaw,
      videoRatio,
      loading,
      processCanvasHeight,
      processCanvasWidth,
      loadingMesh,
      loadingVideoRatio
    } = this.state;
    const { campaignLogo, campaignData } = this.props;

    const screenClass = recognizingFace
      ? "screen camera-calibration analizing-face"
      : "screen camera-calibration";

    const canvasClass = (pictureTaken || loadingMesh) ? "overlay apply-filter" : "overlay";

    const progressBarClass = (recognizingFace && pictureCountdown !== undefined)
      ? "progress-bar-wrapper active"
      : "progress-bar-wrapper";

    let videoClass = videoRatio === "landscape"
    ? "camera-fullscreen black-and-white landscape"
    : "camera-fullscreen black-and-white portrait";

    videoClass = (pictureTaken || loadingMesh) ? `${videoClass} no-opacity` : videoClass;

    return (
      <div className={screenClass}>
        {(loading || loadingVideoRatio) && (
          <div className="screen loader">
            <Spinner speed={0.8} color="#ffffff" size={20} />
          </div>
        )}
        <div className="camera-wrapper">
          <canvas
            className="overlay mesh"
            id="overlay-mesh"
            width={videoWidthRaw}
            height={videoHeightRaw}
            style={{ width: videoWidthRaw, height: videoHeightRaw }}
          />
          <canvas
            className={canvasClass}
            id="overlay"
            width={videoWidthRaw}
            height={videoHeightRaw}
            style={{ width: videoWidthRaw, height: videoHeightRaw }}
          />
          <canvas
            className="overlay picture black-and-white"
            id="picture-canvas"
            width={videoWidthRaw}
            height={videoHeightRaw}
            style={{ width: videoWidthRaw, height: videoHeightRaw }}
          />
          <canvas
            className="hide"
            id="process-canvas"
            width={processCanvasWidth}
            height={processCanvasHeight}
            style={{ width: processCanvasWidth, height: processCanvasHeight }}
          />
          <video
            disablePictureInPicture
            onContextMenu={(e)=> e.preventDefault()}
            autoPlay
            playsInline
            muted
            id="camera"
            className={videoClass}
            width={processCanvasWidth}
            height={processCanvasHeight}
            style={{ width: videoWidth, height: videoHeight }}
          />
        </div>
        {!pictureTaken && (
          <div className="layout-overlay">
            <div className="flexible-top">
              {/*campaignData.design.generalLogoType === "image" && campaignLogo && (
                <img className="logo-big" src={campaignLogo} alt="Sounditi logo" />
              )*/}
              {/*campaignData.design.generalLogoType === "text" &&
              campaignData.design.generalLogoText && (
                <div className="flexible-center">
                  <div className="logo-text">{campaignData.design.generalLogoText}</div>
                </div>
              )*/}
              <div className="vertical-center">
                {!loadingMesh && (
                  <Crosshair />
                )}
                {loadingMesh && (
                  <Spinner speed={0.8} color="#ffffff" size={20} />
                )}
              </div>
            </div>
            <div className="fixed-bottom">
              <div className={progressBarClass}>
                <div className="progress-bar"></div>
              </div>
              <div className="camera-bottom">
                {!recognizingFace && !loadingMesh && (
                  <p><T text="CameraCalibration_Instructions_a" /></p>
                )}
                {recognizingFace && !loadingMesh && (
                  <p><T text="CameraCalibration_Instructions_b" /></p>
                )}
                {loadingMesh && (
                  <p><T text="CameraCalibration_Instructions_c" /></p>
                )}
              </div>
            </div>
          </div>
        )}
        {pictureTaken && (
          <div className="layout-overlay with-padding">
            <div className="float-top">
              {/*campaignData.design.generalLogoType === "image" && campaignLogo && (
                <img className="logo-big" src={campaignLogo} alt="Sounditi logo" />
              )*/}
              {/*campaignData.design.generalLogoType === "text" &&
              campaignData.design.generalLogoText && (
                <div className="flexible-center">
                  <div className="logo-text">{campaignData.design.generalLogoText}</div>
                </div>
              )*/}
            </div>
            <div className="flexible-top"></div>
            <div className="fixed-bottom">
              <div>
                <p><T text="CameraCalibration_Description" /></p>
                <Link onClick={() => this.stopAll()}><T text="CameraCalibration_CTA" /></Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleCameraCalibration)
