import React from 'react';
import { connect } from 'react-redux';
import { finishActiveModule } from '../../reducers/user';
import { setRedirect, setFullWidth } from '../../reducers/navigation';
import Link from '../../components/Link';
import T from '../../components/Translate';

const mapStateToProps = (state, ownProps) => ({
  campaignLogo: state.user.campaignLogo,
  activeModule: state.user.activeModule,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  finishActiveModule: val => dispatch(finishActiveModule(val)),
  setFullWidth: val => dispatch(setFullWidth(val)),
})

class ModuleCameraPermission extends React.Component {
  constructor(props) {
    super(props);
    this.state = { permissionDenied: false };
  }

  componentWillMount() {
    const { setFullWidth } = this.props;

    setFullWidth(false);

    this.postAction('tester_camera_access_requested');
  }

  async postAction(action, interactionId = "") {
    const { activeModule, onEventSend } = this.props;
    onEventSend(action, activeModule, interactionId);
  }

  render() {
    const { permissionDenied } = this.state;
    const { finishActiveModule, campaignLogo, campaignData } = this.props;

    return (
      <div className="screen request-access-camera">
        <div className="screen-inner-wrapper">
          <div className="flexible-top">
            {campaignData.design.generalLogoType === "image" && campaignLogo && (
              <img className="logo-big" src={campaignLogo} alt="Sounditi logo" />
            )}
            {campaignData.design.generalLogoType === "text" &&
            campaignData.design.generalLogoText && (
              <div className="flexible-center">
                <div className="logo-text">{campaignData.design.generalLogoText}</div>
              </div>
            )}
          </div>
          <div className="fixed-bottom">
            <h1><T text="RequestAccessCamera_Title" /></h1>
            <p><T text="RequestAccessCamera_Description" /></p>
            {permissionDenied && (
              <p className="message-black"><T text="RequestAccessCamera_Denied_camera" /></p>
            )}
            {!permissionDenied && (
              <Link onClick={e => {
                this.postAction('tester_camera_access_granted');
                finishActiveModule();
              }}><T text="RequestAccessCamera_CTA" /></Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleCameraPermission)
