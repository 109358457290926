import React from 'react';
import { connect } from 'react-redux';

import { getPreviewURLParameter } from '../utils/globals';


const mapStateToProps = (state, ownProps) => ({
  campaignData: state.user.campaignData,
  campaignBackground: state.user.campaignBackground,
  campaignBackgroundLanding: state.user.campaignBackgroundLanding,
  externalProviderID: state.user.externalProviderID,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class RenderStyles extends React.Component {
  render() {
    const { campaignData, campaignBackground, campaignBackgroundLanding, externalProviderID } = this.props;

    if (!campaignData || !campaignData.design) {
      return (<div></div>);
    }

    /*
    if (externalProviderID) {
      return (
        <style>
          {`
            body {
              color: #1C1D2E;
            }

            .desktop-cover {
              background-color: #ffffff;
            }

            .screen .button-back i {
              color: #1C1D2E;
            }

            .screen {
              background-color: #ffffff;
            }

            .screen.policy-and-conditions p,
            .screen.policy-and-conditions ul,
            .screen.policy-and-conditions ul li,
            .screen.policy-and-conditions table {
              color: #1C1D2E;
            }

            .screen.policy-and-conditions h1,
            .screen.policy-and-conditions h2,
            .screen.policy-and-conditions h3 {
              color: #00CDEE;
            }

            .screen .progress-bar {
              background-color: #00CDEE;
            }

            .screen .loading-resources-text p.animated {
              color: #00CDEE;
            }

            .screen.module-qa {
              background-image: none;
            }

            .screen .rc-progress-circle-path {
              stroke: #00CDEE !important;
            }

            .screen .rc-progress-circle-trail {
              stroke: #00CDEE40 !important;
            }

            .screen .rai-spinner-outer{
              border-color: #00CDEE !important;
            }

            .screen .rai-spinner-inner {
              border-top-color: #00CDEE !important;
            }

            .screen .camera-bottom,
            .screen .desktop-cover {
              color: #00CDEE;
              background-color: #ffffff;
            }

            .screen h1,
            .screen .qa-question-wrapper h1,
            .screen .logo-text {
              color: #1C1D2E;
            }

            .screen .overlay.apply-filter {
              background: linear-gradient(140deg, #03a9f400, #00000020 33.61%, black);
            }

            .screen:not(.camera-calibration) .swiper-pagination-bullet {
              background: #00CDEE;
            }


            .screen.module-qa .experience-progress .step {
              background: #00CDEE60;
            }

            .screen.module-qa .experience-progress .step.active {
              background: #00CDEE;
            }

            .screen .qa-question-wrapper .answers .answer {
              border-color: #1C1D2E40;
            }

            .screen .qa-question-wrapper .answers .answer .answer-icon i {
              color: #1C1D2E40;
            }

            .screen.camera-calibration .fixed-bottom p {
              color: #ffffff;
            }

            .screen p,
            .screen .input label,
            .screen .qa-question-wrapper .answers {
              color: #1C1D2E;
            }

            .screen.camera-calibration .fixed-bottom .camera-bottom p {
              color: #00CDEE;
            }

            .screen .checkmark,
            .screen .container .checkmark:after {
              border-color: #1C1D2E;
            }

            .screen .button-primary {
              background: #00CDEE;
              border-radius: 6px;
              color: #ffffff;
            }

            .screen .input input,
            .screen .input select {
              border-radius: 6px;
            }

            .screen button {
              color: #1C1D2E;
            }

            .screen .logo-small {
              display: none;
            }

            .splash.screen {
              background: #1C1D2E;
            }
          `}
        </style>
      );
    }
    */

    // const landingTextColor = getPreviewURLParameter("lcte") ? `#${getPreviewURLParameter("lcte")}` : campaignData.design.landingColorsText;
    // const landingBackgroundimage = getPreviewURLParameter("lbu") ? `${getPreviewURLParameter("lbu")}` : campaignBackgroundLanding;

    // console.log(getPreviewURLParameter("lcte"));
    // console.log(landingTextColor);

    let screenBackgroundImage = 'background-image: none;';

    if (
      campaignData &&
      campaignData.design &&
      campaignData.design.generalBackgroundType === "image" &&
      campaignBackground
    ) {
      screenBackgroundImage = `background-image: url('${campaignBackground}');`;
    }

    let inputOptionItemColor = campaignData.design.generalPrimaryBtnText;

    let screenPrimaryButton = `
      border: 0;
      background: 0;
      border-radius: 0;
      color: ${campaignData.design.generalPrimaryBtnText}
    `;

    if (
      campaignData &&
      campaignData.design &&
      campaignData.design.generalPrimaryBtnType === "solid"
    ) {
      screenPrimaryButton = `
        border: 0;
        color: ${campaignData.design.generalPrimaryBtnText};
        background: ${campaignData.design.generalPrimaryBtnBackground};
        border-radius: ${campaignData.design.generalPrimaryBtnBorderRadius}px;
      `;
      inputOptionItemColor = campaignData.design.generalPrimaryBtnBackground;
    } else if (
      campaignData &&
      campaignData.design &&
      campaignData.design.generalPrimaryBtnType === "border"
    ) {
      screenPrimaryButton = `
        border: 1px solid ${campaignData.design.generalPrimaryBtnText};
        color: ${campaignData.design.generalPrimaryBtnText};
        background: none;
        border-radius: ${campaignData.design.generalPrimaryBtnBorderRadius}px;
      `;
    }

    let screenSecondaryButton = `
      border: 0;
      background: 0;
      border-radius: 0;
      color: ${campaignData.design.generalSecondaryBtnText}
    `;

    if (
      campaignData &&
      campaignData.design &&
      campaignData.design.generalSecondaryBtnType === "solid"
    ) {
      screenSecondaryButton = `
        border: 0;
        color: ${campaignData.design.generalSecondaryBtnText};
        background: ${campaignData.design.generalSecondaryBtnBackground};
        border-radius: ${campaignData.design.generalSecondaryBtnBorderRadius}px;
      `;
    } else if (
      campaignData &&
      campaignData.design &&
      campaignData.design.generalSecondaryBtnType === "border"
    ) {
      screenSecondaryButton = `
        border: 1px solid ${campaignData.design.generalSecondaryBtnText};
        color: ${campaignData.design.generalSecondaryBtnText};
        background: none;
        border-radius: ${campaignData.design.generalSecondaryBtnBorderRadius}px;
      `;
    }

    let landingBackgroundImage = 'background-image: none;';

    if (
      campaignData &&
      campaignData.design &&
      campaignData.design.landingBackgroundType === "image" &&
      campaignBackgroundLanding
    ) {
      landingBackgroundImage = `background-image: url('${campaignBackgroundLanding}');`;
    }

    let landingPrimaryButton = `
      border: 0;
      background: 0;
      border-radius: 0;
      color: ${campaignData.design.landingPrimaryBtnText}
    `;

    if (
      campaignData &&
      campaignData.design &&
      campaignData.design.landingPrimaryBtnType === "solid"
    ) {
      landingPrimaryButton = `
        border: 0;
        color: ${campaignData.design.landingPrimaryBtnText};
        background: ${campaignData.design.landingPrimaryBtnBackground};
        border-radius: ${campaignData.design.landingPrimaryBtnBorderRadius}px;
      `;
    } else if (
      campaignData &&
      campaignData.design &&
      campaignData.design.landingPrimaryBtnType === "border"
    ) {
      landingPrimaryButton = `
        border: 1px solid ${campaignData.design.landingPrimaryBtnText};
        color: ${campaignData.design.landingPrimaryBtnText};
        background: none;
        border-radius: ${campaignData.design.landingPrimaryBtnBorderRadius}px;
      `;
    }

    let landingSecondaryButton = `
      border: 0;
      background: 0;
      border-radius: 0;
      color: ${campaignData.design.landingSecondaryBtnText}
    `;

    if (
      campaignData &&
      campaignData.design &&
      campaignData.design.landingSecondaryBtnType === "solid"
    ) {
      landingSecondaryButton = `
        border: 0;
        color: ${campaignData.design.landingSecondaryBtnText};
        background: ${campaignData.design.landingSecondaryBtnBackground};
        border-radius: ${campaignData.design.landingSecondaryBtnBorderRadius}px;
      `;
    } else if (
      campaignData &&
      campaignData.design &&
      campaignData.design.landingSecondaryBtnType === "border"
    ) {
      landingSecondaryButton = `
        border: 1px solid ${campaignData.design.landingSecondaryBtnText};
        color: ${campaignData.design.landingSecondaryBtnText};
        background: none;
        border-radius: ${campaignData.design.landingSecondaryBtnBorderRadius}px;
      `;
    }

    return (
      <style>
        {`
          .desktop-cover {
            background-color: ${campaignData.design.generalColorsBackground};
          }

          .screen .button-back i {
            color: ${campaignData.design.generalColorsText};
          }

          .screen {
            background-color: ${campaignData.design.generalColorsBackground};
            ${screenBackgroundImage}
            background-position: center center;
            background-size: cover;
          }

          .screen.policy-and-conditions p,
          .screen.policy-and-conditions ul,
          .screen.policy-and-conditions ul li,
          .screen.policy-and-conditions table {
            color: ${campaignData.design.generalColorsText};
          }

          .screen.policy-and-conditions h1,
          .screen.policy-and-conditions h2,
          .screen.policy-and-conditions h3 {
            color: ${campaignData.design.generalColorsTitle};
          }

          .screen .progress-bar {
            background-color: ${campaignData.design.generalColorsTitle};
          }

          .screen .loading-resources-text p.animated {
            color: ${campaignData.design.generalColorsTitle};
          }

          .screen.module-qa {
            background-image: none;
          }

          .screen .rc-progress-circle-path {
            stroke: ${campaignData.design.generalColorsTitle} !important;
          }

          .screen .rc-progress-circle-trail {
            stroke: ${campaignData.design.generalColorsTitle}40 !important;
          }

          .screen .rai-spinner-outer{
            border-color: ${campaignData.design.generalColorsTitle} !important;
          }

          .screen .rai-spinner-inner {
            border-top-color: ${campaignData.design.generalColorsTitle} !important;
          }

          .screen .camera-bottom,
          .screen .desktop-cover {
            color: ${campaignData.design.generalColorsTitle};
            background-color: ${campaignData.design.generalColorsBackground};
          }

          .screen h1,
          .screen .qa-question-wrapper h1,
          .screen .logo-text,
          .screen.module-qa .button-fullscreen.qa i {
            color: ${campaignData.design.generalColorsTitle};
          }

          .screen .overlay.apply-filter {
            background: linear-gradient(140deg, #03a9f400, #00000020 33.61%, black);
          }

          .screen:not(.camera-calibration) .swiper-pagination-bullet {
            background: ${campaignData.design.generalColorsTitle};
          }


          .screen.module-qa .experience-progress .step {
            background: ${campaignData.design.generalColorsTitle}60;
          }

          .screen.module-qa .experience-progress .step.active {
            background: ${campaignData.design.generalColorsTitle};
          }

          .screen .qa-question-wrapper .answers .answer {
            border-color: ${campaignData.design.generalColorsText}40;
            /* ${screenPrimaryButton} */
          }

          .screen .qa-question-wrapper .answers .answer .answer-icon i {
            color: ${campaignData.design.generalColorsText}40;
          }

          .screen:not(.camera-calibration) p,
          .screen .input label,
          .screen .qa-question-wrapper .answers {
            color: ${campaignData.design.generalColorsText};
          }

          .screen .checkmark,
          .screen .container .checkmark:after {
            border-color: ${campaignData.design.generalColorsText};
          }

          .screen .input-row .item {
            /* border-color: ${campaignData.design.generalColorsText}; */
          }

          .screen .input-row .item.active p,
          .screen .input-row .item:hover p {
            color: ${inputOptionItemColor};
          }

          .screen .input-row .item.active {
            border-color: ${inputOptionItemColor};
          }

          .screen .button-primary,
          .button-primary {
            ${screenPrimaryButton}
          }

          .screen .button-secondary-no-bg {
            ${screenSecondaryButton}
          }

          .screen .input input,
          .screen .input select {
            border-radius: ${campaignData.design.generalPrimaryBtnBorderRadius}px;
          }

          .screen button {
            color: ${campaignData.design.generalColorsText};
          }

          .screen.landing {
            background-color: ${campaignData.design.landingColorsBackground};
            ${landingBackgroundImage}
            background-position: center center;
            background-size: cover;
          }

          .screen.landing h1,
          .screen.landing .logo-text {
            color: ${campaignData.design.landingColorsTitle};
          }

          .screen.landing p {
            color: ${campaignData.design.landingColorsText};
          }

          .screen.landing .button-primary {
            ${landingPrimaryButton}
          }

          .screen.landing .button-secondary-no-bg {
            ${landingSecondaryButton}
          }

          .screen.landing .input input {
            border-radius: ${campaignData.design.landingPrimaryBtnBorderRadius}px;
          }
        `}
      </style>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderStyles)
