export default {
    translations: {
        /* App */
        'App_Title': 'Test created using Alyze',
        /* SignUp */
        'SignUp_Input_Label_Email': 'Email',
        'SignUp_Input_Email': 'What\'s your email?',
        'SignUp_Input_Label_Gender': 'Gender',
        'SignUp_Input_Gender': 'What\'s your gender?',
        'SignUp_Input_Gender_Male': 'Man',
        'SignUp_Input_Gender_Female': 'Woman',
        'SignUp_Input_Gender_Nonbinary': 'Rather not say',
        'SignUp_Input_Label_Birthday': 'Birthdate',
        'SignUp_Input_Label_Age': 'Age',
        'SignUp_Input_Label_Location': 'Location',
        'SignUp_Input_Age': 'How old are you?',
        'SignUp_Input_Birthday_Day': 'Day',
        'SignUp_Input_Birthday_Month': 'Month',
        'SignUp_Input_Birthday_Year': 'Year',
        'SignUp_CTA': 'Next',
        'SignUp_bilendi_CTA': 'START',
        /* PolicyCheck */
        'PolicyCheck_Text_1': 'I\'ve read and agree to the ',
        'PolicyCheck_Link_Privacy': 'privacy policy',
        'PolicyCheck_Text_2': '<br />and the ',
        'PolicyCheck_Link_Terms': 'terms and conditions',
        /* RequestAccessCamera */
        'RequestAccessCamera_Title': 'Please allow access to your camera',
        'RequestAccessCamera_Description': '<b>Alyze</b> will use your camera to recognize and analyze your emotions',
        'RequestAccessCamera_Denied_camera': 'In order to access your camera we need you to grant us access from your browser options',
        'RequestAccessCamera_CTA': 'Allow',
        /* CameraCalibration */
        'CameraCalibration_Instructions_a': 'Place your face in the center of the frame',
        'CameraCalibration_Instructions_b': 'Please keep yourself centered while we calibrate the system!',
        'CameraCalibration_Instructions_c': 'Face scanning...<br />We are almost there',
        'CameraCalibration_Description': 'Now, <b>find a quiet space</b>, put your <b>Headphones on</b>, and focus on the display on your screen',
        'CameraCalibration_CTA': 'Start test',
        /* Experience */
        'Experience_Button_Skip': 'Skip instructions',
        /* Notifications */
        'ErrorNotification_InvalidData': 'Please check all of the data before continuing',
        'ErrorNotification_InvalidEmail': 'The email you have entered is incorrect',
        'ErrorNotification_ConnectionError': 'You do not have an internet connection. Please try again in a few minutes',
        'ErrorNotification_GenericError': 'An unexpected error has occurred. Please try again in a few minutes',
        'ErrorNotification_InvalidCode': 'The access code you have entered is incorrect',
        'ErrorNotification_InvalidToken': 'The session has expired',
        'ErrorNotification_BackRestriction': 'Once the test has started, it is not possible to go back',
        /* NotFound */
        'NotFound_Title': 'Test not found',
        'NotFound_Description': 'The test that you\'re trying to access doesn\'t exist or it\'s unavailable',
        /* LinkUsed */
        'LinkUsed_Title': 'Link used',
        'LinkUsed_Description': 'The link that you\'re trying to access has been used',
        /* ModuleDispatcher */
        'ModuleDispatcher_Loading_Title': 'Loading',
        'ModuleDispatcher_Loading_Text': 'Hold tight! The test is being loaded',
        'ModuleDispatcher_Loading_Finish': 'Loading<br />complete!',
        'ModuleDispatcher_Loading_Error_Title': 'Connection lost',
        'ModuleDispatcher_Loading_Error_Text': 'To carry out the test we need you<br />to establish a good connection<br /><br />Please, check your connection<br />before you continue',
        'ModuleDispatcher_Loading_Error_CTA': 'Try again',
        'ModuleDispatcher_Uploading_Title': 'Uploading',
        'ModuleDispatcher_Uploading_Text': 'Please wait while the<br />results are being uploaded',
        'ModuleDispatcher_Uploading_Finish': 'Uploading<br />complete!',
        'ModuleDispatcher_Uploading_Error_Title': 'Connection lost',
        'ModuleDispatcher_Uploading_Error_Text': 'To upload the results we need you<br />to establish a good connection<br /><br />Please, check your connection<br />before you continue',
        'ModuleDispatcher_Uploading_Error_CTA': 'Try again',
        /* ExternalAudienceProcess */ 
        'ExternalAudienceProcess_Loading_Error_Title': 'Connection lost',
        'ExternalAudienceProcess_Loading_Error_Text': 'To carry out the test we need you<br />to establish a good connection<br /><br />Please, check your connection<br />before you continue',
        'ExternalAudienceProcess_Loading_Error_CTA': 'Try again',
        'ExternalAudienceProcess_Processing_Title': 'Processing',
        'ExternalAudienceProcess_Processing_Text': 'Please, wait until your responses are processed',
        'ExternalAudienceProcess_Processing_Warning': 'Attention! Your responses will not be valid until we have processed all of them. Please wait and don\'t close this window.<br /><small>This process usually takes less than 5 minutes.</small>',
        /* Modules */
        'Modules_Warning': 'Please, center your face between the brackets and pay attention to the screen during the test',
        'Modules_Choose_This': 'Choose this',
        /* External tester */
        'Thanks_Title': 'Thank you for taking part in our test',
        'Thanks_Text': 'We are currently processing your test and you will soon see the reward points in your account',
        'Instructions_Title': 'Dear Participant',
        'Instructions_Text': '<p>As part of this study, we will show you audiovisual content</p><p>We want to measure your reactions towards these advertisements so we <b>will need to analyze your face while looking at this test</b></p><p>A machine will perform the analysis,<br/><b>no human will see your face</b></p><p>You must meet the following requirements for the test to be valid:</p>',
        'Instructions_Step_A': '<b>Do not cover your face with your hands</b> or any other element and do not rest your chin on your hands',
        'Instructions_Step_B': '<b>Keep a front angle between your face</b> and the camera at all times during the test',
        'Instructions_Step_C': '<b>Avoid spaces with too much backlight</b>',
        'Instructions_Step_D': '<b>Look for a quiet space</b>, where you will not be interrupted during the test',
        'Instructions_Text_Location': '<p>Where do you live?</p>',
        /* CameraInaccessible */
        'CameraInaccessible_Title': 'Camera is inaccessible',
        'CameraInaccessible_Description': 'We are unable to access your camera. Please try with another browser or check your camera\'s permission access',
        'CameraInaccessible_CTA': 'Check again',
        /* IncompatibleDevice */
        'IncompatibleDevice_Title': 'Device not supported',
        'IncompatibleDevice_Description': 'Due to the limitations of your device we can\'t grant a good experience of our emotional recognition technology',
        'IncompatibleDevice_Description_2': 'Please, use Android device or computer',
        /* DesktopIncompatibleDisclaimer */
        'DesktopIncompatibleDisclaimer_Title': 'Incompatible browser',
        'DesktopIncompatibleDisclaimer_Description': 'Your browser does not have compatibility with the technology necessary for our system to work',
        'DesktopIncompatibleDisclaimer_Link': 'Click here to get the latest version of Google Chrome',
        /* Thanks */
        'Thanks_Create_Yours_Text': 'Would you like to create a test like this?',
        'Thanks_Create_Yours_CTA': 'Create my own test',
        /* noFaceRecognitionWarning */
        'noFaceRecognitionWarning_Modal_Info_Title': 'We can\'t detect your face well',
        'noFaceRecognitionWarning_Modal_Info_Subtitle': 'Check the following points before proceeding',
        'noFaceRecognitionWarning_Modal_Info_Text_List_1': 'Check that your camera is well positioned',
        'noFaceRecognitionWarning_Modal_Info_Text_List_2': 'Make sure you have good lighting',
        'noFaceRecognitionWarning_Modal_Info_Text_List_3': 'Try not to cover or touch your face during the film',
        'noFaceRecognitionWarning_Modal_Info_Text_List_4': 'Avoid any distractions and pay attention to the film',
        'noFaceRecognitionWarning_Modal_Confirm': 'Continue from last checkpoint',
        /* showRightClickWarning */
        'showRightClickWarning_Modal_Info_Title': 'About this content',
        'showRightClickWarning_Modal_Info_Subtitle': '',
        'showRightClickWarning_Modal_Confirm': 'Continue',
        'showRightClickWarning_Modal_Text': 'This piece of content is unique. It has been generated for you. Your personal data and IP address have been coded into the file\'s metadata, and a watermark is shown during the footage.',
        /* User information watermark */
        'userInformationWatermark': 'This video has been generated for [var1] and linked to your IP address',
        'userInformationWatermark_external': 'This video has been generated for you and linked to your IP address ([var1])',
        /* showNoFaceWarningLive */
        'showNoFaceWarningLive_Warning': 'ATTENTION: We cannot detect your face.<br/>Please, check your camera, the light in your room and don\'t cover your face.',
        'showNoFaceWarningLive_Close': 'Close warning',
        /* ModuleLargeVideo */
        'messageFilmPaused': 'You will continue from the last checkpoint',
        'playerBarContinue': 'Continue playback',
        'playerBarPause': 'Pause playback',
        /* SkipModulePreview */
        'SkipModulePreview_Label': 'Go to module',
        'SkipModulePreview_Prev': 'Prev',
        'SkipModulePreview_Next': 'Next',
    }
}