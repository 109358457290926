import React from 'react';
import { connect } from 'react-redux';

import T from './Translate';

import {
  finishActiveModule,
  prevActiveModule,
  goToModule,
  resetModules,
  loadActiveModule
} from '../reducers/user';

import {
  ANIMATION_SLIDE_IN,
  ANIMATION_SLIDE_OUT
} from '../config/transitions';

import {
  URL_LANDING,
  URL_WELCOME,
  URL_SIGNUP,
  URL_MODULES_DISPATCHER,
} from '../config/urls';

import '../styles/App.scss';
import '../styles/components/PreviewNavigation.scss';

import Select from './Select';

const mapStateToProps = (state, ownProps) => ({
  campaignData: state.user.campaignData,
  activeModule: state.user.activeModule,
  resourcesLoaded: state.user.resourcesLoaded,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  finishActiveModule: val => dispatch(finishActiveModule(val)),
  prevActiveModule: val => dispatch(prevActiveModule(val)),
  goToModule: val => dispatch(goToModule(val)),
  resetModules: val => dispatch(resetModules(val)),
  loadActiveModule: val => dispatch(loadActiveModule(val)),
  dispatch,
})

class PreviewNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.refs.selectModule)
      this.refs.selectModule.refresh()
  }

  getNavigationScreens() {
    return [ URL_LANDING, URL_WELCOME, URL_SIGNUP, URL_MODULES_DISPATCHER ]
  }

  getNavigationTransition(from, to) {
    const fromPosition = this.getNavigationScreens().indexOf(from);  
    const toPosition = this.getNavigationScreens().indexOf(to);  

    if (fromPosition < toPosition) {
      return ANIMATION_SLIDE_IN;
    } else {
      return ANIMATION_SLIDE_OUT;
    }
  }

  navigationScreensGoNext() {
    const { currentPath, finishActiveModule, resourcesLoaded, redirectTo } = this.props
    let nextScreen = "";

    // check resourcesLoaded to wait the resource download before navigate between modules
    if (currentPath === URL_MODULES_DISPATCHER) {
      if (resourcesLoaded)
        finishActiveModule();
    } else {
      const currentPathPosition = this.getNavigationScreens().indexOf(currentPath);
      const nextScreenPosition = currentPathPosition + 1

      nextScreen = this.getNavigationScreens()[nextScreenPosition];
      redirectTo({route: nextScreen, animation: this.getNavigationTransition(currentPath, nextScreen)});
    }
  }

  navigationScreensGoPrev() {
    const { 
      currentPath, 
      prevActiveModule, 
      campaignData, 
      resourcesLoaded, 
      redirectTo, 
      loadActiveModule, 
      activeModule,
      dispatch 
    } = this.props
    const modulesDone = campaignData.modules.campaignModules.filter(module => module.done === true); 
    let prevScreen = "";

    if (currentPath === URL_LANDING)
      return false;

    // check resourcesLoaded to wait the resource download before navigate between modules
    // if we are in the first module, go to prev screen
    if (currentPath === URL_MODULES_DISPATCHER && modulesDone.length >= 1) {
      if (resourcesLoaded) {
        const allCampaignModules = campaignData?.modules?.campaignModules || [];
        let currenModulePosition = allCampaignModules.indexOf(activeModule);
       
        if (currenModulePosition < 0)
          currenModulePosition = allCampaignModules?.length

        this.navigationScreensGoTo(currenModulePosition - 1)
      }

      // prevActiveModule()
    } else {
      const currentPathPosition = this.getNavigationScreens().indexOf(currentPath);
      const prevScreenPosition = currentPathPosition - 1

      prevScreen = this.getNavigationScreens()[prevScreenPosition];
      redirectTo({route: prevScreen, animation: this.getNavigationTransition(currentPath, prevScreen)});
    }
  }

  navigationScreensGoTo(screenOrModule) {
    const { currentPath, goToModule, finishActiveModule, redirectTo, resetModules, dispatch } = this.props
    let nextScreen;
    let modulePosition;

    if (isNaN(screenOrModule)) { 
      // is Screen
      nextScreen = screenOrModule;

      // We wait to reset the modules when modules dispatcher screen are unmounted.
      setTimeout(() => resetModules(), 1000); 
    } else { 
      // is Module
      nextScreen = URL_MODULES_DISPATCHER;
      modulePosition = screenOrModule;
    }

    if (currentPath !== nextScreen) {
      redirectTo({route: nextScreen, animation: this.getNavigationTransition(currentPath, nextScreen)});   
    }

    if (nextScreen === URL_MODULES_DISPATCHER) {
      finishActiveModule();
      goToModule(modulePosition);
    }
  }

  toggleExpand() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  render() {
    const { t, campaignData, currentPath, activeModule, resourcesLoaded } = this.props;
    const { expanded } = this.state;

    const allCampaignModules = campaignData?.modules?.campaignModules || [];
    const options = [
      {value: URL_LANDING, label: 'Landing'},
      {value: URL_WELCOME, label: 'Introduction'},
      {value: URL_SIGNUP, label: 'Login'},
    ];

    allCampaignModules.map((m,i) => {
      options.push({value: i, label: `${i+1}. ${m.name}`})
    });
    options.push({value: allCampaignModules?.length, label: 'Finish'});

    let selectedValue = currentPath === URL_MODULES_DISPATCHER ? allCampaignModules.indexOf(activeModule) : currentPath;
    
    if (selectedValue < 0)
      selectedValue = allCampaignModules?.length
  
    const disablePrevbutton = currentPath === URL_LANDING;
    const disableNextbutton = currentPath === URL_MODULES_DISPATCHER && !activeModule;

    return (
      <div className="skip-modules-container">
        <div className="skip-modules">
          <div className={expanded ? 'expand-button' : 'expand-button closed'} onClick={() => this.toggleExpand()}>
            <i className="icon-right-open-big"></i>
          </div>
          <div className={expanded ? 'module-control' : 'module-control hidden'}>
            <form className="module-control-form">
              <div className="input">
                <div className="input-row select-item">
                  <div className="field">
                    <Select
                      ref="selectModule"
                      label={<T text="SkipModulePreview_Label"/>}
                      name="selectModule"
                      value={selectedValue.toString()}
                      onChange={val => this.navigationScreensGoTo(val.selectModule)}
                      options={options}
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className="arrow-buttons">
              <div className={disablePrevbutton ? 'arrow-button disabled': 'arrow-button'}>
                <T text="SkipModulePreview_Prev"/>
                <button type="button" disabled={disablePrevbutton} onClick={() => this.navigationScreensGoPrev()}><i className="icon-left-open-big"></i></button>
              </div>
              <div className={disableNextbutton ? 'arrow-button disabled': 'arrow-button'}>
                <T text="SkipModulePreview_Next"/>
                <button type="button" disabled={disableNextbutton} onClick={() => this.navigationScreensGoNext()}><i className="icon-right-open-big"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewNavigation)
