import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { TesterAccess } from '@sounditi/ft2-api';

import ExperienceProgress from '../../components/ExperienceProgress';

import { showNotification } from '../../reducers/notifications';
import { setRedirect, setFullWidth } from '../../reducers/navigation';
import {
  clearSession,
  setCampaignData,
  finishActiveModule
} from '../../reducers/user';

import { shuffle, generateRandomNumber, toggleFullScreen } from '../../utils/globals';
import { ANIMATION_SLIDE_OUT } from '../../config/transitions';
import { URL_WELCOME } from '../../config/urls';

import 'react-activity/dist/react-activity.css';

const mapStateToProps = (state, ownProps) => ({
  campaignData: state.user.campaignData,
  activeModule: state.user.activeModule,
  campaign: state.user.campaign,
  campaignPreviewToken: state.user.campaignPreviewToken,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  clearSession: val => dispatch(clearSession(val)),
  setCampaignData: val => dispatch(setCampaignData(val)),
  finishActiveModule: val => dispatch(finishActiveModule(val)),
  showNotification: val => dispatch(showNotification(val)),
  setFullWidth: val => dispatch(setFullWidth(val)),
  dispatch,
})

class ModuleQA extends React.Component {
  _mounted = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeModule: undefined,
      totalSteps: 0,
      activeStep: 1,
      activeQuestion: {},
      activeAnswers: []
    };
  }

  componentWillMount() {
    const { setFullWidth } = this.props;

    setFullWidth(false);
  }

  componentDidMount() {
    this.getClips();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentWillUpdate() {}

  async getClips() {
    this.postAction('tester_enters_module');

    if (this._mounted) {
      this.selectStep(0);
    }
  }

  async postAction(action, interactionId = "") {
    const { activeModule, onEventSend } = this.props;
    onEventSend(action, activeModule, interactionId);
  }

  async postQuestion(interactionId = "", q_id, question, answeropt) {
    const { activeModule, onEventSend } = this.props;
    // console.log({ activeModule, interactionId, q_id, question, answeropt });
    onEventSend("postquestion", activeModule, interactionId, undefined, q_id, question, answeropt);
  }

  selectStep(activeStep) {
    const { activeModule } = this.props;
    const { questions } = activeModule;

    const activeQuestion = questions[activeStep];
    let activeAnswers = activeQuestion.answers;

    if (
      String(activeQuestion.randomize) === "true" ||
      String(activeQuestion.randomize) === "undefined"
    ) {
      activeAnswers = shuffle(activeAnswers);
    }

    this.setState({
      activeStep,
      activeQuestion,
      activeAnswers,
      totalSteps: questions.length,
      loading: false
    });
  }

  async selectAnswer(answer) {
    const { activeModule, finishActiveModule, campaignPreviewToken } = this.props;
    const { totalSteps, activeStep } = this.state;
    const { questions } = activeModule;
    const nextActiveStep = activeStep + 1;

    this.setState({ loading: true });

    const testerAcessParams = campaignPreviewToken ? [ true, campaignPreviewToken ] : [];
    const testerAccess = new TesterAccess(...testerAcessParams);
    const userData = await testerAccess.getProfile();

    // const moduleId = activeModule.id;
    const interactionId = `${userData.id}-${Date.now()}-${generateRandomNumber()}`;
    const q_id = questions[activeStep].questionID;
    const question = questions[activeStep].name;
    const answeropt = answer.name;
    // const createdAt = new Date().getTime();
    // const sessionId = sessionStorage.getItem('sessionID-info');

    // await testerAccess.postQuestion(moduleId, interactionId, q_id, question, answeropt, sessionId, createdAt);
    // console.log({postQuestion: {moduleId, interactionId, q_id, question, answeropt, sessionId, createdAt}});

    this.postQuestion(interactionId, q_id, question, answeropt);

    if (nextActiveStep < totalSteps) {
      this.selectStep(nextActiveStep);
    } else {
      this.postAction('tester_end_module');
      finishActiveModule();
    }
  }

  /* SILLY FUNCTIONS */

  goWelcome() {
    const { setRedirect } = this.props;
    const backScreen = {
      route: URL_WELCOME,
      animation: ANIMATION_SLIDE_OUT
    };
    setRedirect(backScreen);
  }

  render() {
    const { totalSteps, activeStep, activeQuestion, activeAnswers, loading } = this.state;

    if (!activeQuestion || !activeAnswers || loading) {
      return (
        <div className="screen loader">
          <Spinner speed={0.8} color="#ffffff" size={20} />
        </div>
      );
    }

    const renderAnswers = activeAnswers.map((answer, key) => {
      return (
        <div key={key} className="answer" onClick={() => this.selectAnswer(answer)}>
          <div className="answer-text">{answer.name}</div>
          <div className="answer-icon">
            <i className="icon icon-right-open-big"></i>
          </div>
        </div>
      )
    });

    return (
      <div className="screen module-qa">
        <div className="screen-inner-wrapper">
          <div className="flexible-top">
            <div className="qa-question-wrapper">
              <h1>{activeQuestion.name}</h1>
              <div className="answers">
                {renderAnswers}
              </div>
            </div>
          </div>
          <div className="fixed-bottom">
            {/* <ExperienceProgress
              totalSteps={totalSteps}
              activeStep={activeStep}
            /> */}
            <div className="button-fullscreen qa" onClick={() => toggleFullScreen() }><i className="icon icon-resize-full-5"></i></div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleQA)
