import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import RecordRTC from 'recordrtc';
import { TesterAccess } from '@sounditi/ft2-api';
import FaceFinder from '../../assets/cascades/facefinder';
import T from '../../components/Translate';
import ModalDialogInfo from '../../components/ModalDialogInfo';
import Dialog from 'rc-dialog';
import Link from '../../components/Link';

import { showNotification } from '../../reducers/notifications';
import { setRedirect, setFullWidth } from '../../reducers/navigation';
import {
  clearSession,
  setCampaignData,
  finishActiveModule
} from '../../reducers/user';
import { rgba_to_grayscale, loadModels, getFace, isModelsLoaded } from '../../utils/faceRecognition';
import { shuffle, generateRandomNumber, getVideoAspect, formatFragment, parseFragment, fancyTimeFormat, toggleFullScreen } from '../../utils/globals';
import { ANIMATION_SLIDE_OUT } from '../../config/transitions';
import { URL_WELCOME, URL_INCOMPATIBLE_BROWSER } from '../../config/urls';

import 'react-activity/dist/react-activity.css';

const mapStateToProps = (state, ownProps) => ({
  campaignData: state.user.campaignData,
  activeModule: state.user.activeModule,
  campaign: state.user.campaign,
  campaignPreviewToken: state.user.campaignPreviewToken,
  userData: state.user.userData,
  linkData: state.user.linkData,
  externalProviderID: state.user.externalProviderID,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  clearSession: val => dispatch(clearSession(val)),
  setCampaignData: val => dispatch(setCampaignData(val)),
  finishActiveModule: val => dispatch(finishActiveModule(val)),
  setFullWidth: val => dispatch(setFullWidth(val)),
  showNotification: val => dispatch(showNotification(val)),
  dispatch,
})

let noFaceWarningCountdown = undefined;
let noFaceWarningCountdownLive = undefined;
class ModuleLargeVideo extends React.Component {
  _mount = true;
  _playerVideo = undefined;
  _mediaRecord = undefined;
  _clipsSended = [];
  _requestLoop = undefined;
  _loop = () => {};
  _update_memory = window.pico.instantiate_detection_memory(5);
  _facefinder_classify_region = function(r, c, s, pixels, ldim) {return -1.0;};

  constructor(props) {
    super(props);
    this.state = {
      totalSteps: 6,
      activeStep: 0,
      clips: undefined,
      currentClip: undefined,
      currentClipName: "",
      currentClipAspect: "",
      uploadClip: "",
      currentClipReady: false,
      clipsReady: false,
      videoReady: true,
      clipsStarted: false,
      mediaPlayable: true,
      videoHeight: 720,
      videoWidth: 1280,
      loading: true,
      loadingVideoRatio: false,
      videoRatio: 'landscape',
      finishedExperience: false,
      mediaStreamReady: false,
      clipLoaded: false,
      recognizingFace: true,
      processCanvasWidth: 480,
      processCanvasHeight: 480,
      videoContainerWidth: null,
      videoContainerHeight: null,
      activeModule: undefined,
      fragmentSecondsDuration: 60,
      fragmentActive: 0,
      paused: false,
      showWarning: false,
      showNoFaceWarning: false,
      showNoFaceWarningLive: false,
      userData: undefined,
      userIP: undefined,
      showRightClickWarning: false,
      playerBarHidden: false,
    };
  }

  componentWillMount() {
    const { setFullWidth } = this.props;

    loadModels();
    this.loadRecognitionModels();
    setFullWidth(true);
    this.getUserData();
  }

  componentDidMount() {
    setTimeout(() => {
      if (this._mount) {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          this.goCameraInaccessible();
          return false;
        }

        this.getClips();
        this.startMedia();
      }
    }, 1000);

    if (document.querySelector('.float-player-bar') && document.querySelector('.user-information')) {
      let timeoutMouse;

      timeoutMouse = setTimeout(() => {
        const { paused } = this.state;

        if (!paused) {
          this.setState({ playerBarHidden: true });
        }
      }, 5000);

      document.addEventListener('mousemove', (event) => {
        clearTimeout(timeoutMouse);
        this.setState({ playerBarHidden: false });

        timeoutMouse = setTimeout(() => {
          const { paused } = this.state;

          if (!paused) {
            this.setState({ playerBarHidden: true });
          }
        }, 2000);
      })
    }
  }

  componentWillUnmount() {
    this._mount = false;

    this.stopNoFaceWarningCountdownLive();
    this.stopInvalidTestCountdown();
  }

  async getUserData() {
    // console.log("getUserData");
    const { campaignPreviewToken } = this.props;
    const testerAcessParams = campaignPreviewToken ? [true, campaignPreviewToken] : [];
    const testerAccess = new TesterAccess(...testerAcessParams);
    const userData = await testerAccess.getProfile();

    const response = await fetch("https://api.db-ip.com/v2/free/self");
    const IPData = await response.json();
    const userIP = IPData.ipAddress || "0.0.0.0";

    this.setState({ userData, userIP });
  }

  componentDidUpdate() {
    if (this._mount) {
      const {
        currentClipReady,
        clipsReady,
        videoReady,
        clipsStarted,
        mediaStreamReady
      } = this.state;

      if (currentClipReady && mediaStreamReady && videoReady && clipsReady && clipsStarted) {
        this.startClip();
      }

      if (clipsReady && videoReady && !clipsStarted && this._playerVideo) {
        this.initClips();
      }
    }
  }

  loadRecognitionModels() {
    var cascadeurl = FaceFinder;
    fetch(cascadeurl).then(response => {
      response.arrayBuffer().then(buffer => {
        var bytes = new Int8Array(buffer);
        this._facefinder_classify_region = window.pico.unpack_cascade(bytes);
      })
    })
  }

  runFaceRecognition() {
    const { loadingMesh, paused } = this.state;
    const processCanvas = document.getElementById('process-canvas');

    if (this._video && this._video.offsetWidth && this._video.offsetHeight){
      const videoWidth = this._video.offsetWidth;
      const videoHeight = this._video.offsetHeight;

      if (this._video && processCanvas && !loadingMesh) {
        const ctx = processCanvas.getContext('2d');
        ctx.drawImage(this._video, 0, 0, videoWidth, videoHeight);
        var rgba = ctx.getImageData(0, 0, videoWidth, videoHeight).data;

        let image = {
          "pixels": rgba_to_grayscale(rgba, videoHeight, videoWidth),
          "nrows": videoHeight,
          "ncols": videoWidth,
          "ldim": videoWidth
        }
        let params = {
          "shiftfactor": 0.1,
          "minsize": 100,
          "maxsize": 1000,
          "scalefactor": 1.1
        }

        let dets = window.pico.run_cascade(image, this._facefinder_classify_region, params);
        dets = this._update_memory(dets);
        dets = window.pico.cluster_detections(dets, 0.2); // set IoU threshold to 0.2

        let detected = false;
        if (dets.length) {
          for (let i = 0; i < dets.length; ++i) {
            if (dets[i][3] > 50.0) {
              detected = true;
            }
          }
        }

        if (detected) {
          this.setState({ recognizingFace: true });
          this.stopNoFaceWarningCountdownLive();

          this.stopInvalidTestCountdown();
        } else {
          if (!paused) {
            this.setState({ recognizingFace: false });
            this.startNoFaceWarningCountdown();
          }
        }
      }
    }
  }

  startRecognitionLoop() {
    const { recognitionLoopRunning } = this.state;

    if (recognitionLoopRunning) {
      this._loop = () => {
        if (recognitionLoopRunning){
          this.runFaceRecognition();

          this._requestLoop = requestAnimationFrame(this._loop);
        }
      }
      this._requestLoop = requestAnimationFrame(this._loop);
    }
  }

  startNoFaceWarningCountdown() {
    if (this._mount) {
      // if (noFaceWarningCountdown === undefined) {
      //   noFaceWarningCountdown = setTimeout(() => {
      //     // this.setState({ showWarning: true });
      //     // this.pause();
      //     // this.setState({ showNoFaceWarning: true });

      //     const { clips } = this.state;
      //     this.postAction('tester_invalid_test', clips[0].fileNameWithFragment, this._playerVideo.currentTime);
      //   }, 300000);
      // }
      if (noFaceWarningCountdownLive === undefined) {
        noFaceWarningCountdownLive = setTimeout(() => {
          const { paused } = this.state;

          if (!paused) {
            const { clips, showNoFaceWarningLive } = this.state;
            if (clips[0]?.fileNameWithFragment && this._playerVideo?.currentTime && !showNoFaceWarningLive) {
              this.postAction('tester_show_no_face_alert', clips[0].fileNameWithFragment, this._playerVideo.currentTime);
            }
            this.setState({ showNoFaceWarningLive: true });
            this.startIvalidateTestCountdown();
          }
        }, 10000);
      }
    }
  }

  startIvalidateTestCountdown() {
    // console.log("start countdown");
    if (this._mount) {
      if (noFaceWarningCountdown === undefined) {
        noFaceWarningCountdown = setTimeout(() => {
          const { clips } = this.state;
          this.postAction('tester_invalid_test', clips[0].fileNameWithFragment, this._playerVideo.currentTime);
        }, 290000);
        // 290
      }
    }
  }

  stopNoFaceWarningCountdownLive() {
    clearInterval(noFaceWarningCountdownLive);
    noFaceWarningCountdownLive = undefined;
    // this.setState({ showNoFaceWarningLive: false });
  }

  stopInvalidTestCountdown() {
    clearInterval(noFaceWarningCountdown);
    noFaceWarningCountdown = undefined;
    // console.log("stop countdown");
  }

  closeNoFaceWarningLive() {
    const { clips, showNoFaceWarningLive } = this.state;

    if (showNoFaceWarningLive) {
      this.postAction('tester_close_no_face_alert', clips[0].fileNameWithFragment, this._playerVideo.currentTime);
    }
    
    this.setState({ showNoFaceWarningLive: false });
    this.stopNoFaceWarningCountdownLive();

    this.stopInvalidTestCountdown();
  }

  async getClips() {
    const { showNotification, activeModule, campaignPreviewToken, linkData, campaign } = this.props;

    try {
      const testerAcessParams = campaignPreviewToken ? [true, campaignPreviewToken] : [];
      const testerAccess = new TesterAccess(...testerAcessParams);
      const userData = await testerAccess.getProfile();

      // console.log(userData);

      this.postAction('tester_enters_module');

      let clips = await Promise.all(activeModule.videos.map(async (video) => {
        // const getFileName = linkData?.linkUrl ? video.fileName + "-" + linkData.linkUrl : video.fileName;
        const compressed = linkData?.linkUrl ? false : true;

        const fileInformation = await testerAccess.getOfflineFilmFile({
          fileName: video?.fileName,
          compressed,
          linkUrl: linkData?.linkUrl,
          campaignId: campaign
        });
        
        const nowDate = `${Date.now()}-${generateRandomNumber()}`;
        const interactionIdRaw = `${userData.id}-${nowDate}`;
        const interactionId = `${userData.id}-${nowDate}`;
        const fileName = video.fileName;
        const fileNameWithFragment = video.fileName;
        const moduleId = activeModule.id;
        const aspect = await getVideoAspect(fileInformation.uri);
        return { fileName, moduleId, interactionIdRaw, interactionId, aspect, ...fileInformation };
      }));

      // console.log("cargado");

      if (String(activeModule.randomize) === "true") {
        clips = shuffle(clips);
      }

      // console.log(clips);

      if (this._mount) {
        this.setState({
          clips,
          currentClipName: clips[0].fileName || "",
          currentClip: clips[0].uri,
          currentClipAspect: clips[0].aspect,
        }, () => this.setState({ clipsReady: true }));
      }
    } catch (error) {
      console.log(error);
      showNotification("connectionError");
      this.goWelcome();
    };
  }

  pause() {
    const { clips } = this.state;
    this.postAction('tester_film_paused', clips[0].fileNameWithFragment);
    this.closeNoFaceWarningLive();

    this.setState({ paused: true }, () => {
      this._playerVideo.pause();

      if (
        this._mediaRecord
        && this._mediaRecord.state
        && this._mediaRecord.state !== "inactive"
        && this._mediaRecord.state !== "paused"
      ) {
        this._mediaRecord.stopRecording();

        this.stopInvalidTestCountdown();
      }
    });

    clearInterval(noFaceWarningCountdown);
    noFaceWarningCountdown = undefined;
    this.stopInvalidTestCountdown();
  }

  play() {
    const { activeStep, fragmentSecondsDuration, clips } = this.state;
    this.postAction('tester_film_resumed', clips[0].fileNameWithFragment);
    this.stopNoFaceWarningCountdownLive();

    this.setState({ paused: false, showWarning: false, showNoFaceWarning: false, showNoFaceWarningLive: false }, () => {
      this._playerVideo.currentTime = activeStep * fragmentSecondsDuration;
      this._playerVideo.play();

      if (
        this._mediaRecord &&
        this._mediaRecord.state &&
        this._mediaRecord.state !== "recording" &&
        this._mediaRecord.startRecording
      ) {
        // console.log("startRecording");
        this._mediaRecord.startRecording();
      }
    });
  }

  initClips() {
    if (this._mount) {
      this.setState({ clipsStarted: true, loading: false }, () => {
        // console.log("initClips nextClip");
        this.nextClip();
        this._playerVideo.onended = () => {
          const { activeStep, paused } = this.state;

          if (!paused) {
            this.setState({
              activeStep: activeStep + 1
            }, () => {
              // console.log("onended finishClip");
              this.finishClip(true)
            });
          }
        }
      });
    }
  }

  finishClip(isLastOne = false) {
    const { activeModule } = this.props;
    const { paused } = this.state;
    // console.log("finishClip");
    this.nextClip();

    // console.log(this._mediaRecord);

    if (
      this._mediaRecord
      && this._mediaRecord.state
      && this._mediaRecord.state !== "inactive"
      && this._mediaRecord.state !== "paused"
    ) {
      this._mediaRecord.stopRecording(() => {
        // console.log("stop recording");
        if (!paused) {
          // console.log("stop recording ENTRA");
          this.uploadVideoRecord(isLastOne);
        }
      });
    } else if (activeModule?.tech === "play" && !paused) {
      this.uploadVideoRecord(isLastOne);
    }
  }

  startClip() {
    if (this._mount) {
      const { mediaPlayable, mediaStreamReady, clips, activeStep } = this.state;
      const { activeModule } = this.props;

      this.setState({ currentClipReady: false, loading: false }, async () => {
        if (mediaPlayable && mediaStreamReady) {

          this._playerVideo.play();
          this.postAction('tester_start_interaction', clips[0].fileNameWithFragmentEvent);
          // this.postActionGA('GTM_load_interaction', activeStep, "video", activeModule, clips[0].interactionId);

          if (
            this._mediaRecord &&
            this._mediaRecord.state &&
            this._mediaRecord.state !== "recording" &&
            this._mediaRecord.startRecording
          ) {
            this._mediaRecord.startRecording();
          }
        }
      });
    }
  }

  nextClip() {
    const { paused } = this.state;
    if (this._mount && !paused) {
      // console.log("nextClip");
      const { activeModule } = this.props;
      const { totalSteps, activeStep, clips, finishedExperience } = this.state;
      const prevActiveStep = activeStep - 1;

      if (activeStep > 0 && activeStep <= totalSteps) {
        // console.log("END interaction"); 
        this.postAction('tester_end_interaction', clips[0].fileNameWithFragmentEvent);
        // this.postActionGA('GTM_complete_interaction', prevActiveStep, "video", activeModule, clips[0].interactionId);
      }

      if (activeStep <= totalSteps) {
        let uploadClip = clips[0];

        uploadClip.interactionId = parseFragment(uploadClip.interactionIdRaw, activeStep - 1);
        uploadClip.fileNameWithFragment = parseFragment(uploadClip.fileName, activeStep - 1);
        uploadClip.fileNameWithFragmentEvent = parseFragment(uploadClip.fileName, activeStep);

        this.setState({ uploadClip });
      } else {
        this._playerVideo.pause();

        this.setState({
          uploadClip: clips[0],
          loading: true
        });
      }
    }
  }

  startMedia() {
    const { activeModule } = this.props;
    const { fragmentSecondsDuration } = this.state;

    if (this._mount) {
      this._playerVideo = document.getElementById('playerVideo');

      if (this._playerVideo) {
        this._playerVideo.addEventListener('error', () => {
          // console.log("error finishClip");
          this.finishClip();
        }, true);

        this._playerVideo.onloadeddata = () => {
          const totalSteps = Math.ceil(Math.round(this._playerVideo.duration) / fragmentSecondsDuration);

          if (this._mount)
            this.setState({
              currentClipReady: true,
              clipLoaded: true,
              mediaPlayable: true,
              totalSteps
            });
        }

        this._playerVideo.onended = () => {
          // this dont work on preview

          if (activeModule.tech === "play") {
            this.checkFinish();
          }
        }

        this._playerVideo.ontimeupdate = () => {
          const { activeStep, paused } = this.state;

          if (Math.round(this._playerVideo.currentTime) > ((activeStep + 1) * fragmentSecondsDuration) ) {
            if (!paused) {
              this.setState({
                activeStep: activeStep + 1
              }, () => {
                // console.log("ontimeupdate finishClip");
                this.finishClip();

                if (( (activeStep - 1) % 3 ) === 0) {
                  this._playerVideo.pause();
                  this._playerVideo.currentTime = this._playerVideo.currentTime + 0.001;
                  this._playerVideo.play();
                }
              });
            }
          }
        }
      }

      if (activeModule.tech !== "recognition") {
        this.setState({
          mediaStreamReady: true,
          videoReady: true,
          loadingVideoRatio: false
        });
        return false;
      }

      this.initMediaStream();
    }
  }

  async initMediaStream() {
    const videoSettings = { audio: false, video: true };
    this._video = document.getElementById('user_webcam');

    if (this._video) {
      navigator.mediaDevices.getUserMedia(videoSettings).then(async (stream) => {
        const { finishedExperience } = this.state;

        if (!finishedExperience) {
          this._video.srcObject = await stream;
          window.localStream = stream;

          this.setState({ recognitionLoopRunning: true }, () => this.startRecognitionLoop());

          this._mediaRecord = RecordRTC(stream, {
            type: 'video',
            mimeType: 'video/webm;codecs=vp8',
            disableLogs: true,
          });

          this._video.oncanplay = () => {
            if (this._mount) {
              this.setState({ mediaStreamReady: true, videoReady: true });
            }
          }
        }
      }).catch(error => {
        this.goCameraInaccessible();
      });
    }
  }

  stopMedia(callback) {
    if (this._playerVideo) {
      this._playerVideo.pause();
    }

    if (
      this._mediaRecord &&
      this._mediaRecord.state &&
      this._mediaRecord.state !== "inactive" &&
      this._mediaRecord.state !== "paused"
    ) {
      this._mediaRecord.stopRecording();
      this._mediaRecord = undefined;
    }

    if (window.localStream) {
      window.localStream.getTracks().forEach((track) => {
        track.stop();
      });
      window.localStream.stop();
    }

    if (callback) {
      callback()
    }
  }

  isFinished() {
    const { activeStep, totalSteps } = this.state;

    if (activeStep >= totalSteps) {
      return true;
    }

    return false;
  }

  async checkFinish(force = false) {
    // console.log("checkFinish");
    if (this._mount) {
      const { finishedExperience, activeStep, totalSteps } = this.state;
      const { activeModule, finishActiveModule } = this.props;

      if ((this.isFinished() && finishedExperience === false) || force === true) {
        // console.log("FINAL");
        this.postAction('tester_end_module');

        this.setState({ finishedExperience: true }, () => {
          this.stopMedia(() => {
            setTimeout(() => {
              finishActiveModule();
            }, 500);
          })
        });
      }
    }
  }

  async uploadVideoRecord(isLastOne) {
    // console.log("uploadVideoRecord");

    if (this._mount) {
      const { onUploadVideoRecord, activeModule } = this.props;
      const { uploadClip, mediaPlayable, mediaStreamReady, clips, activeStep, showWarning } = this.state;

      // console.log("uploadVideoRecord " +uploadClip.interactionId);

      if (!this._mediaRecord) {
        if (this._clipsSended.indexOf(uploadClip.interactionId) === -1) {
          this._clipsSended.push(uploadClip.interactionId);
        }
        this.checkFinish();

        return false;
      }

      const fileBlob = await this._mediaRecord.getBlob();
      if (fileBlob) {
        const newUploadClip = JSON.parse(JSON.stringify(uploadClip));
        // console.log(window.URL.createObjectURL(fileBlob));
        // const largeVideoFragment = parseFragment(uploadClip.fileName, activeStep - 1);
        const largeVideoFragment = newUploadClip.fileName;

        // console.log("------- onUploadVideoRecord -------");
        // console.log(newUploadClip);
        // console.log(largeVideoFragment);

        onUploadVideoRecord(newUploadClip, activeModule, fileBlob);
      }

      // console.log("isLastOne " + isLastOne)
      // console.log("isFinished " + this.isFinished());

      if (mediaPlayable && mediaStreamReady && !this.isFinished() && isLastOne !== true) {
        if (showWarning === true) {
          this.pause();
          this.setState({ showNoFaceWarning: true });
        } else {
          // console.log("START interaction " + clips[0].fileNameWithFragment);
          this.postAction('tester_start_interaction', clips[0].fileNameWithFragmentEvent);
          // this.postActionGA('GTM_load_interaction', activeStep, "video", activeModule, clips[0].interactionId);

          if (
            this._mediaRecord &&
            this._mediaRecord.state &&
            this._mediaRecord.state !== "recording" &&
            this._mediaRecord.startRecording
          ) {
            this._mediaRecord.startRecording();
          }
        }
      }

      if (this._clipsSended.indexOf(uploadClip.interactionId) === -1) {
        this._clipsSended.push(uploadClip.interactionId);
      }

      this.checkFinish();
    }
  }

  async postAction(action, interactionId = "", currentTime = undefined) {
    const { activeModule, onEventSend } = this.props;
    onEventSend(action, activeModule, interactionId, currentTime);
  }

  async postActionGA(action, activeStep = 0, interactionType = "video", activeModule = [], interactionId = "") {

    const { campaignPreviewToken } = this.props;

    const testerAcessParams = campaignPreviewToken ? [true, campaignPreviewToken] : [];
    const testerAccess = new TesterAccess(...testerAcessParams);
    const userData = await testerAccess.getProfile()

    window.dataLayer.push({
      'event': action,
      attributes: {
        interactionType: interactionType,
        campaignId: localStorage.getItem('snd-campaign'),
        interactionId: interactionId,
        duration: activeModule.videos[0].duration,
        testerId: userData.id,
        time: Date.now()
      }
    });
  }

  /* SILLY FUNCTIONS */

  goWelcome() {
    const { setRedirect } = this.props;
    const backScreen = {
      route: URL_WELCOME,
      animation: ANIMATION_SLIDE_OUT
    };
    setRedirect(backScreen);
  }

  goCameraInaccessible() {
    const { setRedirect } = this.props;
    const screen = {
      route: URL_INCOMPATIBLE_BROWSER,
      animation: ANIMATION_SLIDE_OUT
    };
    setRedirect(screen);
  }

  render() {
    const { activeModule, externalProviderID, campaignData, campaignPreviewToken } = this.props;
    const {
      totalSteps,
      activeStep,
      currentClipName,
      currentClip,
      currentClipAspect,
      mediaPlayable,
      videoWidth,
      videoHeight,
      loading,
      videoRatio,
      recognizingFace,
      videoContainerWidth,
      videoContainerHeight,
      loadingVideoRatio,
      paused,
      showNoFaceWarning,
      showNoFaceWarningLive,
      userData,
      userIP,
      showRightClickWarning,
      playerBarHidden
    } = this.state;
    //const alyzeWatermark = campaignData?.alyzeWatermark;
    const audioWrapperClassName = mediaPlayable ? "audio-wrapper hide" : "audio-wrapper";
    const blurCoverClassName = mediaPlayable ? "blur-cover" : "blur-cover blur";

    const processCanvasWidth = this._video ? this._video.offsetWidth : 0;
    const processCanvasHeight = this._video ? this._video.offsetHeight : 0;

    const videoClass = videoRatio === "landscape"
      ? "camera-fullscreen black-and-white landscape"
      : "camera-fullscreen black-and-white portrait";

    const screenClass = recognizingFace
      ? "screen experience analizing-face film"
      : "screen experience film";

    const playerClass = currentClipAspect;


    // this._playerVideo.currentTime // .duration

    let progressWidth = 0;
    let timeLeft = 0;

    if (this._playerVideo) {
      progressWidth = (100 * this._playerVideo.currentTime / this._playerVideo.duration).toFixed(2);
      timeLeft = this._playerVideo.duration - this._playerVideo.currentTime;

      if (progressWidth > 100)
        progressWidth = 100;

      if (timeLeft < 0)
        timeLeft = 0;

      timeLeft = fancyTimeFormat(timeLeft);
    }

    return (
      <div className={screenClass} onContextMenu={(e)=> { e.preventDefault(); this.setState({ showRightClickWarning: true }) }}>
        <ModalDialogInfo
          name="noFaceRecognitionWarning"
          showModal={showNoFaceWarning}
          closeModal={() => {
            this.stopNoFaceWarningCountdownLive();
            this.play();
          }}
          onConfirm={() => {
            this.stopNoFaceWarningCountdownLive();
            this.play();
          }}
        >
          {/* <p>ATENCIÓN: No podemos detectar tu cara bien.</p>
          <p>Comprueba los siguientes puntos antes de continuar:</p> */}
          <ul>
            <li><T text="noFaceRecognitionWarning_Modal_Info_Text_List_1" /></li>
            <li><T text="noFaceRecognitionWarning_Modal_Info_Text_List_2" /></li>
            <li><T text="noFaceRecognitionWarning_Modal_Info_Text_List_3" /></li>
            <li><T text="noFaceRecognitionWarning_Modal_Info_Text_List_4" /></li>
          </ul>
        </ModalDialogInfo>
        <ModalDialogInfo
          name="showRightClickWarning"
          showModal={showRightClickWarning}
          closeModal={() => {
            this.setState({ showRightClickWarning: false });
          }}
          onConfirm={() => {
            this.setState({ showRightClickWarning: false });
          }}
        >
          <p><T text="showRightClickWarning_Modal_Text" /></p>
        </ModalDialogInfo>
        <video
          disablePictureInPicture
          onContextMenu={(e)=> e.preventDefault()}
          autoPlay
          playsInline
          muted
          id="user_webcam"
          style={{ position: 'fixed', visibility: 'hidden' }}
        />
        <canvas
          id="process-canvas"
          width={processCanvasWidth}
          height={processCanvasHeight}
          style={{ visibility: 'hidden', width: processCanvasWidth + "px", height: processCanvasHeight + "px" }}
        />
        {(loading || loadingVideoRatio) && (
          <div className="screen loader">
            <Spinner speed={0.8} color="#ffffff" size={20} />
          </div>
        )}
        <div className={audioWrapperClassName}>
          <div className="press-play-wrapper">
            {activeStep <= 1 && (
              <h1><T text="Experience_IOS_play" /></h1>
            )}
            {activeStep > 1 && (
              <h1><T text="Experience_IOS_play_continue" /></h1>
            )}
          </div>
        </div>
        <div className={blurCoverClassName}>

          <div className={playerBarHidden ? 'user-information hidden' : 'user-information'}>
            {userData && (
              !externalProviderID
                ? <span>
                    <T text="userInformationWatermark" var1={userData.email ? userData.email : 'demo@demo.com'} /> ({userIP})
                  </span>
                : <T text="userInformationWatermark_external" var1={userIP} />
            )}
          </div>
          <div className="layout-overlay">
            <div className="flexible-top">
              <video
                disablePictureInPicture
                onContextMenu={(e)=> e.preventDefault()}
                preload="auto"
                id="playerVideo"
                className={playerClass}
                src={currentClip}
              />
            </div>
            {paused && (
              <div className="pause-message">
                <T text="messageFilmPaused" />
              </div>
            )}
            {(showNoFaceWarningLive && !campaignPreviewToken) && (
              <div className="pause-message">
                <T text="showNoFaceWarningLive_Warning" />

                <Link onClick={() => this.closeNoFaceWarningLive()}>
                  <T text="showNoFaceWarningLive_Close" />
                </Link>
              </div>
            )}
            <div className={playerBarHidden ? 'float-player-bar hidden' : 'float-player-bar'}>
              <div className="button-play" onClick={() => paused ? this.play() : this.pause()}>
                <i className={`icon ${paused ? "icon-play-4" : "icon-pause-4"}`}></i>
                <p><T text={paused ? "playerBarContinue" : "playerBarPause"} /></p>
              </div>
              <div className="progress-bar">
                <div className="progress" style={{ width: progressWidth + "%" }}></div>
              </div>
              <div className="progress-time">
                {timeLeft}
              </div>
              <div className="button-fullscreen film" onClick={() => toggleFullScreen() }><i className="icon icon-resize-full-5"></i></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleLargeVideo)
