import React from 'react';
import { useTranslation } from "react-i18next";

export default function({ text, var1 }) {
    const { t } = useTranslation();
    let renderText = t(text);

    if (var1) {
       renderText = renderText.replace('[var1]', var1.toString());
    }

    const renderTextSpan = <span dangerouslySetInnerHTML={{__html: renderText}} />;

    return renderTextSpan;
}
