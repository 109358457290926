import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { dateToYMD } from '../utils/globals';

import 'react-day-picker/lib/style.css';
import '../styles/components/Input.scss';

class Input extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: '',
      invalid: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  setInvalid() {
    this.setState({ invalid: true });
    setTimeout(() => {
      this.setState({ invalid: false });
    }, 3000)
  }

  componentWillMount() {
    const { value } = this.props;
    if (value)
      this.setState({value});
  }

  handleChange(e) {
    const { onChange, noLeftZero, pattern } = this.props;
    const returnObj = {};

    if (
      !pattern || e.target.validity.valid &&
      !noLeftZero || (e.target.value.length < 1 || e.target.value > 0)
      ) {
      returnObj[e.target.name] = e.target.value;

      this.setState({ value: e.target.value, invalid: false });

      if (onChange)
        onChange(returnObj);
    }
  }

  handleDateChange(date) {
    const { onChange, name } = this.props;
    const returnObj = {};
    returnObj[name] = date;

    console.log(dateToYMD(date));

    this.setState({ value: date, invalid: false });

    if (onChange)
      onChange(returnObj);
  }

  onKeyDown(e) {
    const { onPressEnter } = this.props;

    if (e.keyCode === 13 && onPressEnter) {
      onPressEnter();
    }
  }

  render() {
    const { icon, placeholder, name, inline, label, disabled, type, pattern, maxLength } = this.props;
    const { value, invalid } = this.state;
    let inputClass = icon ? `input ${icon}`: "input";
    let inputType = "text";

    if (invalid)
      inputClass = `${inputClass} invalid`;

    if (type)
      inputType = type;

    if (inline) {
      inputClass = `${inputClass} inline`;
      return (
        <div className={inputClass}>
          <div className="input-name">{label}</div>
          <input
            disabled={disabled}
            value={value}
            onChange={this.handleChange}
            name={name}
            type={inputType}
            placeholder={placeholder}
            onKeyDown={(e) => this.onKeyDown(e)}
          />
        </div>
      );
    }

    if (type && type === "date") {
      return (
        <div className={inputClass}>
          <label htmlFor={name}>{label}</label>
          <DayPickerInput
            showOverlay={true}
            value={value}
            onDayChange={this.handleDateChange}
          />
        </div>
      );
    }

    if (type && type === "numeric") {
      return (
        <div className={inputClass}>
          {label && (
            <label htmlFor={name}>{label}</label>
          )}
          <input
            value={value}
            onChange={this.handleChange}
            name={name}
            type="number"
            placeholder={placeholder}
            min="0"
            inputMode="numeric"
            pattern="[0-9]*"
            onKeyDown={(e) => this.onKeyDown(e)}
          />
        </div>
      );
    }

    return (
      <div className={inputClass}>
        {label && (
          <label htmlFor={name}>{label}</label>
        )}
        <input
          pattern={pattern}
          maxLength={maxLength}
          value={value}
          onChange={this.handleChange}
          name={name}
          type={inputType}
          placeholder={placeholder}
          onKeyDown={(e) => this.onKeyDown(e)}
        />
      </div>
    );
  }
}

export default Input