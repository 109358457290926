const urls = {
    URL_LANDING: '/welcome',
    URL_WELCOME: '/introduction',
    URL_SIGNUP: '/signup',
    URL_REQUEST_ACCESS_CAMERA: '/request-access-camera',
    URL_CAMERA_CALIBRATION: '/camera-calibration',
    URL_EXPERIENCE: '/experience',
    URL_THANKS: '/thanks',
    URL_IOS_INCOMPATIBLE_DISCLAIMER: '/ios-disclaimer',
    URL_DESKTOP_INCOMPATIBLE_DISCLAIMER: '/desktop-browser-disclaimer',
    URL_ANDROID_INCOMPATIBLE_DISCLAIMER: '/android-browser-disclaimer',
    URL_DEVICE_INCOMPATIBLE_DISCLAIMER: '/device-disclaimer',
    URL_INCOMPATIBLE_BROWSER: '/incompatible-browser',
    URL_PRIVACY_POLICY: '/privacy-policy',
    URL_PRIVACY_POLICY_RESUME: '/privacy-policy-resume',
    URL_TERMS_AND_CONDITIONS: '/terms-and-conditions',
    URL_NOT_FOUND: '/not-found',
    URL_LINK_USED: '/link-used',
    URL_MODULES_DISPATCHER: '/test',
    URL_PREVIEW_PLAY: '/preview-play',
    URL_EXTERNAL_PROCESS: '/processing-test',
    URL_BILENDI_COMPLETE: 'https://survey.maximiles.com/static-complete'
  };


module.exports = urls;
