import {
  drawLine,
  drawContour,
  drawPoint
} from './canvasFunctions';

const meshLines = [
  {a:0, b:1},
  {a:3, b:5},
  {a:5, b:7},
  {a:7, b:8},
  {a:8, b:9},
  {a:9, b:11},
  {a:11, b:13},
  {a:15, b:16},
  {a:16, b:25},
  {a:25, b:22},
  {a:22, b:21},
  {a:21, b:18},
  {a:18, b:0},
  {a:0, b:36},
  {a:1, b:36},
  {a:18, b:38},
  {a:39, b:27},
  {a:21, b:27},
  {a:1, b:31},
  {a:7, b:57},
  {a:48, b:33},
  {a:31, b:27},
  {a:31, b:33},
  {a:33, b:35},
  {a:35, b:27},
  {a:48, b:57},
  {a:57, b:54},
  {a:54, b:33},
  {a:35, b:15},
  {a:45, b:16},
  {a:45, b:15},
  {a:57, b:9},
  {a:27, b:22},
  {a:27, b:42},
  {a:43, b:25},
  {a:48, b:3},
  {a:54, b:13},
  {a:3, b:31},
  {a:35, b:13},
  {a:3, b:1},
  {a:48, b:5},
  {a:54, b:11},
  {a:3, b:1},
  {a:15, b:13},
];

const meshPoints = [
  21,
  18,
  0,
  1,
  3,
  5,
  7,
  8,
  9,
  11,
  13,
  15,
  16,
  25,
  22,
  27,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  35,
  31,
  33,
  48,
  54,
  57
];


export function printMesh(ctx, points) {
  drawContour(ctx, points.slice(36, 42), true);
  drawContour(ctx, points.slice(42, 48), true);
  meshLines.map(point => drawLine(ctx, points[point.a], points[point.b]));
  meshPoints.map(point => drawPoint(ctx, points[point]));
};