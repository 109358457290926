import React from 'react';
import { compose } from 'redux';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { isDesktop, isChrome, isFirefox, isEdge, isAndroid } from 'react-device-detect';
import { TesterAuth, TesterAccess } from '@sounditi/ft2-api';
import { isIOS, isMobile, browserName, fullBrowserVersion, osName, deviceType, deviceDetect } from "react-device-detect";
import history from "./history";

import Notifications from './components/Notifications';
import RenderStyles from './components/RenderStyles';
import PreviewNavigation from './components/PreviewNavigation';
import T from './components/Translate';

import { setRedirect, clearRedirect } from './reducers/navigation';
import {
  clearSession,
  setCampaign,
  setLinkData,
  setIsBEP,
  setCampaignData,
  setCampaignDesignImages,
  setCampaignPreviewToken,
  setCampaignPreviewScreen,
  setActiveModule,
  setExternalProviderID,
  setExternalProvider,
  setTesterAge,
  setTesterGender,
} from './reducers/user';
import { showNotification } from './reducers/notifications';

import { getURLParameter, getURLCampaign, getURLToken, getPreviewScreen, getPreviewLang, isLocalhost, generateRandomNumber } from './utils/globals';

import {
  ANIMATION_NO_TRANSITION,
  ANIMATION_SLIDE_IN,
  ANIMATION_SLIDE_OUT
} from './config/transitions';

import {
  URL_LANDING,
  URL_WELCOME,
  URL_SIGNUP,
  URL_THANKS,
  URL_IOS_INCOMPATIBLE_DISCLAIMER,
  URL_DESKTOP_INCOMPATIBLE_DISCLAIMER,
  URL_ANDROID_INCOMPATIBLE_DISCLAIMER,
  URL_DEVICE_INCOMPATIBLE_DISCLAIMER,
  URL_INCOMPATIBLE_BROWSER,
  URL_PRIVACY_POLICY,
  URL_PRIVACY_POLICY_RESUME,
  URL_TERMS_AND_CONDITIONS,
  URL_NOT_FOUND,
  URL_LINK_USED,
  URL_MODULES_DISPATCHER,
  URL_PREVIEW_PLAY,
  URL_EXTERNAL_PROCESS
} from './config/urls';

import Landing from './containers/Landing';
import Introduction from './containers/Introduction';
import PolicyAndConditions from './containers/PolicyAndConditions';
import SignUp from './containers/SignUp';
import SplashScreen from './containers/SplashScreen';
import Thanks from './containers/Thanks';
import IOSDisclaimer from './containers/IOSDisclaimer';
import AndroidDisclaimer from './containers/AndroidDisclaimer';
import DesktopDisclaimer from './containers/DesktopDisclaimer';
import DeviceDisclaimer from './containers/DeviceDisclaimer';
import CameraInaccessible from './containers/CameraInaccessible';
import NotFound from './containers/NotFound';
import LinkUsed from './containers/LinkUsed';
import ModulesDispatcher from './containers/ModulesDispatcher';
import PreviewPlay from './containers/PreviewPlay';
import ExternalAudienceProcess from './containers/ExternalAudienceProcess';

import './assets/fonts/fontello/css/sounditi-client.css';
import './styles/App.scss';
import './styles/NavigationTransitions.css';
import './styles/Responsive.css';

const mapStateToProps = (state, ownProps) => ({
  redirect: state.navigation.redirect,
  fullWidth: state.navigation.fullWidth,
  tokenData: state.user.tokenData,
  tokenExpirated: state.user.tokenExpirated,
  campaign: state.user.campaign,
  campaignData: state.user.campaignData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  clearRedirect: () => dispatch(clearRedirect()),
  clearSession: val => dispatch(clearSession(val)),
  setLinkData: val => dispatch(setLinkData(val)),
  setIsBEP: val => dispatch(setIsBEP(val)),
  setCampaign: val => dispatch(setCampaign(val)),
  setCampaignPreviewToken: val => dispatch(setCampaignPreviewToken(val)),
  setCampaignPreviewScreen: val => dispatch(setCampaignPreviewScreen(val)),
  setCampaignData: val => dispatch(setCampaignData(val)),
  setCampaignDesignImages: val => dispatch(setCampaignDesignImages(val)),
  setActiveModule: val => dispatch(setActiveModule(val)),
  setExternalProviderID: val => dispatch(setExternalProviderID(val)),
  setExternalProvider: val => dispatch(setExternalProvider(val)),
  setTesterAge: val => dispatch(setTesterAge(val)),
  setTesterGender: val => dispatch(setTesterGender(val)),
  showNotification: val => dispatch(showNotification(val)),
  dispatch,
})

class App extends React.Component {
  _randomNumber = 1;

  constructor(props) {
    super(props);
    this.state = {
      navigationTransition: ANIMATION_SLIDE_IN,
      redirecting: false,
      initialized: false,
      splashScreen: "active",
      livePreviewEmbedded: false,
    };

    this.currentPathname = null;
    this.currentSearch = null;
  }

  componentWillMount() {
    this.initCampaign();
    this._randomNumber = Math.floor((Math.random() * 2) + 1);
  }

  componentWillUpdate(nextProps) {
    const { redirect, clearRedirect } = nextProps;

    if (redirect) {
      this.redirectTo(redirect);
      clearRedirect();
    }
  }

  componentDidMount() {
    // this.startParallax();

    const { history, showNotification } = this.props;

    history.listen((newLocation, action) => {
      if (action === "PUSH") {
        if (
          newLocation.pathname !== this.currentPathname ||
          newLocation.search !== this.currentSearch
        ) {
          // Save new location
          this.currentPathname = newLocation.pathname;
          this.currentSearch = newLocation.search;

          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search
          });
        }
      } else {
        // Send user back if they try to navigate back
        this.setState({ navigationTransition: ANIMATION_NO_TRANSITION }, () => {
          history.go(1);
          showNotification("backRestriction");
        });
        
      }
    });
  }

  redirectTo = (redirect) => {
    // console.log("redirectTo");
    // console.log(redirect);
    const { history } = this.props;
    const { redirecting } = this.state;
    const { route, animation } = redirect;

    if (!redirecting) {
      this.setState({ navigationTransition: animation, redirecting: true }, () => {
        history.push(route);
        setTimeout(() => this.setState({ redirecting: false }), 500);
      });
    }
  }

  async initCampaign(callback) {
    const {
      setIsBEP,
      setCampaign,
      setCampaignData,
      setCampaignPreviewToken,
      setCampaignPreviewScreen,
      setActiveModule,
      setExternalProviderID,
      setTesterAge,
      setTesterGender,
      setExternalProvider,
      campaign,
      i18n,
      setLinkData,
      t
    } = this.props;
    let campaignId = getURLCampaign();
    let previewToken = getURLToken();
    let previewScreen = getPreviewScreen();
    let previewLang = getPreviewLang();
    let kntrID = getURLParameter("kntrid") || undefined;
    let blndID = getURLParameter("blndid") || undefined;
    let tlnID = getURLParameter("tlnid") || undefined;
    let qstnprID = getURLParameter("qstnprid") || undefined;
    let lyzID = getURLParameter("lyzid") || undefined;
    let externalProviderID = undefined;

    if (blndID) {
      externalProviderID = blndID;
      setExternalProvider('bilendi');
    } else if (tlnID) {
      externalProviderID = tlnID;
      setExternalProvider('toluna');
    } else if (kntrID) {
      externalProviderID = kntrID;
      setExternalProvider('kantar');
    } else if (qstnprID) {
      externalProviderID = qstnprID;
      setExternalProvider('questionpro');
    } else if (lyzID) {
      externalProviderID = lyzID;
      setExternalProvider('alyze');
    }

    let testerAge = getURLParameter("a") || undefined;
    let testerGender = getURLParameter("g") || undefined;
    let livePreview = getURLParameter("livepreview") || false;
    let noCamera = getURLParameter("nc") || false;
    let livePreviewEmbedded = getURLParameter("livepreviewembedded") || false;

    const now = new Date().getTime();
    const sessionId = `${now}-${generateRandomNumber()}`;
    const browser = `${browserName}-${fullBrowserVersion}-${osName}`;
    const device = deviceType;

    // console.log("generate session id");

    this.setState({ livePreviewEmbedded });

    sessionStorage.setItem('sessionID-info', sessionId);
    sessionStorage.setItem('browser-info', browser);
    sessionStorage.setItem('device-info', device);

    let campaignResponse = [];

    if (!campaignId) {
      let urlLink = window.location.pathname.replace("/", "");

      try {
        if (urlLink !== "") {
          const testerAccess = new TesterAccess();
          const response = await testerAccess.getLinkByUrl(urlLink);

          if (
            response && (
              response.message === "link not found" ||
              response.message === "link not active" ||
              response.message === "Cannot read property 'campaignId' of undefined"
            )
          ) {
            this.sendEvent('tester_exception_link_not_found', { linkURL: urlLink });
            this.redirectTo({route: URL_NOT_FOUND, animation: ANIMATION_NO_TRANSITION});
            this.hideSplashScreen();
            return false;
          }

          if (
            response && response.message === "link used"
          ) {
            this.sendEvent('tester_exception_link_used', { linkURL: urlLink });
            this.redirectTo({route: URL_LINK_USED, animation: ANIMATION_NO_TRANSITION});
            this.hideSplashScreen();
            return false;
          }

          campaignResponse = response.campaign;
          setLinkData(response);

          if (response && response.campaignId)
            campaignId = response.campaignId;

          this.sendEvent('tester_session_start', { linkURL: urlLink });
        }
      } catch(error) {
        this.sendEvent('tester_exception_link_not_found', { linkURL: urlLink });
        this.redirectTo({route: URL_NOT_FOUND, animation: ANIMATION_NO_TRANSITION});
        this.hideSplashScreen();
        return false;
      }
    }

    localStorage.setItem('previewLang', previewLang);

    if (!campaignId && campaign)
      campaignId = campaign;

    await setCampaign(campaignId);

    try {
      if (previewToken) {
        const testerAccess = new TesterAccess(true, previewToken);
        const campaignData = await testerAccess.getCampaign();
        campaignResponse.data = campaignData.data;
        campaignResponse.features = campaignData.features;

        if (livePreview === "true" && campaignData.data.designPreview) {
          campaignResponse.data.design = campaignResponse.data.designPreview;
        }

        this.getCampaignDesignImages(campaignResponse, campaignId, previewToken);
      } else {
        if (!campaignResponse || campaignResponse.length < 1) {
          const testerAuth = new TesterAuth();
          campaignResponse = await testerAuth.peekCampaign(campaignId);
        }
        this.getCampaignDesignImages(campaignResponse, campaignId);
      }
      const title = t('App_Title');

      if (isLocalhost())
        console.log(campaignResponse);

      if (campaignResponse.userId === "aa1e7098-251a-4d74-8bd4-5ba4e33ba30e") {
        setIsBEP(true);
      }

      const campaignData = campaignResponse.data;
      campaignData.alyzeWatermark = campaignResponse?.features?.alyzeWatermark;
      // campaignData.modules.campaignModules[0].tech = "play";

      const campaignHasRecognition = campaignData.modules.campaignModules.filter(
        module => module.tech === "recognition"
      ).length > 0;

      const campaignModulesFiltered = campaignData.modules.campaignModules.filter((module) => {
        const type = module.type;

        if (type === 'video' && module.videos.length > 0) {
          return module;
        } else if (type === 'film' && module.videos.length > 0) {
          return module;
        } else if (type === 'audio' && module.audios.length > 0) {
          return module;
        } else if (type === 'image' && module.images.length > 0) {
          return module;
        } else if (type === 'qa' && module.questions.length > 0) {
          return module;
        }
      });

      campaignData.modules.campaignModules = Object.values(campaignModulesFiltered);

      // campaignData.modules.campaignModules[0].type = "film";
      // console.log( campaignData.modules.campaignModules);

      if (campaignHasRecognition && (!noCamera || noCamera === "false") && !isLocalhost()) {
      // if (campaignHasRecognition && (!noCamera || noCamera === "false")) {
        // if (!isMobile) {
          campaignData.modules.campaignModules.unshift({ type: "camera_calibration" });
        // }
        campaignData.modules.campaignModules.unshift({ type: "camera_permission" });
        campaignData.modules.campaignModules.unshift({
          icon: "icon-video-1",
          id: `instructions-video-${Date.now()}-${generateRandomNumber()}`,
          img: "card-video.png",
          name: "Video",
          randomize: "true",
          tech: "play",
          type: "video",
          videos: [
            {
              duration: 0,
              fileName: `instructions-video-v2-${campaignData.lang}.mp4`,
              name: `instructions-video-v2-${campaignData.lang}.mp4`,
            }
          ]
        });
      }

      // console.log(livePreview);
      // console.log(previewToken);

      /*
      if (previewToken) {
        campaignData.modules.campaignModules.map(campaignModule => {
          if (campaignModule.type && campaignModule.type === "film") {
            campaignModule.type = "video";
            campaignModule.videos = [
              {
                duration: 0,
                fileName: "preview-video-v2-en.mp4",
                name: "preview-video-v2-en.mp4",
              }
            ];
          }

          return campaignModule;
        });
      }
      */

      // console.log(campaignData);
      // campaignData.modules.campaignModules[0].tech = "choose";
      // console.log(campaignData.modules.campaignModules);

      if (livePreview === "true" && campaignData.designPreview) {
        campaignData.design = campaignData.designPreview;
      }

      if (previewToken && previewScreen && noCamera === "true") {
        const previewCampaignModules = campaignData.modules.campaignModules.map(module => {
          if (module.tech === "recognition")
            module.tech = "play";

          return module;
        });

        campaignData.modules.campaignModules = previewCampaignModules;
      }

      document.title = title;
      setCampaignPreviewToken(previewToken);
      setCampaignPreviewScreen(previewScreen);
      setCampaignData(campaignData);

      if (campaignData.lang === "es")
        i18n.changeLanguage(`default_es`);

      if (previewToken) {
        if (previewLang === 'es') {
          i18n.changeLanguage(`default_es`);
        } else {
          i18n.changeLanguage(`default_en`);
        }
      }

      if (!isDesktop && !isMobile) {
        this.sendEvent('tester_exception_incompatible_device');
        this.goToDeviceDisclaimer();
        return false;
      } else if (isIOS) {
        this.sendEvent('tester_exception_incompatible_ios');
        this.goToIOSDisclaimer();
        return false;
      } else if (isAndroid && (!isChrome && !isFirefox)) {
        this.sendEvent('tester_exception_incompatible_browser_android');
        this.goToAndroidDisclaimer();
        return false;
      } else if (isDesktop && (!isChrome && !isFirefox && !isEdge)) {
        this.sendEvent('tester_exception_incompatible_browser_desktop');
        this.goToDesktopDisclaimer();
        return false;
      } 

      if (!isIOS) {
        if (previewToken && previewScreen) {
          if (previewScreen === "core-landing") {
            this.redirectTo({route: URL_LANDING, animation: ANIMATION_NO_TRANSITION});
            // this.redirectTo({route: URL_EXTERNAL_PROCESS, animation: ANIMATION_NO_TRANSITION});
          } else if (previewScreen === "core-introduction") {
            this.redirectTo({route: URL_WELCOME, animation: ANIMATION_NO_TRANSITION});
          } else if (previewScreen === "core-finish") {
            this.redirectTo({route: URL_THANKS, animation: ANIMATION_NO_TRANSITION});
          } else {
            let found = false;
            campaignData.modules.campaignModules.forEach(module => {
              if (module.tech === "recognition")
                module.tech = "play";

              if (module.id === previewScreen)
                found = true;

              if (!found)
                module.done = true;
            });

            const activeModule = campaignData.modules.campaignModules.filter(module => module.id === previewScreen)[0];

            // if (activeModule.tech === "recognition")
            //    activeModule.tech = "play";

            // console.log("entra");
            setActiveModule(activeModule);

            if (activeModule.tech === "play") {
              this.redirectTo({route: URL_PREVIEW_PLAY, animation: ANIMATION_NO_TRANSITION});
            } else {
              this.redirectTo({route: URL_MODULES_DISPATCHER, animation: ANIMATION_NO_TRANSITION});
            }
          }
        } else {
          if (externalProviderID /*&& testerAge && testerGender*/) {
            setExternalProviderID(externalProviderID);
            // setTesterAge(testerAge);
            // setTesterGender(testerGender);
            // this.redirectTo({route: URL_SIGNUP, animation: ANIMATION_NO_TRANSITION});
          }

          this.redirectTo({route: URL_LANDING, animation: ANIMATION_NO_TRANSITION});
          // FAKE EXTERNAL PROCESS
          // this.redirectTo({route: URL_EXTERNAL_PROCESS, animation: ANIMATION_NO_TRANSITION});
        }
      }

      // this.redirectTo({route: URL_MODULES_DISPATCHER, animation: ANIMATION_SLIDE_OUT});

      if (callback) {
        callback();
      }
    } catch(error) {
      // setCampaign(undefined);
      this.redirectTo({route: URL_NOT_FOUND, animation: ANIMATION_NO_TRANSITION});
      this.hideSplashScreen();
    }
  }

  async sendEvent(action, data = {}) {
    // console.log(activeModule);
    const testerAccess = new TesterAccess();

    try {
      const createdAt = new Date().getTime();
      const sessionId = sessionStorage.getItem('sessionID-info');
      const browser = sessionStorage.getItem('browser-info');
      const device = sessionStorage.getItem('device-info');
      await testerAccess.postAction(action, null, "", device, browser, sessionId, createdAt, null, data);
      console.log({ postAction: { type: action, device, browser, sessionId, createdAt, data } });
    } catch(error) {
      // console.log(error);
    };
  }

  async getCampaignDesignImages(campaign, campaignId, previewToken) {
    const { setCampaignDesignImages, showNotification } = this.props;
    const campaignData = campaign.data;
    const userId = campaign.userId;
    let generalLogoURLFile = "";
    let landingLogoURLFile = "";
    let generalBackgroundURLFile = "";
    let landingBackgroundURLFile = "";

    const testerAccess = new TesterAccess(true, previewToken);
    const testerAuth = new TesterAuth();

    try {
      if (
        campaignData &&
        campaignData.design &&
        campaignData.design.generalLogoType === "image" &&
        campaignData.design.generalLogoURL &&
        campaignData.design.generalLogoURL.fileName
      ) {
        if (previewToken) {
          generalLogoURLFile = await testerAccess.getOfflineFile(campaignData.design.generalLogoURL.fileName);
        } else {
          generalLogoURLFile = await testerAuth.peekFile(userId, campaignData.design.generalLogoURL.fileName);
        }
      }

      if (
        campaignData &&
        campaignData.design &&
        campaignData.design.landingLogoType === "image" &&
        campaignData.design.landingLogoURL &&
        campaignData.design.landingLogoURL.fileName
      ) {
        if (previewToken) {
          landingLogoURLFile = await testerAccess.getOfflineFile(campaignData.design.landingLogoURL.fileName);
        } else {
          landingLogoURLFile = await testerAuth.peekFile(userId, campaignData.design.landingLogoURL.fileName);
        }
      }

      if (
        campaignData &&
        campaignData.design &&
        campaignData.design.generalBackgroundType === "image" &&
        campaignData.design.generalBackgroundURL &&
        campaignData.design.generalBackgroundURL.fileName
      ) {
        if (previewToken) {
          generalBackgroundURLFile = await testerAccess.getOfflineFile(campaignData.design.generalBackgroundURL.fileName);
        } else {
          generalBackgroundURLFile = await testerAuth.peekFile(userId, campaignData.design.generalBackgroundURL.fileName);
        }
      }

      if (
        campaignData &&
        campaignData.design &&
        campaignData.design.landingBackgroundType === "image" &&
        campaignData.design.landingBackgroundURL &&
        campaignData.design.landingBackgroundURL.fileName
      ) {
        if (previewToken) {
          landingBackgroundURLFile = await testerAccess.getOfflineFile(campaignData.design.landingBackgroundURL.fileName);
        } else {
          landingBackgroundURLFile = await testerAuth.peekFile(userId, campaignData.design.landingBackgroundURL.fileName);
        }
      }
    } catch(error) {
      // console.log("here!");
      showNotification("genericError");
    }

    let campaignDesignImages = [
      generalLogoURLFile,
      landingLogoURLFile,
      generalBackgroundURLFile,
      landingBackgroundURLFile
    ];

    campaignDesignImages = campaignDesignImages.filter(img => img.uri);

    let imagesPreloaded = 0;
    campaignDesignImages.forEach((preloadImage) => {
      if (preloadImage.uri) {
        const img = new Image();
        img.src = preloadImage.uri;
        img.onload = () => {
          imagesPreloaded++;

          if (campaignDesignImages.length === imagesPreloaded) {
            this.hideSplashScreen();
          }
        }
      }
    });

    await setCampaignDesignImages({
      generalLogoURLFile,
      landingLogoURLFile,
      generalBackgroundURLFile,
      landingBackgroundURLFile
    });

    if (campaignDesignImages.length >= 0) {
      this.hideSplashScreen();
    }
  }

  goToUnauthorizedHome() {
    // console.log("goToUnauthorizedHome");
    this.redirectTo({route: URL_WELCOME, animation: ANIMATION_SLIDE_OUT});
  }

  goToDeviceDisclaimer() {
    this.redirectTo({route: URL_DEVICE_INCOMPATIBLE_DISCLAIMER, animation: ANIMATION_NO_TRANSITION});
  }

  goToIOSDisclaimer() {
    this.redirectTo({route: URL_IOS_INCOMPATIBLE_DISCLAIMER, animation: ANIMATION_NO_TRANSITION});
  }

  goToAndroidDisclaimer() {
    this.redirectTo({route: URL_ANDROID_INCOMPATIBLE_DISCLAIMER, animation: ANIMATION_NO_TRANSITION});
  }

  goToDesktopDisclaimer() {
    this.redirectTo({route: URL_DESKTOP_INCOMPATIBLE_DISCLAIMER, animation: ANIMATION_NO_TRANSITION});
  }

  hideSplashScreen() {
    this.setState({ splashScreen: 'out' }, () =>
      setTimeout(() => this.setState({ initialized: true, splashScreen: 'hide' }), 450)
    );
  }

  startParallax() {
    const scene = document.getElementById('scene');
    new window.Parallax(scene);
  }

  render() {
    const {
      navigationTransition,
      redirecting,
      initialized,
      splashScreen,
      livePreviewEmbedded
    } = this.state;
    const { 
      redirectTo, 
      fullWidth, 
      campaignData, 
      activeModule
    } = this.props;
    const campaignId = getURLCampaign();
    const testerAuth = new TesterAuth();
    const alyzeWatermark = campaignData?.alyzeWatermark !== undefined ? campaignData?.alyzeWatermark : true;

    const RouteAuthorize = () => {
      if (!testerAuth.getToken() && !redirecting) {
        this.goToUnauthorizedHome();
      }

      return true;
    }

    let fullContainerClass = fullWidth ? "fullwidth" : "";

    if (campaignId) {
      fullContainerClass = fullContainerClass + ' ' + campaignId;
    }

    const classDesktopCover = "desktop-cover";
    const classDesktopCoverB = "desktop-cover left";

    return (
      <Route
        render={({ location }) => (
          <div className={fullContainerClass}>
            {livePreviewEmbedded === "true" && (
              <PreviewNavigation redirectTo={this.redirectTo} currentPath={history?.location?.pathname} />
            )}
            <RenderStyles />
            {(alyzeWatermark && initialized) &&
              <div className="logo-byalyze">
                <p><a href="https://cutt.ly/byAlyze" target="_blank">Powered by Alyze</a></p>
              </div>
            }
            {/*
            <div className={classDesktopCover}>
              <div className="logo-byalyze">
                <p><a href="https://byalyze.com/" target="_blank">Powered by Alyze®</a></p>
              </div>
            </div>
            <div className={classDesktopCoverB}></div>
            */}
            <Notifications />
            {!initialized && (
              <SplashScreen state={splashScreen} />
            )}
            <div style={{display: 'none'}}>
              {JSON.stringify(redirectTo)}
            </div>
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                classNames={navigationTransition}
                timeout={500}
              >
                <Switch location={location}>
                  <Route path={URL_MODULES_DISPATCHER}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <ModulesDispatcher livePreviewEmbedded={livePreviewEmbedded} />
                      )}
                    </div>
                  </Route>
                  <Route path={URL_LANDING}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <Landing campaignData={campaignData} />
                      )}
                    </div>
                  </Route>
                  <Route path={URL_WELCOME}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <Introduction campaignData={campaignData} />
                      )}
                    </div>
                  </Route>
                  <Route path={URL_SIGNUP}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <SignUp campaignData={campaignData}/>
                      )}
                    </div>
                  </Route>
                  <Route path={URL_THANKS}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <Thanks campaignData={campaignData} />
                      )}
                    </div>
                  </Route>
                  <Route path={URL_PREVIEW_PLAY}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <PreviewPlay campaignData={campaignData} />
                      )}
                    </div>
                  </Route>
                  <Route path={URL_IOS_INCOMPATIBLE_DISCLAIMER}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <IOSDisclaimer campaignData={campaignData} />
                      )}
                    </div>
                  </Route>
                  <Route path={URL_DESKTOP_INCOMPATIBLE_DISCLAIMER}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <DesktopDisclaimer campaignData={campaignData} />
                      )}
                    </div>
                  </Route>
                  <Route path={URL_ANDROID_INCOMPATIBLE_DISCLAIMER}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <AndroidDisclaimer campaignData={campaignData} />
                      )}
                    </div>
                  </Route>
                  <Route path={URL_DEVICE_INCOMPATIBLE_DISCLAIMER}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <DeviceDisclaimer campaignData={campaignData} />
                      )}
                    </div>
                  </Route>
                  <Route path={URL_EXTERNAL_PROCESS}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <ExternalAudienceProcess campaignData={campaignData} />
                      )}
                    </div>
                  </Route>
                  <Route path={URL_INCOMPATIBLE_BROWSER}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <CameraInaccessible campaignData={campaignData} />
                      )}
                    </div>
                  </Route>
                  <Route path={URL_PRIVACY_POLICY}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <PolicyAndConditions page="policy" />
                      )}
                    </div>
                  </Route>
                  <Route path={URL_PRIVACY_POLICY_RESUME}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <PolicyAndConditions page="policy-resume" />
                      )}
                    </div>
                  </Route>
                  <Route path={URL_TERMS_AND_CONDITIONS}>
                    <div className="screen-wrapper">
                      {campaignData && (
                        <PolicyAndConditions page="terms" />
                      )}
                    </div>
                  </Route>
                  <Route path={URL_NOT_FOUND}>
                    <div className="screen-wrapper">
                      <NotFound />
                    </div>
                  </Route>
                  <Route path={URL_LINK_USED}>
                    <div className="screen-wrapper">
                      <LinkUsed />
                    </div>
                  </Route>
                  <Route render={() => <div>Not Found</div>} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </div>
        )}
      />
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(App);
