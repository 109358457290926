import React from 'react';
import { connect } from 'react-redux';

import '../styles/components/Crosshair.scss';

const mapStateToProps = (state, ownProps) => ({})
const mapDispatchToProps = (dispatch, ownProps) => ({})

class Crosshair extends React.Component {
  render() {
    return (
      <div className="camera-crosshair">
        <div className="TL"></div>
        <div className="TR"></div>
        <div className="RT"></div>
        <div className="RB"></div>
        <div className="BR"></div>
        <div className="BL"></div>
        <div className="LB"></div>
        <div className="LT"></div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Crosshair)