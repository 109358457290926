import {
  SESSION_TRACKER_COOKIE_NAME,
  GLOBAL_TRACKER_COOKIE_NAME,
  LOG_TRACKS,
  LOG_DISABLED,
  LEAD
} from '../config/global';

import { getURLCampaign } from '../utils/globals';

import { AZURE_TRACKER_HOST } from '../config/azure'
const uuidv4 = require('uuid/v4');

class SounditiTracker {
  // When creating the tracker check on
  constructor() {
    this.tracker_url = `${AZURE_TRACKER_HOST}/track`;
    this.sess_cookie_name = SESSION_TRACKER_COOKIE_NAME;
    this.glob_cookie_name = GLOBAL_TRACKER_COOKIE_NAME;
    this.session_id = uuidv4();
    this.log_mode = LOG_TRACKS || false
    this.disabled = LOG_DISABLED || false
  }

  init = async () => {
    if (this.disabled) return;
    const _this = this;
    // create a glob_ui for each device
    if (!localStorage.getItem(this.glob_cookie_name))
      localStorage.setItem(this.glob_cookie_name, `_snd_${uuidv4()}`)

    if (sessionStorage.getItem(this.sess_cookie_name)) {
      this.session_id = sessionStorage.getItem(this.sess_cookie_name)
      const continuation_object = {
        type: "session_continue",
        anonymous_user: localStorage.getItem(this.glob_cookie_name),
        language: navigator.language,
        platform: navigator.platform
      }
      this.sendTrack(continuation_object);
    } else {
      sessionStorage.setItem(this.sess_cookie_name, this.session_id)
      const start_object = {
        type: "session_start",
        anonymous_user: localStorage.getItem(this.glob_cookie_name),
        language: navigator.language,
        platform: navigator.platform,
        lead: localStorage.getItem(LEAD)
      }
      this.sendTrack(start_object);
    }

    window.addEventListener('unload', function (e) {
      const end_object = { type: "session_end" }
      _this.sendTrack(end_object)
    });
  }

  /**
   * Add the session id to the analytic object
   *
   * @param {object}
   */
  datafy = data => {
    return JSON.stringify({
      sessionId: this.session_id,
      campaign: getURLCampaign(),
       ...data
    })
  }

  /**
   * logs or sends and event
   */
  sendTrack = analyticObject => {
    if (this.disabled) return;
    if (this.log_mode) {
      console.log('TRACK OBJECT', this.datafy(analyticObject));
    } else {
      navigator.sendBeacon(
        this.tracker_url,
        this.datafy(analyticObject))
    }
  }

  /**
   * track an screen event
   */
  screen = (name, rest) => {
    if (this.disabled) return;
    const analyticObject = { type: 'enter_on_screen', location: window.location.href, name, ...rest }
    if (this.log_mode) {
      console.log('TRACK SCREEN: ', this.datafy(analyticObject));
    } else {
      navigator.sendBeacon(
        this.tracker_url,
        this.datafy(analyticObject)
      );
    }
  }

  /**
   * tracks and action event
   */
  action = (name, rest) => {
    if (this.disabled) return;
    let analyticObject = { type: 'user_action', location: window.location.href, name, ...rest }
    if (this.log_mode) {
      console.log('TRACK ACTION: ', this.datafy(analyticObject));
    } else {
      navigator.sendBeacon(
        this.tracker_url,
        this.datafy(analyticObject)
      );
      window.gtag('event', name, {
        'event_category': 'user_action',
        'event_label': window.location.href
      });
    }
  }

  /**
   * tracks and action event
   */
  event = (name, rest) => {
    if (this.disabled) return;
    const analyticObject = { type: 'app_event', location: window.location.href, name, ...rest }
    if (this.log_mode) {
      console.log('TRACK EVENT: ', this.datafy(analyticObject));
    } else {
      navigator.sendBeacon(
        this.tracker_url,
        this.datafy(analyticObject)
      );
      window.gtag('event', name, {
        'event_category': 'app_event',
        'event_label': window.location.href
      });
    }
  }

  /**
   * track errors events
   */
  error = (name, err, info) => {
    if (this.disabled) return;
    const analyticObject = { type: 'error', location: window.location.href, name, err, info, navigator: window.navigator}
    if (this.log_mode) {
      console.log('TRACK ERROR: ', this.datafy(analyticObject));
    } else {
      navigator.sendBeacon(
        this.tracker_url,
        this.datafy(analyticObject)
      );
      window.gtag('event', name, {
        'event_category': 'error',
        'event_label': window.location.href
      });
    }
  }
}

export default new SounditiTracker();
