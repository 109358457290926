import * as faceapi from 'face-api.js';

let modelsLoaded = false;
let tinyFaceDetectorLoaded = false;
let faceLandmarkLoaded = false;

export function rgba_to_grayscale(rgba, nrows, ncols) {
  var gray = new Uint8Array(nrows*ncols);
  for(var r=0; r<nrows; ++r)
    for(var c=0; c<ncols; ++c)
      gray[r*ncols + c] = (2*rgba[r*4*ncols+4*c+0]+7*rgba[r*4*ncols+4*c+1]+1*rgba[r*4*ncols+4*c+2])/10;
  return gray;
}

export function loadModels() {
  const MODEL_URL = process.env.PUBLIC_URL + '/models';
  faceapi.loadTinyFaceDetectorModel(MODEL_URL).then(() => setModelLoaded('tinyFaceDetector'));
  faceapi.loadFaceLandmarkModel(MODEL_URL).then(() => setModelLoaded('faceLandmark'));
}

export async function getFace(video_input, video_canvas, inputSize = 512) {
  const options = new faceapi.TinyFaceDetectorOptions({ inputSize });
  const result = await faceapi.detectSingleFace(video_input, options).withFaceLandmarks();
  matchDimensions(video_canvas, video_input, true);
  // const resizedResult = await faceapi.resizeResults(result, dims);
  return result
}

function matchDimensions(input, reference, useMediaDimensions) {
  if (useMediaDimensions === void 0) { useMediaDimensions = false; }
  var _a = useMediaDimensions
      ? faceapi.getMediaDimensions(reference)
      : reference, width = _a.width, height = _a.height;
  input.width = width;
  input.height = height;
  return { width: width, height: height };
}

function setModelLoaded(model) {
  if (model === 'tinyFaceDetector')
    tinyFaceDetectorLoaded = true;

  if (model === 'faceLandmark')
    faceLandmarkLoaded = true;

  if (faceLandmarkLoaded && tinyFaceDetectorLoaded)
    modelsLoaded = true;
}

export function isModelsLoaded() {
  return modelsLoaded;
}