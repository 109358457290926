import React from 'react';
import { connect } from 'react-redux';
import { TesterAuth } from '@sounditi/ft2-api';

import Link from '../components/Link';
import T from '../components/Translate';

import { setRedirect, setFullWidth } from '../reducers/navigation';
import { clearSession, setCampaignData } from '../reducers/user';

import { ANIMATION_NO_TRANSITION } from '../config/transitions';
import { URL_EXTERNAL_PROCESS } from '../config/urls';

import 'react-activity/dist/react-activity.css';

const mapStateToProps = (state, ownProps) => ({
  isBEP: state.user.isBEP,
  campaign: state.user.campaign,
  campaignLogo: state.user.campaignLogo,
  campaignData: state.user.campaignData,
  externalProviderID: state.user.externalProviderID
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  clearSession: val => dispatch(clearSession(val)),
  setCampaignData: val => dispatch(setCampaignData(val)),
  setFullWidth: val => dispatch(setFullWidth(val)),
  dispatch,
})

class Thanks extends React.Component {
  componentWillMount() {
    const { setFullWidth, externalProviderID, setRedirect } = this.props;

    setFullWidth(false);

    if (externalProviderID) {
      setRedirect({route: URL_EXTERNAL_PROCESS, animation: ANIMATION_NO_TRANSITION});
    }
  }

  async goHome() {
    /*
    const { setRedirect, campaign, dispatch } = this.props;
    const nextScreen = { route: URL_WELCOME, animation: ANIMATION_SLIDE_IN };
    const testerAuth = new TesterAuth();
    const campaignResponse = await testerAuth.peekCampaign(campaign);

    dispatch(setCampaignData(campaignResponse.data)).then(() => {
      setRedirect(nextScreen);
    });
    */
  }

  goWizard() {
    window.location.href = "https://admin.byalyze.net/express/select-files";
  }

  goSignUp() {
    window.location.href = "https://admin.byalyze.net/login";
  }

  goToLink(link) {
    window.location.href = link;
  }

  render() {
    const { campaignLogo, campaignData, externalProviderID, isBEP } = this.props;

    const activeModule = campaignData.modules.coreModulesPost.filter(
      module => module.id === "core-finish"
    )[0];

    //const alyzeWatermark = campaignData.showPromoButton !== undefined ? campaignData.showPromoButton : true;
    const alyzeWatermark = campaignData.alyzeWatermark;

    if (externalProviderID) {
      return (
        <div className="screen thanks">
          <div className="screen-inner-wrapper">
            <div className="flexible-top">

            </div>
            <div className="fixed-bottom">
                <h1><T text="Thanks_Title" /></h1>
                <p><T text="Thanks_Text" /></p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="screen thanks instructions">
        <div className="screen-inner-wrapper">
          <div className="flexible-top">
            {campaignData.design.generalLogoType === "image" && campaignLogo && (
              <img className="logo-big" src={campaignLogo} alt="Sounditi logo" />
            )}
            {campaignData.design.generalLogoType === "text" &&
            campaignData.design.generalLogoText && (
              <div className="flexible-center">
                <div className="logo-text">{campaignData.design.generalLogoText}</div>
              </div>
            )}
          </div>
          <div className="fixed-bottom">
            {activeModule.title && (
              <h1>{activeModule.title}</h1>
            )}
            {activeModule.text && (
              <p>{activeModule.text}</p>
            )}
            {activeModule.externalLink && (
              <Link onClick={() => this.goToLink(activeModule.buttonLink)}>{activeModule.buttonLinkText}</Link>
            )}
            {/*
              <Link onClick={() => this.goHome()}>{activeModule.button}</Link>
            */}
            {!isBEP && alyzeWatermark && (
              <div className="create-your-own">
                <h2><T text="Thanks_Create_Yours_Text" /></h2>
                <Link main onClick={() => this.goSignUp()}>
                  <T text="Thanks_Create_Yours_CTA" />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Thanks)
