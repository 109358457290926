import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { dateToYMD } from '../utils/globals';

import 'react-day-picker/lib/style.css';
import '../styles/components/Input.scss';

class Select extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: '',
      invalid: false,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  setInvalid() {
    this.setState({ invalid: true });
    setTimeout(() => {
      this.setState({ invalid: false });
    }, 3000)
  }

  componentWillMount() {
    const { value } = this.props;
    if (value)
      this.setState({value});
  }

  handleChange (e) {
    const { onChange } = this.props;
    const returnObj = {};
    returnObj[e.target.name] = e.target.value;

    this.setState({ value: e.target.value, invalid: false });

    if (onChange)
      onChange(returnObj);
  }

  refresh() {
    const { value } = this.props;
    if (value)
      this.setState({value});
  }

  render() {
    const { name, label, last, options, placeholder } = this.props;
    const { value, invalid } = this.state;
    let inputClass = "input";

    if (invalid)
      inputClass = `${inputClass} invalid`;

    if (last)
      inputClass = `${inputClass} last`;

    const renderOptions = options.map(option => {
      return (
        <option key={option.value} value={option.value}>{option.label}</option>
      );
    });

    return (
      <div className={inputClass}>
        {label && (
          <label htmlFor={name}>{label}</label>
        )}
        <div className="input-wrapper select">
          <i className="icon-down-open-3" />
          <select name={name} value={value} onChange={this.handleChange}>
            {placeholder && (
              <option value="">{placeholder}</option>
            )}
            {renderOptions}
          </select>
        </div>
      </div>
    );
  }
}

export default Select