// TODO: add callback and renovate enviroments... remove production until ready.
const dev = {
  LOCATION_TOKEN: 'sounditi-token-dev',
  LOCATION_PROFILE: 'sounditi-profile-dev',
  LOCATION_TOKEN_EXPIRATION: 'sounditi-expiration-dev',
  SESSION_TRACKER_COOKIE_NAME: '_snd_a-dev',
  GLOBAL_TRACKER_COOKIE_NAME: '_snd_g-dev',
  LEAD: '_snd_lead-dev',
  UPLOAD_EXPERIENCES: true,
  LOG_TRACKS: false,
  LOG_DISABLED: true,
  DEFAULT_CAMPAIGN: 'facetrack',
  EXTERNAL_PROCESS_SOCKET_URL: 'wss://6kof2jwpr0.execute-api.eu-central-1.amazonaws.com/dev'
};

const pre = {
  LOCATION_TOKEN: 'sounditi-token-stag',
  LOCATION_PROFILE: 'sounditi-profile-stag',
  LOCATION_TOKEN_EXPIRATION: 'sounditi-expiration-stag',
  SESSION_TRACKER_COOKIE_NAME : '_snd_a-stag',
  GLOBAL_TRACKER_COOKIE_NAME : '_snd_g-stag',
  LEAD: '_snd_lead-stag',
  UPLOAD_EXPERIENCES: true,
  LOG_TRACKS: false,
  LOG_DISABLED: false,
  DEFAULT_CAMPAIGN: 'facetrack',
  EXTERNAL_PROCESS_SOCKET_URL: 'wss://nmqdch5bkh.execute-api.eu-central-1.amazonaws.com/pre'
};

const prod = {
  LOCATION_TOKEN: 'sounditi-token-prod',
  LOCATION_PROFILE: 'sounditi-profile-prod',
  LOCATION_TOKEN_EXPIRATION: 'sounditi-expiration-prod',
  SESSION_TRACKER_COOKIE_NAME: '_snd_a-prod',
  GLOBAL_TRACKER_COOKIE_NAME: '_snd_g-prod',
  LEAD: '_snd_lead-prod',
  UPLOAD_EXPERIENCES: true,
  LOG_TRACKS: false,
  LOG_DISABLED: false,
  DEFAULT_CAMPAIGN: 'facetrack',
  EXTERNAL_PROCESS_SOCKET_URL: 'wss://vptszttrnk.execute-api.eu-central-1.amazonaws.com/prod'
};

let config = dev;

switch (process.env.REACT_APP_STAGE) {
  case 'pre':
    config = pre;
    break;
  case 'prod':
    config = prod;
    break;
  default:
    break;
}

module.exports = config;
