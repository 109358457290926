export function initData(data) {
  return localStorage.getItem(data) ? localStorage.getItem(data) : undefined;
}

export function initTokenExpiration(tokenExpiration) {
  if (!tokenExpiration)
    return true;

  const tokenExpirated = new Date().getTime() >  1000 * tokenExpiration;
  return tokenExpirated;
}

export function getActiveModule(data) {
  const activeModule = data.modules.campaignModules.filter(module => {
    if (
      (!module.done || module.done !== true) && (
      module.type === "camera_permission" ||
      module.type === "camera_calibration" ||
      module.type === "video" ||
      module.type === "film" ||
      module.type === "audio" ||
      module.type === "image" ||
      module.type === "qa")
    ) {
      return module;
    }
  })[0];
  // console.log("getActiveModule");
  // console.log(activeModule);
  return activeModule;
}

export function getCampaingLogo(data) {
  let campaignLogo = '';
  if (
    data &&
    data.design &&
    data.design.generalLogoURL &&
    data.design.generalLogoType === "image"
  ) {
    campaignLogo = data.design.generalLogoURL;
  }
  return campaignLogo;
}

export async function getCampaingLogoLanding(data) {
  let campaignLogoLanding = '';
  if (
    data &&
    data.design &&
    data.design.landingLogoURL &&
    data.design.landingLogoType === "image"
  ) {
    campaignLogoLanding = data.design.landingLogoURL;
  }
  return campaignLogoLanding;
}