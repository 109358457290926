import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import RecordRTC from 'recordrtc';
import { TesterAccess } from '@sounditi/ft2-api';

import ExperienceProgress from '../../components/ExperienceProgress';
import T from '../../components/Translate';
import Link from '../../components/Link';

import { showNotification } from '../../reducers/notifications';
import { setRedirect, setFullWidth } from '../../reducers/navigation';
import {
  clearSession,
  setCampaignData,
  finishActiveModule
} from '../../reducers/user';

import { shuffle, generateRandomNumber, getImageAspect, toggleFullScreen } from '../../utils/globals';
import { ANIMATION_SLIDE_OUT } from '../../config/transitions';
import { URL_WELCOME, URL_INCOMPATIBLE_BROWSER } from '../../config/urls';

import 'react-activity/dist/react-activity.css';

const mapStateToProps = (state, ownProps) => ({
  campaignData: state.user.campaignData,
  activeModule: state.user.activeModule,
  campaign: state.user.campaign,
  campaignPreviewToken: state.user.campaignPreviewToken,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  clearSession: val => dispatch(clearSession(val)),
  setCampaignData: val => dispatch(setCampaignData(val)),
  finishActiveModule: val => dispatch(finishActiveModule(val)),
  setFullWidth: val => dispatch(setFullWidth(val)),
  showNotification: val => dispatch(showNotification(val)),
  dispatch,
})


class ModuleImage extends React.Component {
  _mount = true;
  _playerImage = undefined;
  _timeout = undefined;
  _mediaRecord = undefined;
  _clipsSended = [];

  constructor(props) {
    super(props);
    this.state = {
      totalSteps: 6,
      activeStep: 0,
      clips: undefined,
      currentClip: undefined,
      currentClipAspect: "",
      uploadClip: "",
      currentClipReady: false,
      clipsReady: false,
      videoReady: true,
      clipsStarted: false,
      mediaPlayable: true,
      videoHeight: 720,
      videoWidth: 1280,
      loading: true,
      loadingVideoRatio: false,
      videoRatio: 'landscape',
      finishedExperience: false,
      mediaStreamReady: false,
      clipLoaded: false,
      recognizingFace: true,
      processCanvasWidth: 480,
      processCanvasHeight: 480,
      videoContainerWidth: null,
      videoContainerHeight: null,
      activeModule: undefined
    };
  }

  componentWillMount() {
    const { setFullWidth } = this.props;

    setFullWidth(true);
  }

  componentDidMount() {
    setTimeout(() => {
      if (this._mount) {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          this.goCameraInaccessible();
          return false;
        }

        this.getClips();
        this.startMedia();
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._mount = false;
  }

  componentDidUpdate() {
    if (this._mount) {
      const {
        currentClipReady,
        clipsReady,
        videoReady,
        clipsStarted,
        mediaStreamReady
      } = this.state;

      if (currentClipReady && mediaStreamReady && videoReady && clipsReady && clipsStarted) {
        this.startClip();
      }

      if (clipsReady && videoReady && !clipsStarted && this._playerImage) {
        this.initClips();
      }
    }
  }

  async getClips() {
    const { showNotification, activeModule, campaignPreviewToken } = this.props;

    try {
      const testerAcessParams = campaignPreviewToken ? [true, campaignPreviewToken] : [];
      const testerAccess = new TesterAccess(...testerAcessParams);
      const userData = await testerAccess.getProfile();

      this.postAction('tester_enters_module');

      let clips = await Promise.all(activeModule.images.map(async (image) => {
        const fileInformation = await testerAccess.getOfflineFile(image.fileName);
        const nowDate = `${Date.now()}-${generateRandomNumber()}`;
        const interactionId = `${userData.id}-${nowDate}`;
        const fileName = image.fileName;
        const moduleId = activeModule.id;
        const aspect = await getImageAspect(fileInformation.uri);
        return { fileName, moduleId, interactionId, aspect, ...fileInformation };
      }));

      if (String(activeModule.randomize) === "true") {
        clips = shuffle(clips);
      }

      // console.log(clips);

      if (this._mount) {
        this.setState({
          clips,
          totalSteps: clips.length
        }, () => this.setState({ clipsReady: true }));
      }
    } catch (error) {
      console.log(error);
      showNotification("connectionError");
      this.goWelcome();
    };
  }

  initClips() {
    if (this._mount) {
      this.setState({ clipsStarted: true, loading: false }, () => {
        this.nextClip();
      });
    }
  }

  finishClip() {
    // console.log("finishClip");
    const { activeModule } = this.props;

    if (activeModule.tech === "play") {
      this.nextClip(() => this.uploadVideoRecord());
      return false;
    }

    if (activeModule.tech === "choose") {
      this.uploadVideoRecord();
      return false;
    }

    if (
      this._mediaRecord
      && this._mediaRecord.state
      && this._mediaRecord.state !== "inactive"
      && this._mediaRecord.state !== "paused"
    ) {
      // console.log("stop record");
      this._mediaRecord.stopRecording(() => {
        this.nextClip(() => this.uploadVideoRecord());
      });
    }
  }

  startClip() {
    // console.log("startClip");
    if (this._mount) {
      const { mediaPlayable, mediaStreamReady, clips, activeStep } = this.state;
      const { activeModule } = this.props;
      this.setState({ currentClipReady: false, loading: false }, async () => {
        if (mediaPlayable && mediaStreamReady) {
          // this._playerImage.play();
          if (activeModule.tech !== "choose") {
            this.postAction('tester_start_interaction', clips[activeStep - 1].fileName);
            this.postActionGA('GTM_load_interaction', "image", activeModule, clips[activeStep - 1].interactionId);
          }

          if (activeModule.tech !== "recognition") {
            // console.log("entra");
            this.startImageExposition();
          }

          if (
            this._mediaRecord &&
            this._mediaRecord.state &&
            this._mediaRecord.state !== "recording" &&
            this._mediaRecord.startRecording
          ) {
            // console.log("start record");
            this._mediaRecord.startRecording();
            this.startImageExposition();
          }
        }
      });
    }
  }

  startImageExposition() {
    // console.log("startImageExposition");
    if (this._mount) {
      const { activeModule } = this.props;

      if (
        activeModule &&
        activeModule.tech !== "choose"
      ) {
        if (this._timeout) {
          clearTimeout(this._timeout);
        }

        const seconds = activeModule.duration * 1000;

        this._timeout = setTimeout(() => {
          // console.log("timeout");
          this.finishClip();
        }, seconds);
      }
    }
  }

  nextClip(callback) {
    // console.log("nextClip");
    if (this._mount) {
      const { activeModule } = this.props;
      const { totalSteps, activeStep, clips } = this.state;
      const nextActiveStep = activeStep + 1;

      if (activeModule && activeModule.tech !== "choose" && activeStep > 0) {
        this.postAction('tester_end_interaction', clips[activeStep - 1].fileName);
          this.postActionGA('GTM_complete_interaction', "image", activeModule, clips[activeStep - 1].interactionId);
      }

      if (nextActiveStep <= totalSteps) {
        let uploadClip = clips[activeStep];

        if (activeStep > 0) {
          uploadClip = clips[activeStep - 1];
        }
        // console.log(clips[activeStep].uri);

        this.setState({
          currentClip: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        }, () => {
          setTimeout(() => {
            this.setState({
              activeStep: nextActiveStep,
              currentClip: clips[activeStep].uri,
              currentClipAspect: clips[activeStep].aspect,
              uploadClip,
              clipLoaded: false,
              currentClipReady: true,
            }, () => {
              if (callback)
                callback()
            });
          }, 100);
        });
        /*
        data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7
        */
      } else {
        this.setState({
          uploadClip: clips[totalSteps - 1],
          loading: true
        }, () => {
          if (callback)
            callback()
        });
      }
    }
  }

  manualNextClip() {
    if (this._mount) {
      const { totalSteps, activeStep, clips } = this.state;
      const nextActiveStep = activeStep + 1;

      if (nextActiveStep <= totalSteps) {
        // this._playerImage.pause();
        this.setState({
          activeStep: nextActiveStep,
          currentClip: clips[nextActiveStep - 1].uri,
          currentClipAspect: clips[nextActiveStep - 1].aspect,
          clipLoaded: false
        }, () => {
          // this._playerImage.play()
        });
      }
    }
  }

  manualPrevClip() {
    if (this._mount) {
      const { activeStep, clips } = this.state;
      const prevActiveStep = activeStep - 1;

      if (prevActiveStep > 0) {
        // this._playerImage.pause();
        this.setState({
          activeStep: prevActiveStep,
          currentClip: clips[prevActiveStep - 1].uri,
          currentClipAspect: clips[prevActiveStep - 1].aspect,
          clipLoaded: false
        }, () => {
          // this._playerImage.play()
        });
      }
    }
  }

  async chooseThis() {
    if (this._mount) {
      const { campaignPreviewToken } = this.props;
      const { activeStep, clips } = this.state;
      const choosedClip = clips[activeStep - 1];

      // this._playerImage.pause();
      this.setState({ loading: true });

      const testerAcessParams = campaignPreviewToken ? [true, campaignPreviewToken] : [];
      const testerAccess = new TesterAccess(...testerAcessParams);

      const moduleId = choosedClip.moduleId;
      const interactionId = choosedClip.interactionId;
      const q_id = choosedClip.moduleId;
      const question = "choose image";
      const answeropt = choosedClip.fileName;
      const createdAt = new Date().getTime();
      const sessionId = sessionStorage.getItem('sessionID-info');

      await testerAccess.postQuestion(moduleId, interactionId, q_id, question, answeropt, sessionId, createdAt);
      console.log({ postQuestion: { moduleId, interactionId, q_id, question, answeropt, sessionId, createdAt } });

      this.checkFinish();
    }
  }

  startMedia() {
    const { activeModule } = this.props;

    if (this._mount) {
      this._playerImage = document.getElementById('playerImage');

      /*
      if (this._playerImage){
        this._playerImage.addEventListener('error', () => {
          this.finishClip();
        }, true);

        this._playerImage.oncanplay = () => {
          if (this._mount)
            this.setState({
              currentClipReady: true,
              clipLoaded: true,
              mediaPlayable: true
            });
        }
      }
      */

      if (activeModule.tech !== "recognition") {
        this.setState({
          mediaStreamReady: true,
          videoReady: true,
          loadingVideoRatio: false
        });
        return false;
      }

      this.initMediaStream();
    }
  }

  async initMediaStream() {
    const videoSettings = { audio: false, video: true };
    this._video = document.getElementById('user_webcam');

    if (this._video) {
      navigator.mediaDevices.getUserMedia(videoSettings).then(async (stream) => {
        const { finishedExperience } = this.state;

        if (!finishedExperience) {
          this._video.srcObject = await stream;
          window.localStream = stream;

          this._mediaRecord = RecordRTC(stream, {
            type: 'video',
            mimeType: 'video/webm;codecs=vp8',
            disableLogs: true,
          });

          this._video.oncanplay = () => {
            if (this._mount) {
              this.setState({ mediaStreamReady: true, videoReady: true });
            }
          }
        }
      }).catch(error => {
        this.goCameraInaccessible();
      });
    }
  }

  stopMedia(callback) {
    if (
      this._mediaRecord &&
      this._mediaRecord.state &&
      this._mediaRecord.state !== "inactive" &&
      this._mediaRecord.state !== "paused"
    ) {
      this._mediaRecord.stopRecording();
      this._mediaRecord = undefined;
    }

    if (window.localStream) {
      window.localStream.getTracks().forEach((track) => {
        track.stop();
      })
      window.localStream.stop();
    }

    if (callback) {
      callback()
    }
  }

  async checkFinish() {
    if (this._mount) {
      const { clips, finishedExperience } = this.state;
      const { activeModule, finishActiveModule } = this.props;

      if (
        (this._clipsSended.length >= clips.length || activeModule.tech === "choose") &&
        finishedExperience === false
      ) {
        this.postAction('tester_end_module');

        this.setState({ finishedExperience: true }, () => {
          this.stopMedia(() => {
            setTimeout(() => {
              finishActiveModule();
            }, 500);
          })
        });
      }
    }
  }

  async uploadVideoRecord() {
    if (this._mount) {
      const { onUploadVideoRecord, activeModule } = this.props;
      const { uploadClip } = this.state;

      if (!this._mediaRecord) {
        if (activeModule.tech !== "choose") {
          if (this._clipsSended.indexOf(uploadClip) === -1) {
            this._clipsSended.push(uploadClip);
          }
          this.checkFinish();
        }

        return false;
      }

      const fileBlob = await this._mediaRecord.getBlob();
      if (fileBlob) {
        // console.log(window.URL.createObjectURL(fileBlob));
        const newUploadClip = JSON.parse(JSON.stringify(uploadClip));
        onUploadVideoRecord(newUploadClip, activeModule, fileBlob);
      }

      if (activeModule.tech !== "choose") {
        if (this._clipsSended.indexOf(uploadClip) === -1) {
          this._clipsSended.push(uploadClip);
        }
        this.checkFinish();
      }
    }
  }

  async postAction(action, interactionId = "") {
    const { activeModule, onEventSend } = this.props;
    onEventSend(action, activeModule, interactionId);
  }

  async postActionGA(action, interactionType = "image", activeModule = [], interactionId = "") {

    const { campaignPreviewToken } = this.props;

    const testerAcessParams = campaignPreviewToken ? [true, campaignPreviewToken] : [];
    const testerAccess = new TesterAccess(...testerAcessParams);
    const userData = await testerAccess.getProfile();

    window.dataLayer.push({
      'event': action,
      attributes: {
        interactionType: interactionType,
        campaignId: localStorage.getItem('snd-campaign'),
        interactionId: interactionId,
        duration: parseInt(activeModule.duration),
        testerId: userData.id,
        time: Date.now()
      }
    });
  }

  /* SILLY FUNCTIONS */

  goWelcome() {
    const { setRedirect } = this.props;
    const backScreen = {
      route: URL_WELCOME,
      animation: ANIMATION_SLIDE_OUT
    };
    setRedirect(backScreen);
  }

  goCameraInaccessible() {
    const { setRedirect } = this.props;
    const screen = {
      route: URL_INCOMPATIBLE_BROWSER,
      animation: ANIMATION_SLIDE_OUT
    };
    setRedirect(screen);
  }

  render() {
    const { activeModule } = this.props;
    const {
      totalSteps,
      activeStep,
      currentClip,
      currentClipAspect,
      mediaPlayable,
      videoWidth,
      videoHeight,
      loading,
      videoRatio,
      recognizingFace,
      processCanvasWidth,
      processCanvasHeight,
      videoContainerWidth,
      videoContainerHeight,
      loadingVideoRatio,
    } = this.state;
    const audioWrapperClassName = mediaPlayable ? "audio-wrapper hide" : "audio-wrapper";
    const blurCoverClassName = mediaPlayable ? "blur-cover" : "blur-cover blur";

    const videoClass = videoRatio === "landscape"
      ? "camera-fullscreen black-and-white landscape"
      : "camera-fullscreen black-and-white portrait";

    const screenClass = recognizingFace
      ? "screen experience analizing-face"
      : "screen experience";

    const playerClass = currentClipAspect;

    return (
      <div className={screenClass}>
        <video
          disablePictureInPicture
          onContextMenu={(e)=> e.preventDefault()}
          autoPlay
          playsInline
          muted
          id="user_webcam"
          style={{ position: 'fixed', visibility: 'hidden' }}
        />
        {(loading || loadingVideoRatio) && (
          <div className="screen loader">
            <Spinner speed={0.8} color="#ffffff" size={20} />
          </div>
        )}
        <div className={audioWrapperClassName}>
          <div className="press-play-wrapper">
            {activeStep <= 1 && (
              <h1><T text="Experience_IOS_play" /></h1>
            )}
            {activeStep > 1 && (
              <h1><T text="Experience_IOS_play_continue" /></h1>
            )}
          </div>
        </div>
        <div className={blurCoverClassName}>
          {activeModule && activeModule.tech === "recognition" && (
            <>
              <div className="analyzing-msg"><div className="dot"></div>Analyzing</div>
              <div className="camera-wrapper">
                <canvas
                  className="hide"
                  id="process-canvas"
                  width={processCanvasWidth}
                  height={processCanvasHeight}
                  style={{ width: processCanvasWidth, height: processCanvasHeight }}
                />
                <video
                  disablePictureInPicture
                  onContextMenu={(e)=> e.preventDefault()}
                  autoPlay
                  playsInline
                  muted
                  className={videoClass}
                  id="camera"
                  width={videoContainerWidth}
                  height={videoContainerHeight}
                  style={{ width: videoWidth, height: videoHeight }}
                />
                <video
                  disablePictureInPicture
                  onContextMenu={(e)=> e.preventDefault()}
                  autoPlay
                  playsInline
                  muted
                  id="camera_preview"
                  className="video-preview"
                />
                <div className="border-top"></div>
                <div className="border-left"></div>
                <div className="border-right"></div>
                <div className="search-face-msg">
                  <div className="err"><T text="Modules_Warning" /></div>
                </div>
              </div>
            </>
          )}
          <div className="layout-overlay">
            <div className="flexible-top">
              <img
                id="playerImage"
                className={playerClass}
                src={currentClip}
                alt="test media"
              />
            </div>
            <div className="fixed-bottom">
              {activeModule && activeModule.tech === "choose" && (
                <>
                  <Link main onClick={() => this.chooseThis()}><T text="Modules_Choose_This" /></Link>
                  <Link inline onClick={() => this.manualPrevClip()}>
                    <i className="icon icon-left-open-4"></i>
                  </Link>
                  <ExperienceProgress
                    totalSteps={totalSteps}
                    activeStep={activeStep}
                    choose={activeModule && activeModule.tech === "choose"}
                  />
                  <Link inline onClick={() => this.manualNextClip()}>
                    <i className="icon icon-right-open-4"></i>
                  </Link>
                </>
              )}
              <div className="button-fullscreen" onClick={() => toggleFullScreen() }><i className="icon icon-resize-full-5"></i></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleImage)
