import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Spinner } from 'react-activity';
import { TesterAuth, TesterAccess } from '@sounditi/ft2-api';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import T from '../components/Translate';
import Link from '../components/Link';
import Input from '../components/Input';
import Select from '../components/Select';
import Back from '../components/Back';
import PolicyCheck from '../components/PolicyCheck';

import { setSignupData, clearSignupData } from '../reducers/user';
import { showNotification } from '../reducers/notifications';
import { setRedirect, setFullWidth } from '../reducers/navigation';
import { setSession } from '../reducers/user';

import { isLocalhost } from '../utils/globals';

import {
  ANIMATION_SLIDE_IN
} from '../config/transitions';

import {
  URL_MODULES_DISPATCHER,
  URL_WELCOME,
} from '../config/urls';

const mapStateToProps = (state, ownProps) => ({
  campaign: state.user.campaign,
  signupData: state.user.signupData,
  campaignLogo: state.user.campaignLogo,
  campaignPreviewToken: state.user.campaignPreviewToken,
  externalProviderID: state.user.externalProviderID,
  externalProvider: state.user.externalProvider,
  testerAge: state.user.testerAge,
  testerGender: state.user.testerGender,
  linkData: state.user.linkData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setFullWidth: val => dispatch(setFullWidth(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  setSignupData: val => dispatch(setSignupData(val)),
  setSession: val => dispatch(setSession(val)),
  clearSignupData: val => dispatch(clearSignupData(val)),
  showNotification: val => dispatch(showNotification(val)),
  dispatch,
})

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataValidated: false,
      email: "",
      gender: "",
      age: "",
      birthdateYear: "",
      birthdateMonth: "",
      birthdateDay: "",
      country: "",
      city: "",
      policy: false,
      loading: false,
      hideLoading: false,
      genderInvalid: false,
      countryInvalid: false,
      cityInvalid: false,
    };
  }

  componentWillMount() {
    const { signupData, campaignPreviewToken, setFullWidth } = this.props;

    setFullWidth(false);

    if (signupData) {
      const {
        gender,
        email,
        age,
        birthdateYear,
        birthdateMonth,
        birthdateDay,
        country,
        city,
        policy,
      } = signupData;
      this.setState({
        gender,
        email,
        age,
        birthdateYear,
        birthdateMonth,
        birthdateDay,
        country,
        city,
        policy,
      }, () => this.validateData());
    } else if (campaignPreviewToken && isLocalhost()) {
        const gender = "nonbinary";
        const email = "preview@byalyze.com";
        const age = "30";
        const birthdateYear = "2020";
        const birthdateMonth = "1";
        const birthdateDay = "1";
        const country = "Spain";
        const city = "Madrid";
        const policy = true;
        this.setState({
          gender,
          email,
          age,
          birthdateYear,
          birthdateMonth,
          birthdateDay,
          country,
          city,
          policy,
        }, () => this.validateData());
    }
  }

  validateData() {
    let {
      policy,
      gender,
      age,
      birthdateYear,
      birthdateMonth,
      birthdateDay,
      email,
      country,
      city
    } = this.state;
    const { externalProviderID, testerGender, testerAge, externalProvider } = this.props;



    if (externalProviderID && externalProvider) {
      email = `${externalProviderID}@${externalProvider}.tester`;
    }
    /*
    if (testerGender) {
      if (testerGender.toLowerCase() === "1") {
        gender = "male";
      } else if (testerGender.toLowerCase() === "2") {
        gender = "female";
      } else {
        gender = "nonbinary";
      }
    }

    if (testerAge) {
      const date = new Date();
      date.setYear(date.getFullYear() - testerAge);

      birthdateDay = date.getDate().toString();
      birthdateMonth = (date.getMonth() + 1).toString(); //Month from 0 to 11
      birthdateYear = date.getFullYear().toString();
    }
    */
    const dataValidated = (
      gender !== '' &&
      email !== '' &&
      country !== '' &&
      city !== '' &&
      this.checkMailRegex(email) &&
      (this.checkValidAge(age) /*|| this.checkValidAge(testerAge)*/)  &&
      policy === true
    );

    /*
      birthdateYear.length === 4 &&
      birthdateMonth !== '' &&
      birthdateDay !== '' &&
    */
    this.setState({dataValidated});
  }

  onInputChange(val) {
    this.setState(val, ()=>{
      this.validateData();
    });
  }

  onSelectChange(field, val) {
    if (field === 'country') {
      this.setState({ city: '' });
    }

    this.setState({ [field]: val }, ()=>{
      this.validateData();
    });
  }

  setFieldInvalid(fieldInvalid) {
    this.setState({ [fieldInvalid]: true });
    setTimeout(() => {
      this.setState({ [fieldInvalid]: false });
    }, 3000)
  }

  async onSubmit() {
    
    let {
      loading,
      dataValidated,
      policy,
      gender,
      age,
      birthdateYear,
      birthdateMonth,
      birthdateDay,
      email,
      country,
      city
    } = this.state;
    const {
      setSignupData,
      showNotification,
      campaignPreviewToken,
      externalProviderID,
      testerGender,
      testerAge,
      linkData,
      externalProvider
    } = this.props;

    if (!loading) {
      //console.log("onSubmit")

      if (externalProviderID && externalProvider) {
        email = `${externalProviderID}@${externalProvider}.tester`;
      }
      
      /*
      if (testerGender) {
        if (testerGender === "1") {
          gender = "male";
        } else if (testerGender === "2") {
          gender = "female";
        } else {
          gender = "nonbinary";
        }
      }
      */

      if (/*testerAge ||*/ age) {
        /*if (!age)
          age = testerAge;*/

        const date = new Date();
        date.setYear(date.getFullYear() - age);

        birthdateDay = date.getDate().toString();
        birthdateMonth = (date.getMonth() + 1).toString(); //Month from 0 to 11
        birthdateYear = date.getFullYear().toString();
      }

      const linkURL = linkData?.linkUrl || "";
      const signupData = {
        email,
        gender,
        age,
        birthdateYear,
        birthdateMonth,
        birthdateDay,
        country,
        city,
        policy,
        linkURL
      };

      // console.log(signupData);
      this.postAction("tester_signup_submit_data", "", signupData);

      if (dataValidated) {
        this.setState({ loading: true, hideLoading: false });
        setSignupData(signupData);

        const birthdate = `${birthdateYear}-${this.pad(birthdateMonth)}-${this.pad(birthdateDay)}`;

        try {
          if (campaignPreviewToken) {
            await this.postAction("tester_login");
            this.goModulesDispatcher();
            return false;
          }

          const linkId = linkData && linkData.linkId ? linkData.linkId : "";
          const sessionId = sessionStorage.getItem('sessionID-info');

          const testerAuth = new TesterAuth();
          const response = await testerAuth.signUp("", email.toLowerCase(), "1234", gender, birthdate, linkId, sessionId, country, city);

          if (response && response.quotaFullRedirect) {
            window.location.replace(response.quotaFullRedirect);
          } else {
            if (response.created === true) {
              // window.fbq('trackCustom', 'SignUp');
              await this.postAction("tester_signup");
            } else {
              await this.postAction("tester_login");
            }

            this.setState({ hideLoading: true });
            this.goModulesDispatcher();
          }
        } catch(error) {
          console.log(error);
          this.setState({ loading: false });
          showNotification("genericError");
        }
      } else {
        if ((!email || !this.checkMailRegex(email)) && this.refs.email) {
          this.refs.email.setInvalid();
        }

        if (!gender || gender === "") {
          this.setFieldInvalid('genderInvalid');
        }

        if (!age || !this.checkValidAge(age) && this.refs.age) {
          this.refs.age.setInvalid();
        }

        if (country === "") {
          this.setFieldInvalid('countryInvalid');
        }

        if (city === "") {
          this.setFieldInvalid('cityInvalid');
        }

        /*
        if (!birthdateYear || birthdateYear.length !== 4 && this.refs.birthdateYear) {
          this.refs.birthdateYear.setInvalid();
        }

        if (!birthdateMonth && this.refs.birthdateMonth) {
          this.refs.birthdateMonth.setInvalid();
        }

        if (!birthdateDay && this.refs.birthdateDay) {
          this.refs.birthdateDay.setInvalid();
        }
        */

        if (!policy && this.refs.policy) {
          this.refs.policy.setInvalid();
        }

        showNotification("invalidData");
      }
    }
  }

  async postAction(action, interactionId = "", data = undefined) {
    const { campaign, campaignPreviewToken } = this.props;
    const testerAcessParams = campaignPreviewToken ? [ true, campaignPreviewToken ] : [];
    const testerAccess = new TesterAccess(...testerAcessParams);
    const createdAt = new Date().getTime();
    const sessionId = sessionStorage.getItem('sessionID-info');
    const browser = sessionStorage.getItem('browser-info');
    const device = sessionStorage.getItem('device-info');

    await testerAccess.postAction(action, `${campaign}-core-login`, interactionId, device, browser, sessionId, createdAt, undefined, data);
    console.log({postAction: {type: action, moduleId: `${campaign}-core-login`, interactionId, device, browser, sessionId, createdAt, data}});
  }

  pad(n) {
    return (n <= 9) ? ("0" + n) : n;
  }

  goModulesDispatcher() {
    const { setRedirect } = this.props;
    const nextScreen = {
      route: URL_MODULES_DISPATCHER,
      animation: ANIMATION_SLIDE_IN
    };

    setRedirect(nextScreen);
  }

  checkMailRegex(mail) {
    const r = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return r.test(mail.toLowerCase());
  }

  checkValidAge(age) {
    if (parseInt(age) >= 14 && parseInt(age) <= 99) {
      return true;
    }
    return false;
  }

  render() {
    const { gender, email, age, policy, birthdateYear, birthdateMonth, birthdateDay, loading, hideLoading, genderInvalid, country, city, countryInvalid, cityInvalid } = this.state;
    const { externalProviderID, setSignupData, campaignLogo, campaignData, t } = this.props;
    const signupData = { gender, email, policy, birthdateYear, birthdateMonth, birthdateDay, loading, country, city };
    const classScreen = externalProviderID ? "screen signup instructions" : "screen signup instructions";
    const blacklistCountries = ['AQ','IO', 'BQ', 'BV', 'CC', 'TF', 'HM', 'KP'];
    // t('LoginEmail_Input_Code')

    return (
      <div className={classScreen}>
        {loading && (
          <div className="screen loader">
            {!hideLoading && (
              <Spinner speed={0.8} color="#ffffff" size={20} />
            )}
          </div>
        )}
        {/*!externalProviderID && (
          <div className="float-button-left">
            <Back to={URL_WELCOME} onClick={() => clearSignupData()} />
          </div>
        )*/}
        <div className="screen-inner-wrapper">
          {/* {!externalProviderID && ( */}
            <div className="flexible-top">
              {campaignLogo && (
                <img className="logo-big" src={campaignLogo} alt="Sounditi logo" />
              )}
              {campaignData.design.generalLogoType === "text" &&
              campaignData.design.generalLogoText && (
                <div className="flexible-center">
                  <div className="logo-text">{campaignData.design.generalLogoText}</div>
                </div>
              )}
            </div>
          {/* )}
          {externalProviderID && (
            <div className="instructions">
              <h1><T text="Instructions_Title" /></h1>
              <T text="Instructions_Text" />
              <ul>
                <li><T text="Instructions_Step_A" /></li>
                <li><T text="Instructions_Step_B" /></li>
                <li><T text="Instructions_Step_C" /></li>
                <li><T text="Instructions_Step_D" /></li>
              </ul>
              {/*<T text="Instructions_Text_Location" />*/} {/*
            </div>
          )} */}
          <div className="fixed-bottom">
            {!externalProviderID && (
              <Input
                label={t('SignUp_Input_Label_Email')}
                placeholder={t('SignUp_Input_Email')}
                name="email"
                ref="email"
                value={email}
                onChange={val => this.onInputChange(val)}
                onPressEnter={e => this.onSubmit(e)}
              />
            )}

            {/*!externalProviderID &&*/ (
              <>
                {/*
                <Select
                  label={t('SignUp_Input_Label_Gender')}
                  placeholder={t('SignUp_Input_Gender')}
                  name="gender"
                  ref="gender"
                  value={gender}
                  onChange={val => this.onInputChange(val)}
                  options={[
                    { "value": "male", "label": t('SignUp_Input_Gender_Male') },
                    { "value": "female", "label": t('SignUp_Input_Gender_Female') },
                    { "value": "nonbinary", "label": t('SignUp_Input_Gender_Nonbinary') }
                  ]}
                />
                */}
                <div className={genderInvalid ? "input invalid" : "input"}>
                  <label><T text="SignUp_Input_Label_Gender" /></label>
                  <div className="input-row select-item">
                    <div className={gender === "male" ? "item active" : "item"} onClick={() => this.onInputChange({ gender: "male" })}>
                      <p><T text="SignUp_Input_Gender_Male" /></p>
                    </div>
                    <div className={gender === "female" ? "item active" : "item"} onClick={() => this.onInputChange({ gender: "female" })}>
                      <p><T text="SignUp_Input_Gender_Female" /></p>
                    </div>
                    <div className={gender === "nonbinary" ? "item active" : "item"} onClick={() => this.onInputChange({ gender: "nonbinary" })}>
                      <p><T text="SignUp_Input_Gender_Nonbinary" /></p>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/*!externalProviderID &&*/ (
              <>
                <Input
                  type="numeric"
                  pattern="[0-9]*"
                  label={t('SignUp_Input_Label_Age')}
                  placeholder={t('SignUp_Input_Age')}
                  name="age"
                  ref="age"
                  maxLength="2"
                  value={age}
                  onChange={val => this.onInputChange(val)}
                  onPressEnter={e => this.onSubmit(e)}
                />
                {/*
                <div className="input">
                  <label><T text="SignUp_Input_Label_Birthday" /></label>
                  <div className="input-row">

                    <Input
                      pattern="[0-9]*"
                      placeholder={t('SignUp_Input_Birthday_Day')}
                      name="birthdateDay"
                      ref="birthdateDay"
                      maxLength="2"
                      value={birthdateDay}
                      onChange={val => this.onInputChange(val)}
                      onPressEnter={e => this.onSubmit(e)}
                    />
                    <Input
                      pattern="[0-9]*"
                      placeholder={t('SignUp_Input_Birthday_Month')}
                      name="birthdateMonth"
                      ref="birthdateMonth"
                      maxLength="2"
                      value={birthdateMonth}
                      onChange={val => this.onInputChange(val)}
                      onPressEnter={e => this.onSubmit(e)}
                    />
                    <Input
                      noLeftZero
                      pattern="[0-9]*"
                      placeholder={t('SignUp_Input_Birthday_Year')}
                      name="birthdateYear"
                      ref="birthdateYear"
                      maxLength="4"
                      value={birthdateYear}
                      onChange={val => this.onInputChange(val)}
                      onPressEnter={e => this.onSubmit(e)}
                    />

                  </div>
                </div>
                */}
              </>
            )}

            <div className="input">
              <label><T text="SignUp_Input_Label_Location" /></label>
              <div className="input-row select-item">
                <div className="field">
                  <div className={countryInvalid ? "select invalid" : "select"}>
                    <CountryDropdown
                      value={country}
                      onChange={val => this.onSelectChange('country', val)}
                      required={true}
                      name="country"
                      ref="country"
                      blacklist={blacklistCountries}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className={cityInvalid ? "select invalid" : "select"}>
                    <RegionDropdown
                      country={country}
                      value={city}
                      onChange={val => this.onSelectChange('city', val)}
                      required={true}
                      name="city"
                      ref="city"
                    />
                  </div>
                </div>
              </div>
            </div>

            <PolicyCheck
              name="policy"
              ref="policy"
              onChange={val => this.onInputChange(val)}
              checked={policy}
              onClickViewPolicy={() => setSignupData(signupData)}
            />

            <Link onClick={e => this.onSubmit(e)}>
              {!externalProviderID && (
                <T text="SignUp_CTA" />
              )}
              {externalProviderID && (
                <T text="SignUp_bilendi_CTA" />
              )}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SignUp);
