export default {
    translations: {
        /* App */
        'App_Title': 'Test creado con Alyze',
        /* SignUp */
        'SignUp_Input_Label_Email': 'Correo Electrónico',
        'SignUp_Input_Email': 'Cuál es tu correo electrónico?',
        'SignUp_Input_Label_Gender': 'Género',
        'SignUp_Input_Gender': '¿Cuál es tu género?',
        'SignUp_Input_Gender_Male': 'Hombre',
        'SignUp_Input_Gender_Female': 'Mujer',
        'SignUp_Input_Gender_Nonbinary': 'No contestar',
        'SignUp_Input_Label_Birthday': 'Fecha de Nacimiento',
        'SignUp_Input_Label_Age': 'Edad',
        'SignUp_Input_Label_Location': 'Ubicación',
        'SignUp_Input_Age': '¿Cuál es tu edad?',
        'SignUp_Input_Birthday_Day': 'Día',
        'SignUp_Input_Birthday_Month': 'Mes',
        'SignUp_Input_Birthday_Year': 'Año',
        'SignUp_CTA': 'Siguiente',
        'SignUp_bilendi_CTA': 'COMENZAR',
        /* PolicyCheck */
        'PolicyCheck_Text_1': 'He leído y acepto la ',
        'PolicyCheck_Link_Privacy': 'política de privacidad',
        'PolicyCheck_Text_2': '<br />y los ',
        'PolicyCheck_Link_Terms': 'términos y condiciones',
        /* RequestAccessCamera */
        'RequestAccessCamera_Title': 'Por favor, permite el<br />acceso a tu cámara',
        'RequestAccessCamera_Description': '<b>Alyze</b> usará tu cámara<br />para reconocer y analizar<br />tus emociones',
        'RequestAccessCamera_Denied_camera': 'Para poder acceder a tu cámara necesitamos que nos des permiso desde las opciones de tu navegador',
        'RequestAccessCamera_CTA': 'Permitir',
        /* CameraCalibration */
        'CameraCalibration_Instructions_a': 'Coloca tu cara en el centro de la pantalla',
        'CameraCalibration_Instructions_b': 'Por favor, mantente centrado mientras calibramos el sistema',
        'CameraCalibration_Instructions_c': 'Analizando...<br />Estamos casi listos',
        'CameraCalibration_Description': 'Ahora <b>encuentra un lugar tranquilo</b>, colócate los <b>auriculares</b>, y presta atención a la pantalla',
        'CameraCalibration_CTA': 'Comenzar',
        /* Experience */
        'Experience_Button_Skip': 'Saltar instrucciones',
        /* Notifications */
        'ErrorNotification_InvalidData': 'Por favor revisa toda la información antes de continuar',
        'ErrorNotification_InvalidEmail': 'El correo electrónico que has introducido es incorrecto',
        'ErrorNotification_ConnectionError': 'No tienes conexión a internet. Por favor vuelve a intentarlo en unos minutos',
        'ErrorNotification_GenericError': 'Ha ocurrido un error inesperado. Por favor vuelve a intentarlo en unos minutos',
        'ErrorNotification_InvalidCode': 'El código de acceso que has introducido es incorrecto',
        'ErrorNotification_InvalidToken': 'La sesión ha caducado',
        'ErrorNotification_BackRestriction': 'Una vez se ha comenzado el test no es posible retroceder',
        /* NotFound */
        'NotFound_Title': 'Test no encontrado',
        'NotFound_Description': 'El test al que intentas acceder no existe o no está disponible',
        /* LinkUsed */
        'LinkUsed_Title': 'Link utilizado',
        'LinkUsed_Description': 'El link al que intentas acceder ya ha sido utilizado',
        /* ModuleDispatcher */
        'ModuleDispatcher_Loading_Title': 'Cargando',
        'ModuleDispatcher_Loading_Text': 'El test se está cargando',
        'ModuleDispatcher_Loading_Finish': '¡Carga<br />completada!',
        'ModuleDispatcher_Loading_Error_Title': 'Conexión perdida',
        'ModuleDispatcher_Loading_Error_Text': 'Para seguir con la prueba necesitamos que dispongas de una buena conexión<br /><br />Por favor, revisa tu conexión<br />antes de continuar',
        'ModuleDispatcher_Loading_Error_CTA': 'Intentar de nuevo',
        'ModuleDispatcher_Uploading_Title': 'Enviando',
        'ModuleDispatcher_Uploading_Text': 'Por favor, espera mientras<br />se envian los resultados',
        'ModuleDispatcher_Uploading_Finish': '¡Envio<br />completado!',
        'ModuleDispatcher_Uploading_Error_Title': 'Conexión perdida',
        'ModuleDispatcher_Uploading_Error_Text': 'Para enviar los resultados necesitamos que<br />dispongas de una buena conexión<br /><br />Por favor, revisa tu conexión<br />antes de continuar',
        'ModuleDispatcher_Uploading_Error_CTA': 'Intentar de nuevo',
        /* ExternalAudienceProcess */ 
        'ExternalAudienceProcess_Loading_Error_Title': 'Conexión perdida',
        'ExternalAudienceProcess_Loading_Error_Text': 'Para enviar los resultados necesitamos que<br />dispongas de una buena conexión<br /><br />Por favor, revisa tu conexión<br />antes de continuar',
        'ExternalAudienceProcess_Loading_Error_CTA': 'Intentar de nuevo',
        'ExternalAudienceProcess_Processing_Title': 'Procesando',
        'ExternalAudienceProcess_Processing_Text': 'Por favor, espera mientras tus respuestas son procesadas.',
        'ExternalAudienceProcess_Processing_Warning': 'ATENCIÓN: Tus respuestas no serán validadas hasta que estén todas procesadas. Por favor, aguarda sin cerrar esta ventana.<br /><small>Este proceso suele demorarse menos de 5 minutos.</small>',
        /* Modules */
        'Modules_Warning': 'Por favor, posiciona tu cara en las guias<br />y presta atención a la pantalla',
        'Modules_Choose_This': 'Elegir esta opción',
        /* External tester */
        'Thanks_Title': 'Gracias por participar',
        'Thanks_Text': 'Ya estamos procesando tu test y pronto verás reflejados los puntos en tu cuenta',
        'Instructions_Title': 'Estimado Participante',
        'Instructions_Text': '<p>Vamos a mostrarte contenido audiovisual como parte de este estudio</p><p>Queremos medir tus reacciones ante este contenido, por lo que <b>necesitamos analizar tu cara mientras visualizas este test</b></p><p>Será una máquina la que realice dicho análisis,<br/><b>ninguna persona humana verá tu rostro</b></p><p>Para que el test sea válido debes cumplir los siguientes requisitos:</p>',
        'Instructions_Step_A': '<b>Evita taparte la cara con las manos</b> u otro elemento y no apoyes la barbilla sobre tu mano',
        'Instructions_Step_B': '<b>Mantén un ángulo frontal de la cámara</b> respecto a tu cara en todo momento durante el test',
        'Instructions_Step_C': '<b>Evita espacios con mucha luz de fondo</b>',
        'Instructions_Step_D': '<b>Busca un espacio tranquilo</b>, en el que no seas interrumpido durante la realización del test',
        /* CameraInaccessible */
        'CameraInaccessible_Title': 'La cámara es inaccesible',
        'CameraInaccessible_Description': 'No podemos acceder a tu cámara. Por favor, prueba con otro navegador o revisa los permisos de acceso de tu cámara',
        'CameraInaccessible_CTA': 'Comprobar de nuevo',
        /* IncompatibleDevice */
        'IncompatibleDevice_Title': 'Dispositivo no soportado',
        'IncompatibleDevice_Description': 'Debido a las limitaciones de tu dispositivo, no podemos garantizar una buena experiencia de nuestra tecnología de reconocimiento emocional',
        'IncompatibleDevice_Description_2': 'Por favor, utilice un dispositivo Android o un ordenador',
        /* DesktopIncompatibleDisclaimer */
        'DesktopIncompatibleDisclaimer_Title': 'Navegador incompatible',
        'DesktopIncompatibleDisclaimer_Description': 'Tu navegador aún no tiene compatibilidad con la tecnología necesaria para que nuestro sistema funcione',
        'DesktopIncompatibleDisclaimer_Link': 'Pulsa aquí para obtener la versión más reciente de Google Chrome',
        /* Thanks */
        'Thanks_Create_Yours_Text': '¿Te gustaría crear un test como este?',
        'Thanks_Create_Yours_CTA': 'Crear mi propio test',
        /* noFaceRecognitionWarning */
        'noFaceRecognitionWarning_Modal_Info_Title': 'No podemos detectar tu cara bien',
        'noFaceRecognitionWarning_Modal_Info_Subtitle': 'Comprueba los siguientes puntos antes de continuar',
        'noFaceRecognitionWarning_Modal_Info_Text_List_1': 'Comprueba que tu cámara esté bien posicionada',
        'noFaceRecognitionWarning_Modal_Info_Text_List_2': 'Asegúrate de tener una buena iluminación',
        'noFaceRecognitionWarning_Modal_Info_Text_List_3': 'Intenta no taparte ni tocarte la cara durante la película',
        'noFaceRecognitionWarning_Modal_Info_Text_List_4': 'Evita cualquier distracción y presta atención a la película',
        'noFaceRecognitionWarning_Modal_Confirm': 'Continuar desde el último punto de control',
        /* showRightClickWarning */
        'showRightClickWarning_Modal_Info_Title': 'Acerca de este contenido',
        'showRightClickWarning_Modal_Info_Subtitle': '',
        'showRightClickWarning_Modal_Confirm': 'Continuar',
        'showRightClickWarning_Modal_Text': 'Este contenido es único. Ha sido generado para ti. Tus datos personales y tu dirección IP se han codificado en los metadatos del archivo, y se muestra una marca de agua durante la grabación.',
        /* User information watermark */
        'userInformationWatermark': 'Este vídeo ha sido generado para [var1] y vinculado a tu dirección IP',
        'userInformationWatermark_external': 'Este vídeo ha sido generado para ti y vinculado a tu dirección IP ([var1])',
        /* showNoFaceWarningLive */
        'showNoFaceWarningLive_Warning': 'ATENCIÓN: No podemos detectar tu cara.<br/>Por favor, comprueba tu cámara, la luz de la habitación y no te cubras la cara.',
        'showNoFaceWarningLive_Close': 'Cerrar aviso',
        /* ModuleLargeVideo */
        'messageFilmPaused': 'Continuarás desde el último checkpoint',
        'playerBarContinue': 'Continuar reproducción',
        'playerBarPause': 'Pausar reproducción',
        /* SkipModulePreview */
        'SkipModulePreview_Label': 'Ir al módulo',
        'SkipModulePreview_Prev': 'Anterior',
        'SkipModulePreview_Next': 'Siguiente',
    }
}