import React from 'react';
import { connect } from 'react-redux';
import T from '../components/Translate';
import { TesterAccess } from '@sounditi/ft2-api';
import Link from '../components/Link';
import { Circle } from 'rc-progress';

import { setFullWidth } from '../reducers/navigation';

import { EXTERNAL_PROCESS_SOCKET_URL } from '../config/global';

import 'react-activity/dist/react-activity.css';

const mapStateToProps = (state, ownProps) => ({
  campaignData: state.user.campaignData,
  campaignLogo: state.user.campaignLogo,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setFullWidth: val => dispatch(setFullWidth(val)),
  dispatch,
})

class ExternalAudienceProcess extends React.Component {
  _processTextAnimation = undefined;
  _mount = true;
  _sessionEndSended = false;

  constructor(props) {
    super(props);
    this.state = {
      activeModule: null,
      processPercent: 0,
      processTextAnimation: "",
      processError: false,
      sessionEnd: false
    };
  }

  componentWillMount() {
    const { setFullWidth } = this.props;

    setFullWidth(true);

    if (this._processTextAnimation === undefined) {
      this._processTextAnimation = setInterval(() => {
        let processTextAnimation = this.state.processTextAnimation + ".";

        if (processTextAnimation.length > 3)
          processTextAnimation = ""

        if (this._mount)
          this.setState({ processTextAnimation });
      }, 500);
    }

    this.startSocket();
  }

  async postAction(action, interactionId = "", data = undefined) {
    const { campaign, campaignPreviewToken } = this.props;
    const testerAcessParams = campaignPreviewToken ? [true, campaignPreviewToken] : [];
    const testerAccess = new TesterAccess(...testerAcessParams);
    const createdAt = new Date().getTime();
    const sessionId = sessionStorage.getItem('sessionID-info');
    const browser = sessionStorage.getItem('browser-info');
    const device = sessionStorage.getItem('device-info');

    await testerAccess.postAction(action, `${campaign}-core-finish`, interactionId, device, browser, sessionId, createdAt, undefined, data);
    console.log({ postAction: { type: action, moduleId: `${campaign}-core-finish`, interactionId, device, browser, sessionId, createdAt, data } });
  }

  startSocket(retry = false) {
    const { setFullWidth } = this.props;

    setFullWidth(true);

    this.setState({ processError: false });
    const sessionId = sessionStorage.getItem('sessionID-info');

    this.postAction('tester_processing_waiting_start');
    const socket = new WebSocket(EXTERNAL_PROCESS_SOCKET_URL);
   
    socket.onopen = (e) => {
      console.log('connected');

      socket.send(JSON.stringify({
        action: 'whoiam',
        sessionId
      }))

      if (!retry && !this._sessionEndSended) {
        this.postAction('tester_session_end');
        this._sessionEndSended = true;
      }
    };

    socket.onmessage = async (event) => {
      console.log(`[message] Data received from server: ${event.data}`);
      const data = JSON.parse(event.data);

      const processPercent = data.progress;
      this.setState({ processPercent });

      if (data.processed) {
        await this.postAction('tester_processing_waiting_finish');
        this.setState({ processPercent: 100 });
        setTimeout(() => {
          socket.close();
          window.location.replace(data.redirect);
        }, 2000);
      }
    };

    socket.onclose = (event) => {
      if (event.wasClean) {
        console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        console.log(`[close] Connection died; code=${event.code}`);
      }
    };

    socket.onerror = (error) => {
      if (!this._sessionEndSended) {
        this.postAction('tester_session_end');
        this._sessionEndSended = true;
      }

      this.postAction('tester_processing_waiting_error', "", { errorMessage: error.message });
      setFullWidth(false);
      console.log(`[error] ${error.message}`);
      this.setState({ processError: true });
      socket.close();
    };
  }

  startFakeSocket() {
    this.setState({ processError: false });

    setInterval(() => {
      const processPercent = this.state.processPercent + (Math.floor(Math.random() * (10 - 0)) + 0);
      this.setState({ processPercent });

      if (processPercent >= 100) {
        window.location.replace("http://www.google.com");
      }
    }, 2000);
  }

  render() {
    const {
      processPercent,
      processTextAnimation,
      processError,
    } = this.state;
    const {
      campaignData,
      campaignLogo,
    } = this.props;

    return (
      <div className="screen ios-disclaimer instructions">
        <div className="screen-inner-wrapper">
          <div className="flexible-top">
            {campaignData.design.generalLogoType === "image" && campaignLogo && (
              <img className="logo-big" src={campaignLogo} alt="Sounditi logo" />
            )}
            {campaignData.design.generalLogoType === "text" &&
            campaignData.design.generalLogoText && (
              <div className="flexible-center">
                <div className="logo-text">{campaignData.design.generalLogoText}</div>
              </div>
            )}
          </div>
          <div className="fixed-bottom">
            {!processError && (
              <>
                <div className="loading-resources-box">
                  <div className="loading-resources-text">
                    <h1><T text="ExternalAudienceProcess_Processing_Title" /> {parseInt(processPercent)}%</h1>
                    <p><T text="ExternalAudienceProcess_Processing_Text" /></p>
                    <p className="animated">{processTextAnimation}</p>
                  </div>
                  <Circle percent={processPercent} strokeWidth="4" trailWidth="4" strokeColor="#278DF4" />
                </div>
                <div className="process-warning">
                  <p><T text="ExternalAudienceProcess_Processing_Warning" /></p>
                </div>
              </>
            )}
            {processError && (
              <>
                <h1><T text="ModuleDispatcher_Loading_Error_Title" /></h1>
                <p><T text="ModuleDispatcher_Loading_Error_Text" /></p>
                <Link onClick={() => this.startSocket(true)}><T text="ModuleDispatcher_Loading_Error_CTA" /></Link>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalAudienceProcess)
