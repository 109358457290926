import React from 'react';
import Link from '../components/Link';
import T from '../components/Translate';

import {
  ANIMATION_SLIDE_OUT
} from '../config/transitions';

import {
  URL_PRIVACY_POLICY,
  URL_TERMS_AND_CONDITIONS,
  URL_WELCOME
} from '../config/urls';

// import iconInformation from '../assets/icon-information.png';
import '../styles/components/PolicyCheck.scss';

class PolicyCheck extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: '',
      invalid: false,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  setInvalid() {
    this.setState({ invalid: true });
    setTimeout(() => {
      this.setState({ invalid: false });
    }, 3000)
  }

  componentWillMount() {
    const { value } = this.props;
    if (value)
      this.setState({value});
  }

  goWelcome() {
    const { setRedirect } = this.props;
    const nextScreen = {
      route: URL_WELCOME,
      animation: ANIMATION_SLIDE_OUT
    };

    setRedirect(nextScreen);
  }

  handleChange (e) {
    const { onChange } = this.props;
    const returnObj = {};
    returnObj[e.target.name] = e.target.checked;

    this.setState({value: e.target.value});

    if (onChange)
      onChange(returnObj);
  }

  render() {
    const { name, checked, onClickViewPolicy } = this.props;
    const { value, invalid } = this.state;
    let checkClass = "policy-block"

    if (invalid)
      checkClass = `${checkClass} invalid`;

    return (
      <div className={checkClass}>
        <div className="check">
          <label className="container">
            <input
              checked={checked}
              name={name}
              value={value}
              type="checkbox"
              onChange={this.handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
          {/*
          <p><T text="PolicyCheck_Text_1" /><Link to={URL_PRIVACY_POLICY} onClick={() => onClickViewPolicy()} type="inline"><T text="PolicyCheck_Link_Privacy" /></Link><T text="PolicyCheck_Text_2" /><Link to={URL_TERMS_AND_CONDITIONS} onClick={() => onClickViewPolicy()} type="inline"><T text="PolicyCheck_Link_Terms" /></Link></p>
          */}
          <p>
            <T text="PolicyCheck_Text_1" />
            <a href="https://alyze.us/privacy-policy/" target="_blank"><T text="PolicyCheck_Link_Privacy" /></a>
            <T text="PolicyCheck_Text_2" />
            <a href="https://alyze.us/terms-of-use/" target="_blank"><T text="PolicyCheck_Link_Terms" /></a>
          </p>
        {/*
        <p>He leído y acepto la <Link to={URL_PRIVACY_POLICY} onClick={() => onClickViewPolicy()} type="inline">política de privacidad</Link> y los <Link to={URL_TERMS_AND_CONDITIONS} onClick={() => onClickViewPolicy()} type="inline">términos y condiciones</Link></p>
        {/*
        <Link to={URL_PRIVACY_POLICY_RESUME} onClick={() => onClickViewPolicy()} type="policy-information">
          <img src={iconInformation} alt="Icono información" />
        </Link>
        */}
      </div>
    );
  }
}

export default PolicyCheck
