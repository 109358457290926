import React from 'react';
import { connect } from 'react-redux';
import Link from '../components/Link';
import T from '../components/Translate';

import { setFullWidth } from '../reducers/navigation';

import {
  URL_MODULES_DISPATCHER
} from '../config/urls';

import 'react-activity/dist/react-activity.css';

const mapStateToProps = (state, ownProps) => ({
  campaignLogo: state.user.campaignLogo
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setFullWidth: val => dispatch(setFullWidth(val)),
})

class CameraInaccessible extends React.Component {
  componentWillMount() {
    const { setFullWidth } = this.props;

    setFullWidth(false);
  }

  render() {
    // window.fbq('trackCustom', 'CameraInaccessible');
    const { campaignLogo, campaignData } = this.props;

    return (
      <div className="screen incompatible-browser">
        <div className="screen-inner-wrapper">
          <div className="flexible-top">
            {campaignData.design.generalLogoType === "image" && campaignLogo && (
              <img className="logo-big" src={campaignLogo} alt="Sounditi logo" />
            )}
            {campaignData.design.generalLogoType === "text" &&
            campaignData.design.generalLogoText && (
              <div className="flexible-center">
                <div className="logo-text">{campaignData.design.generalLogoText}</div>
              </div>
            )}
          </div>
          <div className="fixed-bottom">
            <h1><T text="CameraInaccessible_Title" /></h1>
            <p><T text="CameraInaccessible_Description" /></p>
            <Link to={URL_MODULES_DISPATCHER}><T text="CameraInaccessible_CTA" /></Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CameraInaccessible)
