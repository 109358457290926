// COMPONENTS
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Dialog from 'rc-dialog';
import { Spinner } from 'react-activity';
import Link from './Link';
import T from './Translate';


// ACTIONS
import { showNotification } from '../reducers/notifications';
import { setRedirect } from '../reducers/navigation';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
})

class ModalDialogInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserOptions: false,
    };
  }

  render() {
    const {
      name,
      nameElement,
      showModal,
      closeModal,
      children,
      onConfirm,
      t,
      largeModal,
    } = this.props;

    if (!showModal)
      return false;

    const name_element = nameElement && nameElement !== '' ? ` (${nameElement})` : '';

    return (
      <Dialog
        title={t(`${name}_Modal_Info_Title`) + name_element}
        closeIcon={<i className="icon icon-cancel-6"></i>}
        visible={showModal}
        //closable={closeModal !== undefined}
        maskClosable={true}
        onClose={() => {
            closeModal();
        }}
        className={largeModal ? "dialogInfoLarge dialogInfo" : "dialogInfo"}
      >
        <p className="modal-subtitle"><T text={`${name}_Modal_Info_Subtitle`} /></p>
        <div className="modal-body">
          {children}
        </div>
        <div className="dialog-buttons">
          <Link onClick={() => {
            if (onConfirm)
              onConfirm();
          }}>
            <T text={`${name}_Modal_Confirm`} />
          </Link>
        </div>
      </Dialog>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(ModalDialogInfo);
