import { combineReducers } from 'redux'
import navigation from './navigation'
import user from './user'
import notifications from './notifications'


const experienceApp = combineReducers({
  navigation,
  user,
  notifications,
})

export default experienceApp