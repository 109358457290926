import React from 'react';
import { connect } from 'react-redux';
import T from '../components/Translate';

import { setFullWidth } from '../reducers/navigation';

const mapStateToProps = (state, ownProps) => ({
  campaignLogo: state.user.campaignLogo
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setFullWidth: val => dispatch(setFullWidth(val)),
})

class NotFound extends React.Component {
  componentWillMount() {
    const { setFullWidth } = this.props;

    setFullWidth(false);
  }

  render() {
    return (
      <div className="screen login-email">
        <div className="screen-inner-wrapper">
          <div className="flexible-top">
            <img className="logo-big" src="/assets/logo.svg" alt="Sounditi logo" />
          </div>
          <div className="fixed-bottom">
            <h1><T text="NotFound_Title" /></h1>
            <p><T text="NotFound_Description" /></p>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound)
