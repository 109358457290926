import { DEFAULT_CAMPAIGN } from '../config/global';

export function isCampaign(campaign) {
  const fullURL = window.location.host;
  const URLParts = fullURL.split('.');

  return URLParts[0] === campaign || URLParts[0] === `${campaign}-totem`;
}

export function getURLCampaign() {
  // let campaign = DEFAULT_CAMPAIGN;
  let campaign = getURLParameter("id");

  /*
  const fullURL = window.location.host;
  const URLParts = fullURL.split('.');

  if (URLParts.length >= 3) {
    campaign = URLParts[0];
  }
  */

  return campaign;
}

export function getPreviewScreen() {
  // let campaign = DEFAULT_CAMPAIGN;
  let campaign = getURLParameter("s");

  return campaign;
}

export function getPreviewLang() {
  // let campaign = DEFAULT_CAMPAIGN;
  let lang = getURLParameter("l");

  return lang;
}

export function getURLToken() {
  // let campaign = DEFAULT_CAMPAIGN;
  let campaign = getURLParameter("token");

  return campaign;
}

export function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

export function getJsonFromUrl(url) {
  // if (!url) url = location.href;
  const question = url.indexOf('?');
  let hash = url.indexOf('#');
  if (hash === -1 && question === -1) return {};
  if (hash === -1) hash = url.length;
  let query = question === -1 || hash === question + 1 ? url.substring(hash) :
    url.substring(question + 1, hash);
  let result = {};
  query.split('&').forEach(function (part) {
    if (!part) return;
    part = part.split('+').join(' '); // replace every + with space, regexp-free version
    let eq = part.indexOf('=');
    let key = eq > -1 ? part.substr(0, eq) : part;
    let val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : '';
    let from = key.indexOf('[');
    if (from === -1) result[decodeURIComponent(key)] = val;
    else {
      let to = key.indexOf(']', from);
      let index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!result[key]) result[key] = [];
      if (!index) result[key].push(val);
      else result[key][index] = val;
    }
  });
  return result;
}


export function getURLParameter(parameterName) {
  let result = null;
  let tmp = [];

  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });

  return result;
}

export function getPreviewURLParameter(parameterName) {
  let result = null;
  let tmp = [];

  if (!localStorage.getItem('previewURL'))
    return false

  const previewURL = localStorage.getItem('previewURL');

  previewURL
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });

  return result;
}

export function removeURLParam(key, sourceURL) {
  let rtn = sourceURL.split("?")[0];
  let param;
  let params_arr = [];
  let queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";

  if (queryString !== "") {
      params_arr = queryString.split("&");
      for (let i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split("=")[0];
          if (param === key) {
              params_arr.splice(i, 1);
          }
      }
      rtn = rtn + "?" + params_arr.join("&");
  }

  return rtn;
}

export function blobToFile(blob, fileName){
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  return blob;
}

export function shuffle(array) {
  return array.sort(function() {
      return .5 - Math.random();
  });
}

export function generateRandomNumber() {
  return Math.round(Math.random() * 1000000000);
}

export async function getImageAspect(src) {
  let orientation,
  img = new Image();
  img.src = src;

  return new Promise(resolve => {
    img.onload = () => {
      if (img.naturalWidth > img.naturalHeight) {
        orientation = 'landscape';
      } else {
        orientation = 'portrait';
      }

      resolve(orientation);
    };
  });
}

export async function getVideoAspect(src) {
  let orientation,
  video = document.createElement("video");
  video.src = src;

  return new Promise(resolve => {
    video.onloadeddata = () => {
      if (video.videoWidth > video.videoHeight) {
        orientation = 'landscape';
      } else {
        orientation = 'portrait';
      }

      resolve(orientation);
    };
  });
}

export function dateToYMD(date) {
  var d = date.getDate();
  var m = date.getMonth() + 1; //Month from 0 to 11
  var y = date.getFullYear();
  return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

export function isLocalhost() {
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
  );

  return isLocalhost;
}

export function formatFragment(fragmentString) {
  if (fragmentString < 10) {
    fragmentString = "00" + fragmentString;
  } else if (fragmentString < 100) {
    fragmentString = "0" + fragmentString;
  }

  return fragmentString;
}

export function parseFragment(fragmentString, fragmentNumberRAW) {
  let fragmentNumber = fragmentNumberRAW;

  if (fragmentNumber < 10) {
    fragmentNumber = "00" + fragmentNumber;
  } else if (fragmentNumber < 100) {
    fragmentNumber = "0" + fragmentNumber;
  }

  const fragment = `${fragmentString}-fragment-${fragmentNumber}`;

  return fragment;
}

export function toggleFullScreen() {
  const element = document.body;
  const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

  if (!isFullscreen) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
}