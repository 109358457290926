import React from 'react';
import { connect } from 'react-redux';
import { TesterAuth } from '@sounditi/ft2-api';

import Link from '../components/Link';
import T from '../components/Translate';

import { setRedirect, setFullWidth } from '../reducers/navigation';
import { clearSession, setCampaignData } from '../reducers/user';

import { ANIMATION_NO_TRANSITION } from '../config/transitions';
import { URL_MODULES_DISPATCHER } from '../config/urls';

import 'react-activity/dist/react-activity.css';

const mapStateToProps = (state, ownProps) => ({
  campaign: state.user.campaign,
  campaignLogo: state.user.campaignLogo,
  campaignData: state.user.campaignData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  clearSession: val => dispatch(clearSession(val)),
  setCampaignData: val => dispatch(setCampaignData(val)),
  setFullWidth: val => dispatch(setFullWidth(val)),
  dispatch,
})

class PreviewPlay extends React.Component {
  componentWillMount() {
    const { setFullWidth } = this.props;

    setFullWidth(true);
  }

  render() {
    const { campaignLogo, campaignData } = this.props;

    const activeModule = campaignData.modules.coreModulesPost.filter(
      module => module.id === "core-finish"
    )[0];

    const previewLang = localStorage.getItem('previewLang') || "en";
    const btnText = previewLang === "es" ? 'Pulsa aquí para empezar la previsualización' : 'Press here to start preview';

    return (
      <div className="screen thanks">
        <div className="screen-inner-wrapper">
          <div className="flexible-top"></div>
          <div className="fixed-bottom" style={{ maxWidth: "400px" }}>
            <Link onClick={() => {
              const { setRedirect} = this.props;
              const nextScreen = { route: URL_MODULES_DISPATCHER, animation: ANIMATION_NO_TRANSITION };

              setRedirect(nextScreen);
            }}>{btnText}</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPlay)
