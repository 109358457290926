import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import RecordRTC from 'recordrtc';
import { TesterAccess } from '@sounditi/ft2-api';

import ExperienceProgress from '../../components/ExperienceProgress';
import T from '../../components/Translate';
import Link from '../../components/Link';

import { showNotification } from '../../reducers/notifications';
import { setRedirect, setFullWidth } from '../../reducers/navigation';
import {
  clearSession,
  setCampaignData,
  finishActiveModule
} from '../../reducers/user';

import { shuffle, generateRandomNumber, fancyTimeFormat, toggleFullScreen } from '../../utils/globals';
import { ANIMATION_SLIDE_OUT } from '../../config/transitions';
import { URL_WELCOME, URL_INCOMPATIBLE_BROWSER } from '../../config/urls';

import 'react-activity/dist/react-activity.css';

const mapStateToProps = (state, ownProps) => ({
  campaignData: state.user.campaignData,
  activeModule: state.user.activeModule,
  campaign: state.user.campaign,
  campaignPreviewToken: state.user.campaignPreviewToken,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  clearSession: val => dispatch(clearSession(val)),
  setCampaignData: val => dispatch(setCampaignData(val)),
  finishActiveModule: val => dispatch(finishActiveModule(val)),
  setFullWidth: val => dispatch(setFullWidth(val)),
  showNotification: val => dispatch(showNotification(val)),
  dispatch,
})


class ModuleAudio extends React.Component {
  _mount = true;
  _playerAudio = undefined;
  _mediaRecord = undefined;
  _clipsSended = [];

  constructor(props) {
    super(props);
    this.state = {
      totalSteps: 6,
      activeStep: 0,
      clips: undefined,
      currentClip: undefined,
      uploadClip: "",
      currentClipReady: false,
      clipsReady: false,
      videoReady: true,
      clipsStarted: false,
      mediaPlayable: true,
      videoHeight: 720,
      videoWidth: 1280,
      loading: true,
      loadingVideoRatio: false,
      videoRatio: 'landscape',
      finishedExperience: false,
      mediaStreamReady: false,
      clipLoaded: false,
      recognizingFace: true,
      processCanvasWidth: 480,
      processCanvasHeight: 480,
      videoContainerWidth: null,
      videoContainerHeight: null,
      activeModule: undefined,
      playerBarHidden: false,
    };
  }

  componentWillMount() {
    const { setFullWidth } = this.props;

    setFullWidth(true);
  }

  componentDidMount() {
    setTimeout(() => {
      if (this._mount) {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          this.goCameraInaccessible();
          return false;
        }

        this.getClips();
        this.startMedia();
      }
    }, 1000);

    if (document.querySelector('.float-player-bar')) {
      let timeoutMouse;

      timeoutMouse = setTimeout(() => {
        this.setState({ playerBarHidden: true });
      }, 5000);

      document.addEventListener('mousemove', (event) => {
        clearTimeout(timeoutMouse);
        this.setState({ playerBarHidden: false });

        timeoutMouse = setTimeout(() => {
          this.setState({ playerBarHidden: true });
        }, 2000);
      })
    }
  }

  componentWillUnmount() {
    this._mount = false;
  }

  componentDidUpdate() {
    if (this._mount) {
      const {
        currentClipReady,
        clipsReady,
        videoReady,
        clipsStarted,
        mediaStreamReady
      } = this.state;

      if (currentClipReady && mediaStreamReady && videoReady && clipsReady && clipsStarted) {
        this.startClip();
      }

      if (clipsReady && videoReady && !clipsStarted && this._playerAudio) {
        this.initClips();
      }
    }
  }

  async getClips() {
    const { showNotification, activeModule, campaignPreviewToken } = this.props;

    try {
      const testerAcessParams = campaignPreviewToken ? [true, campaignPreviewToken] : [];
      const testerAccess = new TesterAccess(...testerAcessParams);
      const userData = await testerAccess.getProfile();

      this.postAction('tester_enters_module');

      let clips = await Promise.all(activeModule.audios.map(async (audio) => {
        const fileInformation = await testerAccess.getOfflineFile(audio.fileName);
        const nowDate = `${Date.now()}-${generateRandomNumber()}`;
        const interactionId = `${userData.id}-${nowDate}`;
        const fileName = audio.fileName;
        const moduleId = activeModule.id;
        return { fileName, moduleId, interactionId, ...fileInformation };
      }));

      if (String(activeModule.randomize) === "true") {
        clips = shuffle(clips);
      }

      if (this._mount) {
        this.setState({
          clips,
          totalSteps: clips.length
        }, () => this.setState({ clipsReady: true }));
      }
    } catch (error) {
      showNotification("connectionError");
      this.goWelcome();
    };
  }

  initClips() {
    if (this._mount) {
      this.setState({ clipsStarted: true, loading: false }, () => {
        this.nextClip();
        this._playerAudio.onpause = () => this.finishClip();
      });
    }
  }

  finishClip() {
    const { activeModule } = this.props;

    if (activeModule.tech !== "choose") {
      this.nextClip();
    }

    if (activeModule.tech !== "recognition") {
      this.uploadVideoRecord();
      return false;
    }

    if (
      this._mediaRecord
      && this._mediaRecord.state
      && this._mediaRecord.state !== "inactive"
      && this._mediaRecord.state !== "paused"
    ) {
      this._mediaRecord.stopRecording(() => {
        this.uploadVideoRecord()
      });
    }
  }

  startClip() {
    if (this._mount) {
      const { mediaPlayable, mediaStreamReady, clips, activeStep } = this.state;
      const { activeModule } = this.props;
      this.setState({ currentClipReady: false, loading: false }, async () => {
        if (mediaPlayable && mediaStreamReady) {
          this._playerAudio.play();
          if (activeModule.tech !== "choose") {
            this.postAction('tester_start_interaction', clips[activeStep - 1].fileName);
            this.postActionGA('GTM_load_interaction', activeStep, "audio", activeModule, clips[activeStep - 1].interactionId);
          }

          if (
            this._mediaRecord &&
            this._mediaRecord.state &&
            this._mediaRecord.state !== "recording" &&
            this._mediaRecord.startRecording
          ) {
            this._mediaRecord.startRecording();
          }
        }
      });
    }
  }

  nextClip() {
    if (this._mount) {
      const { activeModule } = this.props;
      const { totalSteps, activeStep, clips } = this.state;
      const nextActiveStep = activeStep + 1;

      if (activeModule.tech !== "choose" && activeStep > 0) {
        this.postAction('tester_end_interaction', clips[activeStep - 1].fileName);
          this.postActionGA('GTM_complete_interaction', activeStep, "audio", activeModule, clips[activeStep - 1].interactionId);
      }

      if (nextActiveStep <= totalSteps) {
        let uploadClip = clips[activeStep];

        if (activeStep > 0) {
          uploadClip = clips[activeStep - 1];
        }

        this.setState({
          activeStep: nextActiveStep,
          currentClip: clips[activeStep].uri,
          uploadClip,
          clipLoaded: false
        });
      } else {
        this._playerAudio.pause();

        this.setState({
          uploadClip: clips[totalSteps - 1],
          loading: true
        });
      }
    }
  }

  manualNextClip() {
    if (this._mount) {
      const { totalSteps, activeStep, clips } = this.state;
      const nextActiveStep = activeStep + 1;

      if (nextActiveStep <= totalSteps) {
        this._playerAudio.pause();
        this.setState({
          activeStep: nextActiveStep,
          currentClip: clips[nextActiveStep - 1].uri,
          clipLoaded: false
        }, () => this._playerAudio.play());
      }
    }
  }

  manualPrevClip() {
    if (this._mount) {
      const { activeStep, clips } = this.state;
      const prevActiveStep = activeStep - 1;

      if (prevActiveStep > 0) {
        this._playerAudio.pause();
        this.setState({
          activeStep: prevActiveStep,
          currentClip: clips[prevActiveStep - 1].uri,
          clipLoaded: false
        }, () => this._playerAudio.play());
      }
    }
  }

  async chooseThis() {
    if (this._mount) {
      const { campaignPreviewToken } = this.props;
      const { activeStep, clips } = this.state;
      const choosedClip = clips[activeStep - 1];

      this._playerAudio.pause();
      this.setState({ loading: true });

      const testerAcessParams = campaignPreviewToken ? [true, campaignPreviewToken] : [];
      const testerAccess = new TesterAccess(...testerAcessParams);

      const moduleId = choosedClip.moduleId;
      const interactionId = choosedClip.interactionId;
      const q_id = choosedClip.moduleId;
      const question = "choose audio";
      const answeropt = choosedClip.fileName;
      const createdAt = new Date().getTime();
      const sessionId = sessionStorage.getItem('sessionID-info');

      await testerAccess.postQuestion(moduleId, interactionId, q_id, question, answeropt, sessionId, createdAt);
      console.log({ postQuestion: { moduleId, interactionId, q_id, question, answeropt, sessionId, createdAt } });

      this.checkFinish();
    }
  }

  startMedia() {
    const { activeModule } = this.props;

    if (this._mount) {
      this._playerAudio = document.getElementById('playerAudio');

      if (this._playerAudio) {
        this._playerAudio.addEventListener('error', () => {
          this.finishClip();
        }, true);

        this._playerAudio.oncanplay = () => {
          if (this._mount)
            this.setState({
              currentClipReady: true,
              clipLoaded: true,
              mediaPlayable: true
            });
        }
      }

      if (activeModule.tech !== "recognition") {
        this.setState({
          mediaStreamReady: true,
          videoReady: true,
          loadingVideoRatio: false
        });
        return false;
      }

      this.initMediaStream();
    }
  }

  async initMediaStream() {
    const videoSettings = { audio: false, video: true };
    this._video = document.getElementById('user_webcam');

    if (this._video) {
      navigator.mediaDevices.getUserMedia(videoSettings).then(async (stream) => {
        const { finishedExperience } = this.state;

        if (!finishedExperience) {
          this._video.srcObject = await stream;
          window.localStream = stream;

          this._mediaRecord = RecordRTC(stream, {
            type: 'video',
            mimeType: 'video/webm;codecs=vp8',
            disableLogs: true,
          });

          this._video.oncanplay = () => {
            if (this._mount) {
              this.setState({ mediaStreamReady: true, videoReady: true });
            }
          }
        }
      }).catch(error => {
        this.goCameraInaccessible();
      });
    }
  }

  stopMedia(callback) {
    if (this._playerAudio) {
      this._playerAudio.pause();
    }

    if (
      this._mediaRecord &&
      this._mediaRecord.state &&
      this._mediaRecord.state !== "inactive" &&
      this._mediaRecord.state !== "paused"
    ) {
      this._mediaRecord.stopRecording();
      this._mediaRecord = undefined;
    }

    if (window.localStream) {
      window.localStream.getTracks().forEach((track) => {
        track.stop();
      });
      window.localStream.stop();
    }

    if (callback) {
      callback()
    }
  }

  async checkFinish() {
    if (this._mount) {
      const { clips, finishedExperience } = this.state;
      const { activeModule, finishActiveModule } = this.props;

      if (
        (this._clipsSended.length >= clips.length || activeModule.tech === "choose") &&
        finishedExperience === false
      ) {
        this.postAction('tester_end_module');

        this.setState({ finishedExperience: true }, () => {
          this.stopMedia(() => {
            setTimeout(() => {
              finishActiveModule();
            }, 500);
          })
        });
      }
    }
  }

  async uploadVideoRecord() {
    if (this._mount) {
      const { onUploadVideoRecord, activeModule } = this.props;
      const { uploadClip } = this.state;

      if (!this._mediaRecord) {
        if (activeModule.tech !== "choose") {
          if (this._clipsSended.indexOf(uploadClip) === -1) {
            this._clipsSended.push(uploadClip);
          }
          this.checkFinish();
        }

        return false;
      }

      const fileBlob = await this._mediaRecord.getBlob();
      if (fileBlob) {
        // console.log(window.URL.createObjectURL(fileBlob));
        const newUploadClip = JSON.parse(JSON.stringify(uploadClip));
        onUploadVideoRecord(newUploadClip, activeModule, fileBlob);
      }

      if (activeModule.tech !== "choose") {
        if (this._clipsSended.indexOf(uploadClip) === -1) {
          this._clipsSended.push(uploadClip);
        }
        this.checkFinish();
      }
    }
  }

  async postAction(action, interactionId = "") {
    const { activeModule, onEventSend } = this.props;
    onEventSend(action, activeModule, interactionId);
  }

  async postActionGA(action, activeStep = 1, interactionType = "audio", activeModule = [], interactionId = "") {

    const { campaignPreviewToken } = this.props;

    const testerAcessParams = campaignPreviewToken ? [true, campaignPreviewToken] : [];
    const testerAccess = new TesterAccess(...testerAcessParams);
    const userData = await testerAccess.getProfile();

    window.dataLayer.push({
      'event': action,
      attributes: {
        interactionType: interactionType,
        campaignId: localStorage.getItem('snd-campaign'),
        interactionId: interactionId,
        duration: activeModule.audios[activeStep - 1].duration,
        testerId: userData.id,
        time: Date.now()
      }
    });
  }

  /* SILLY FUNCTIONS */

  goWelcome() {
    const { setRedirect } = this.props;
    const backScreen = {
      route: URL_WELCOME,
      animation: ANIMATION_SLIDE_OUT
    };
    setRedirect(backScreen);
  }

  goCameraInaccessible() {
    const { setRedirect } = this.props;
    const screen = {
      route: URL_INCOMPATIBLE_BROWSER,
      animation: ANIMATION_SLIDE_OUT
    };
    setRedirect(screen);
  }

  render() {
    const { activeModule, campaignData } = this.props;
    const {
      totalSteps,
      activeStep,
      currentClip,
      mediaPlayable,
      videoWidth,
      videoHeight,
      loading,
      videoRatio,
      recognizingFace,
      processCanvasWidth,
      processCanvasHeight,
      videoContainerWidth,
      videoContainerHeight,
      loadingVideoRatio,
      playerBarHidden,
    } = this.state;
    //const alyzeWatermark = campaignData?.alyzeWatermark;
    const blurCoverClassName = mediaPlayable ? "blur-cover" : "blur-cover blur";

    const videoClass = videoRatio === "landscape"
      ? "camera-fullscreen black-and-white landscape"
      : "camera-fullscreen black-and-white portrait";

    const screenClass = recognizingFace
      ? "screen experience analizing-face"
      : "screen experience";

    let progressWidth = 0;
    let timeLeft = 0;

    if (this._playerAudio) {
      progressWidth = (100 * this._playerAudio.currentTime / this._playerAudio.duration).toFixed(2);
      timeLeft = this._playerAudio.duration - this._playerAudio.currentTime;

      if (progressWidth > 100)
        progressWidth = 100;

      if (timeLeft < 0)
        timeLeft = 0;

      timeLeft = fancyTimeFormat(timeLeft);
    }

    return (
      <div className={screenClass}>
        <video
          disablePictureInPicture
          onContextMenu={(e)=> e.preventDefault()}
          autoPlay
          playsInline
          muted
          id="user_webcam"
          style={{ position: 'fixed', visibility: 'hidden' }}
        />
        {(loading || loadingVideoRatio) && (
          <div className="screen loader">
            <Spinner speed={0.8} color="#ffffff" size={20} />
          </div>
        )}
        <div className={blurCoverClassName}>
          <div className="layout-overlay">
            <div className="flexible-top">
              <div className="hide">
                <audio preload="auto" id="playerAudio" src={currentClip} />
              </div>
              <i className="icon icon-sound-1"></i>
            </div>
            {activeModule && activeModule.tech !== "choose" && (
              <div className={playerBarHidden ? 'float-player-bar hidden' : 'float-player-bar'}>
                <div className="progress-bar">
                  <div className="progress" style={{ width: progressWidth + "%" }}></div>
                </div>
                <div className="progress-time">
                  {timeLeft}
                </div>
                <div className="button-fullscreen" onClick={() => toggleFullScreen() }><i className="icon icon-resize-full-5"></i></div>
              </div>
            )}
            {activeModule && activeModule.tech === "choose" && (
              <div className="fixed-bottom">
                <Link main onClick={() => this.chooseThis()}><T text="Modules_Choose_This" /></Link>
                <Link inline onClick={() => this.manualPrevClip()}>
                  <i className="icon icon-left-open-4"></i>
                </Link>
                <ExperienceProgress
                  totalSteps={totalSteps}
                  activeStep={activeStep}
                  choose={activeModule && activeModule.tech === "choose"}
                />
                <Link inline onClick={() => this.manualNextClip()}>
                  <i className="icon icon-right-open-4"></i>
                </Link>
                <div className="button-fullscreen" onClick={() => toggleFullScreen() }><i className="icon icon-resize-full-5"></i></div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleAudio)
