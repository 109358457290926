import React from 'react';
import { connect } from 'react-redux';

import Link from '../components/Link';
import Back from '../components/Back';

import { setRedirect, setFullWidth } from '../reducers/navigation';

import { URL_SIGNUP, URL_LANDING } from '../config/urls';
import { ANIMATION_SLIDE_IN } from '../config/transitions';

const mapStateToProps = (state, ownProps) => ({
  campaignLogo: state.user.campaignLogo,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setFullWidth: val => dispatch(setFullWidth(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  dispatch
})

class Introduction extends React.Component {
  componentWillMount() {
    const { setFullWidth } = this.props;

    setFullWidth(false);
  }

  goSignup() {
    const { setRedirect } = this.props;
    const nextScreen = {
      route: URL_SIGNUP,
      animation: ANIMATION_SLIDE_IN
    };

    setRedirect(nextScreen);
  }

  render() {
    const { campaignLogo, campaignData } = this.props;

    const activeModule = campaignData.modules.coreModulesPre.filter(
      module => module.id === "core-introduction"
    )[0];

    return (
      <div className="screen welcome instructions">
        <div className="version">0.1.0</div>
        {/*
        <div className="float-button-left">
          <Back to={URL_LANDING} />
        </div>
        */}
        <div className="screen-inner-wrapper">
          {(
            (campaignData.design.generalLogoType === "image" && campaignLogo) ||
            (campaignData.design.generalLogoType === "text" && campaignData.design.generalLogoText )
          ) && (
            <div className="flexible-top">
              {campaignData.design.generalLogoType === "image" && campaignLogo && (
                <img className="logo-big" src={campaignLogo} alt="Sounditi logo" />
              )}
              {campaignData.design.generalLogoType === "text" &&
              campaignData.design.generalLogoText && (
                <div className="flexible-center">
                  <div className="logo-text">{campaignData.design.generalLogoText}</div>
                </div>
              )}
            </div>
          )}
          <div className="fixed-bottom">
            {activeModule.title && (
              <h1>{activeModule.title}</h1>
            )}
            {activeModule.text && (
              <p>{activeModule.text}</p>
            )}
            <Link onClick={() => this.goSignup()}>{activeModule.button}</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Introduction)
