import {
  LOCATION_PROFILE,
  LOCATION_TOKEN,
  LOCATION_TOKEN_EXPIRATION
} from '../config/global';
import {
  initData,
  initTokenExpiration,
  getActiveModule,
} from '../utils/userReducerUtils';

const initState = {
  userData: initData(LOCATION_PROFILE),
  tokenData: initData(LOCATION_TOKEN),
  tokenExpiration: initData(LOCATION_TOKEN_EXPIRATION),
  tokenExpirated: initTokenExpiration(initData(LOCATION_TOKEN_EXPIRATION)),
  signupData: undefined,
  campaign: initData('snd-campaign'),
  campaignPreviewToken: undefined,
  campaignPreviewScreen: undefined,
  campaignData: undefined,
  activeModule: undefined,
  campaignLogo: undefined,
  campaignLogoLanding: undefined,
  campaignBackground: undefined,
  campaignBackgroundLanding: undefined,
  resourcesLoaded: false,
  filesToSend: [],
  uploadedFiles: [],
  failedFiles: [],
  allModulesFinished: false,
  uploadingError: false,
  moduleLoaded: true,
  externalProviderID: undefined,
  externalProvider: undefined,
  testerAge: undefined,
  testerGender: undefined,
  linkData: undefined,
  isBEP: false
};

export const loadActiveModule = payload => Promise.resolve({
  type: 'LOAD_ACTIVE_MODULE',
  payload,
});

export const setUploadingError = payload => Promise.resolve({
  type: 'SET_UPLOADING_ERROR',
  payload,
});

export const setFilesToSend = payload => Promise.resolve({
  type: 'SET_FILES_TO_SEND',
  payload,
});

export const setUploadedFiles = payload => Promise.resolve({
  type: 'SET_UPLOADED_FILES',
  payload,
});

export const setFailedFiles = payload => Promise.resolve({
  type: 'SET_FAILED_FILES',
  payload,
});

export const setAllModulesFinished = payload => Promise.resolve({
  type: 'SET_ALL_MODULES_FINISHED',
  payload,
});

export const setSignupData = payload => Promise.resolve({
  type: 'SET_SIGNUP_DATA',
  payload,
});

export const clearSignupData = () => Promise.resolve({
  type: 'CLEAR_SIGNUP_DATA',
});

export const setUserData = payload => Promise.resolve({
  type: 'SET_USER_DATA',
  payload,
});

export const setLinkData = payload => Promise.resolve({
  type: 'SET_LINK_DATA',
  payload,
});

export const setIsBEP = payload => Promise.resolve({
  type: 'SET_IS_BEP',
  payload,
});

export const setCampaign = payload => Promise.resolve({
  type: 'SET_CAMPAIGN',
  payload,
});

export const setCampaignPreviewToken = payload => Promise.resolve({
  type: 'SET_CAMPAIGN_PREVIEW_TOKEN',
  payload,
});

export const setCampaignPreviewScreen = payload => Promise.resolve({
  type: 'SET_CAMPAIGN_PREVIEW_SCREEN',
  payload,
});

export const setCampaignDesignImages = payload => Promise.resolve({
  type: 'SET_CAMPAIGN_DESIGN_IMAGES',
  payload,
});

export const setCampaignData = payload => Promise.resolve({
  type: 'SET_CAMPAIGN_DATA',
  payload,
});

export const setActiveModule = payload => Promise.resolve({
  type: 'SET_ACTIVE_MODULE',
  payload,
});

export const setResourcesLoaded = payload => Promise.resolve({
  type: 'SET_RESOURCES_LOADED',
  payload,
});

export const finishActiveModule = payload => Promise.resolve({
  type: 'FINISH_ACTIVE_MODULE',
  payload,
});

export const prevActiveModule = payload => Promise.resolve({
  type: 'PREV_ACTIVE_MODULE',
  payload,
});

export const goToModule = payload => Promise.resolve({
  type: 'GO_TO_MODULE',
  payload,
});

export const resetModules = payload => Promise.resolve({
  type: 'RESET_MODULES',
  payload,
});

export const setSession = payload => Promise.resolve({
  type: 'SET_SESSION',
  payload,
});

export const clearSession = () => Promise.resolve({
  type: 'CLEAR_SESSION',
});

export const checkTokenExpiration = payload => Promise.resolve({
  type: 'CHECK_LOCATION_TOKEN_EXPIRATION',
  payload,
});

export const setExternalProviderID = payload => Promise.resolve({
  type: 'SET_EXTERNAL_PROVIDER_ID',
  payload,
});

export const setExternalProvider = payload => Promise.resolve({
  type: 'SET_EXTERNAL_PROVIDER',
  payload,
});

export const setTesterAge = payload => Promise.resolve({
  type: 'SET_TESTER_AGE',
  payload,
});

export const setTesterGender = payload => Promise.resolve({
  type: 'SET_TESTER_GENDER',
  payload,
});

const user = (state = initState, action) => {
  switch (action.type) {
    case 'LOAD_ACTIVE_MODULE': {
      return { ...state, moduleLoaded: true }
    }
    case 'SET_UPLOADING_ERROR': {
      const uploadingError = action.payload;
      return { ...state, uploadingError }
    }
    case 'SET_FILES_TO_SEND': {
      const filesToSend = action.payload;
      return { ...state, filesToSend }
    }
    case 'SET_UPLOADED_FILES': {
      const uploadedFiles = action.payload;
      return { ...state, uploadedFiles }
    }
    case 'SET_FAILED_FILES': {
      const failedFiles = action.payload;
      return { ...state, failedFiles }
    }
    case 'SET_ALL_MODULES_FINISHED': {
      const allModulesFinished = true;
      return { ...state, allModulesFinished }
    }
    case 'SET_RESOURCES_LOADED': {
      const resourcesLoaded = true;
      return { ...state, resourcesLoaded }
    }
    case 'SET_SIGNUP_DATA': {
      const email = action.payload.email.toLowerCase();
      const signupData = { ...action.payload, email };
      return { ...state, signupData }
    }
    case 'CLEAR_SIGNUP_DATA': {
      return { ...state, signupData: undefined }
    }
    case 'SET_USER_DATA': {
      const userData = JSON.stringify(action.payload);
      localStorage.setItem(LOCATION_PROFILE, userData);

      return { ...state, userData }
    }
    case 'SET_LINK_DATA': {
      const linkData = action.payload;
      return { ...state, linkData }
    }
    case 'SET_IS_BEP': {
      const isBEP = action.payload;
      return { ...state, isBEP }
    }
    case 'SET_CAMPAIGN': {
      const campaign = action.payload;
      localStorage.setItem('snd-campaign', campaign);
      return { ...state, campaign }
    }
    case 'SET_CAMPAIGN_PREVIEW_TOKEN': {
      const campaignPreviewToken = action.payload;
      return { ...state, campaignPreviewToken }
    }
    case 'SET_CAMPAIGN_PREVIEW_SCREEN': {
      const campaignPreviewScreen = action.payload;
      return { ...state, campaignPreviewScreen }
    }
    case 'SET_CAMPAIGN_DATA': {
      const campaignData = action.payload;
      const activeModule = getActiveModule(campaignData);

      localStorage.setItem('campaignData', JSON.stringify(campaignData));
      return { ...state, campaignData, activeModule }
    }
    case 'SET_ACTIVE_MODULE': {
      const activeModule = action.payload;

      return { ...state, activeModule }
    }
    case 'SET_CAMPAIGN_DESIGN_IMAGES': {
      let campaignLogo = state.campaignLogo;
      let campaignLogoLanding = state.campaignLogoLanding;
      let campaignBackground = state.campaignBackground;
      let campaignBackgroundLanding = state.campaignBackgroundLanding;

      if (
        action.payload.generalLogoURLFile &&
        action.payload.generalLogoURLFile.uri
      )
        campaignLogo = action.payload.generalLogoURLFile.uri

      if (
        action.payload.landingLogoURLFile &&
        action.payload.landingLogoURLFile.uri
      )
        campaignLogoLanding = action.payload.landingLogoURLFile.uri

      if (
        action.payload.generalBackgroundURLFile &&
        action.payload.generalBackgroundURLFile.uri
      )
        campaignBackground = action.payload.generalBackgroundURLFile.uri

      if (
        action.payload.landingBackgroundURLFile &&
        action.payload.landingBackgroundURLFile.uri
      )
        campaignBackgroundLanding = action.payload.landingBackgroundURLFile.uri

      return {
        ...state,
        campaignLogo,
        campaignLogoLanding,
        campaignBackground,
        campaignBackgroundLanding
      }
    }
    case 'FINISH_ACTIVE_MODULE': {
      const campaignData = state.campaignData;

      const campaignModules = state.campaignData.modules.campaignModules.map(module => {
        if (module === state.activeModule) {
          module.done = true;
        }
        return module;
      });
      campaignData.modules.campaignModules = campaignModules;

      const activeModule = getActiveModule(campaignData);

      return { ...state, campaignData, activeModule, moduleLoaded: false }
    }
    case 'PREV_ACTIVE_MODULE': {
      const campaignData = state.campaignData;

      // if this is undefined, we see thanks screen
      if (!state.activeModule) {
        delete state.campaignData.modules.campaignModules[state.campaignData.modules.campaignModules.length - 1].done
      }

      const campaignModules = state.campaignData.modules.campaignModules.map((module, key) => {
        if (module === state.activeModule && state.campaignData.modules?.campaignModules[key - 1]?.done) {
          delete state.campaignData.modules.campaignModules[key - 1].done;
        }
        return module;
      });
      campaignData.modules.campaignModules = campaignModules;

      const activeModule = getActiveModule(campaignData);

      return { ...state, campaignData, activeModule, moduleLoaded: true }
    }
    case 'GO_TO_MODULE': {
      const modulePosition = action.payload;
      const campaignData = state.campaignData;

      const campaignModules = state.campaignData.modules.campaignModules.map((module, key) => {
        if (key < modulePosition) {
          module.done = true;
        } else {
          delete module.done;
        }
        return module;
      });
      campaignData.modules.campaignModules = campaignModules;

      const activeModule = getActiveModule(campaignData);

      return { ...state, campaignData, activeModule, moduleLoaded: true }
    }
    case 'RESET_MODULES': {
      const campaignData = state.campaignData;

      const campaignModules = state.campaignData.modules.campaignModules.map((module, key) => {
        delete module.done;
        return module;
      });
      campaignData.modules.campaignModules = campaignModules;

      const activeModule = getActiveModule(campaignData);

      return { ...state, campaignData, activeModule, moduleLoaded: true }
    }
    case 'SET_SESSION': {
      const userData = JSON.stringify(action.payload.user);
      const tokenData = action.payload.token;
      const tokenExpiration = action.payload.payload.exp;

      return { ...state, userData, tokenData, tokenExpiration }
    }
    case 'CLEAR_SESSION': {
      const userData = undefined;
      const tokenData = undefined;
      const tokenExpiration = undefined;

      return { ...state, userData, tokenData, tokenExpiration }
    }
    case 'CHECK_LOCATION_TOKEN_EXPIRATION': {
      const { tokenExpiration } = state;
      const tokenExpirated = new Date().getTime() >  1000 * tokenExpiration;

      return { ...state, tokenExpirated }
    }
    case 'SET_EXTERNAL_PROVIDER_ID': {
      const externalProviderID = action.payload;
      localStorage.setItem('snd-external-provider-id', externalProviderID);
      return { ...state, externalProviderID }
    }
    case 'SET_EXTERNAL_PROVIDER': {
      const externalProvider = action.payload;
      return { ...state, externalProvider }
    }
    case 'SET_TESTER_AGE': {
      const testerAge = action.payload;
      return { ...state, testerAge }
    }
    case 'SET_TESTER_GENDER': {
      const testerGender = action.payload;
      return { ...state, testerGender }
    }
    default:
      return state
  }
}

export default user