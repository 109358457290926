import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import default_en from "./config/strings/default_en";
import default_es from "./config/strings/default_es";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      default_en: default_en,
      default_es: default_es,
    },
    lng: 'default_en',
    fallbackLng: "default_en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
