import React from 'react';
import Back from '../components/Back';

import {
  URL_SIGNUP
} from '../config/urls';

class PolicyAndConditions extends React.Component {
  render() {
    const { page } = this.props;
    return (
      <div className="screen policy-and-conditions">
        <div className="float-button-left">
          <Back to={URL_SIGNUP} />
        </div>
        <div className="scroll-content">
          {page === "terms" && (
            <>
              <h1>Términos y condiciones</h1>
              <p>1. Su relación con Sounditi</p>

              <p>1.1 Su utilización del sitio web de Sounditi (el "Sitio Web") y de todos los productos, canales, software, alimentación de datos y servicios de Sounditi estará sujeto a las condiciones de un acuerdo legal entre Ud. y Sounditi. "Sounditi" significa la entidad que opera el servicio en el Espacio Económico Europeo y Suiza, que es Sounditi S.L. una compañía constituida en España y opera bajo las leyes de España, con CIF. B88021969, con domicilio en Plaza Duque de Pastrana 3, Madrid 28036, España.<br />
              1.2 Su acuerdo legal con Sounditi se compone de los términos y condiciones establecidos en este documento.<br />
              1.3 Los Términos y Condiciones constituyen un contrato legalmente vinculante para Ud. y para Sounditi en relación con su utilización de los servicios. Nuestra Política de Privacidad explica cómo tratamos sus datos personales y cómo protegemos su privacidad cuando utilice el Servicio. Es importante que lea los Términos y Condiciones y la Política de Privacidad detenidamente.<br />
              1.4 Los Términos y Condiciones son de aplicación a todos los usuarios del Servicio, incluyendo aquellos usuarios que aporten igualmente Contenido en el Servicio. “Contenido” incluye el texto, software, guiones, gráficos, fotos, sonidos, música, videos, combinaciones audiovisuales, funcionalidades interactivas y otros materiales que Ud. pueda visualizar en, o acceder a través de, o aportar a, el Servicio.<br />
              2. Aceptación de los Términos y Condiciones</p>

              <p>2.1 Para poder utilizar los servicios, deberá aceptar previamente los Términos y Condiciones. Ud. no podrá utilizar los servicios si no acepta los Términos y Condiciones.<br />
              2.2. Ud. reconoce y acepta que Sounditi considerará que su uso de los servicios constituye la aceptación de los Términos y Condiciones a partir del inicio de dicha utilización.<br />
              2.3 Ud. no podrá utilizar los servicios ni podrá aceptar los Términos y Condiciones si (a) no está legalmente capacitado para celebrar un contrato vinculante con Sounditi, o (b) tiene prohibida o legalmente excluida la recepción o utilización de los servicios, en virtud de la legislación de su país de residencia o desde el que accede o utiliza los servicios.<br />
              2.4 Le recomendamos que imprima o guarde en sus archivos una copia local de los Términos y Condiciones.<br />
              3. Objeto<br />
              A través del presente Sitio Web Sounditi le ofrece una serie de servicios de de branding y marketing digital destinados a completar, mejorar e impulsar su estrategia de marketing (en adelante, “Servicios”) consistentes en la creación de proyectos colaborativos basados en el uso de la neurociencia.<br />
              4. Cuentas Sounditi</p>

              <p>4.1 Para poder acceder a determinadas funciones del Sitio Web u otros elementos de los Servicios, tendrá que crear una cuenta Sounditi. Al crear su cuenta, deberá facilitar determinada información correcta y completa. Es importante que mantenga de forma segura y confidencial su contraseña de cuenta Sounditi.<br />
              4.2 Ud. deberá notificar a Sounditi inmediatamente cualquier incumplimiento de las normas de seguridad o cualquier uso no autorizado de su cuenta Sounditi del que tenga conocimiento.<br />
              4.3 Ud. acepta ser el único responsable (frente a Sounditi y a otros terceros) de toda la actividad que tenga lugar en su cuenta Sounditi.<br />
              5. Restricciones generales a la utilización</p>

              <p>5.1 Sounditi le autoriza a acceder y utilizar los Servicios, con sujeción a las condiciones específicas que a continuación se indican, y Ud. acepta que su inobservancia de cualquiera de estas condiciones constituirá un incumplimiento de los presentes Términos y Condiciones por su parte:<br />
              Ud. se obliga a no distribuir ninguna parte del Sitio Web o de los Servicios, incluyendo a título enunciativo, que no limitativo, el Contenido, a través de ningún medio sin la previa autorización por escrito de Sounditi salvo en caso de que Sounditi ponga a su disposición los medios para dicha distribución a través de una funcionalidad ofrecida por el Servicio (tal como el Visualizador de imágenes o el grabador de video de Sounditi);<br />
              Ud. se obliga a no alterar o modificar ninguna parte del Sitio Web o de los Servicios (incluyendo a título enunciativo, que no limitativo, el Visualizador de imágenes, el grabador de video y sus tecnologías relacionadas);<br />
              Ud. se obliga a no acceder a los Contenidos utilizando cualquier tecnología o medio distintos de las páginas de visualización de imagenes del propio Sitio Web, el grabador de video de Sounditi o cualquier otro medio que Sounditi designe explícitamente a tal efecto;<br />
              Ud. se obliga a no eludir, desactivar o manipular de cualquier otra forma (o tratar de eludir, desactivar o manipular) las funciones de seguridad de los Servicios u otras funciones que (i) impidan o restrinjan la utilización o copia de los Contenidos, o (ii) que aplican las limitaciones a la utilización de los Servicios o de los contenidos que se ofrecen a través de los Servicios;<br />
              Ud. se obliga a no utilizar o activar ningún sistema automatizado (incluyendo, sin limitación alguna, cualquier robot, araña ("spider") o lector offline que acceda al Servicio de modo que envíe más mensajes de solicitud a los servidores de Sounditi en un intervalo de tiempo concreto de los que una persona física podría razonablemente producir en el mismo intervalo de tiempo utilizando un navegador web públicamente disponible y general (es decir, sin modificar);<br />
              Ud. se obliga a no recabar datos personales de ningún usuario del Sitio Web o los Servicios (incluyendo los nombres de cuenta Sounditi);<br />
              Ud. se obliga a no utilizar el Sitio Web o los Servicios (incluyendo los comentarios y las funciones de e-mail del Sitio Web) para ofrecer servicios en relación con una actividad comercial;<br />
              Ud. se obliga a no acceder al Contenido para cualquier finalidad distinta al uso personal, no comercial únicamente conforme a lo pretendido y permitido por el normal funcionamiento de los Servicios.<br />
              5.2 Ud. se obliga a cumplir con todas las demás disposiciones de los Términos y Condiciones y de las Directrices de la Comunidad Sounditi en todo momento mientras utilice los Servicios.<br />
              5.3 Sounditi autoriza a los operadores de motores de búsqueda públicos a utilizar arañas ("spiders") para copiar materiales del sitio con el único fin de crear índices de búsqueda de los materiales disponibles para el público en general, si bien dichos materiales no podrán ser almacenados en memoria caché u otros archivos. Sounditi se reserva el derecho a revocar estas excepciones de forma general o en casos específicos.<br />
              5.4 Sounditi innova de forma constante con el fin de ofrecer a sus usuarios la mejor experiencia posible. Ud. reconoce y acepta que la forma y naturaleza de los Servicios prestados por Sounditi podrá variar en cualquier momento sin necesidad de preaviso.<br />
              5.5 Como parte de este proceso de innovación constante, Ud. reconoce y acepta que Sounditi podrá necesitar interrumpir (de forma permanente o temporal) la prestación de los Servicios (o de cualquier función de éstos) a Ud. o a los usuarios en general,como consecuencia de requisitos operacionales tales como aquellos relacionados con la seguridad de los Servicios, su seguridad como usuario, o a necesidades de mantenimiento de los Servicios. Ud. podrá dejar de utilizar los Servicios en cualquier momento y no vendrá obligado a informar de ello específicamente a Sounditi.<br />
              5.6 Ud. reconoce ser el único responsable (sin que Sounditi asuma responsabilidad alguna frente a Ud. o a cualquier tercero para) del incumplimiento de sus obligaciones previstas en los Términos y Condiciones y de las consecuencias (incluyendo cualesquiera pérdidas o daños en que Sounditi pueda incurrir) derivadas de dicho incumplimiento.<br />
              6. Contenido</p>

              <p>6.1 Como titular de una cuenta Sounditi, Ud. Podrá enviar Contenido a Sounditi . Ud. entiende que con independencia de que dicho Contenido sea publicado, Sounditi no garantiza ningún tipo de confidencialidad con respecto a Contenido.<br />
              6.2 Ud. seguirá siendo el titular de los derechos relativos a su Contenido, si bien deberá otorgar unos derechos de licencia limitados a favor de Sounditi y otros usuarios de los Servicios. Estos derechos se describen en el epígrafe 7 de los presentes Términos y Condiciones (Derechos que Ud. otorga bajo licencia).<br />
              6.3 Ud. reconoce y acepta ser el único responsable de su Contenido y de las consecuencias de su publicación. Sounditi no suscribe ningún Contenido ni ninguna opinión, recomendación o consejo expresado en relación con el mismo, excluyendo expresamente toda responsabilidad relativa al Contenido.<br />
              6.4 Ud. manifiesta y garantiza que es titular (y que lo seguirá siendo mientras utilice los Servicios) de todas licencias y todos los derechos, consentimientos y permisos que se necesitan para permitir a Sounditi utilizar su Contenido a efectos de la prestación del Servicio y para cualquier otro fin con arreglo a la forma contemplada en el Servicio y en los presentes Términos y Condiciones.<br />
              6.5 Ud. se obliga a no publicar o cargar ningún Contenido que contenga material cuya posesión sea ilegal para Ud. en su país de residencia, o cuya utilización o posesión sea ilegal para Sounditi en relación con la prestación de los Servicios.<br />
              6.6 Ud. se obliga a que el Contenido que Ud. proporcional al Servicio no contendrá material sujeto a derechos de propiedad intelectual de terceros o material sujeto a otros derechos de propiedad industrial de terceros(incluyendo derechos de exclusividad o publicidad), salvo que el titular legal de dichos derechos le haya otorgado una licencia o autorización formal, o está legalmente autorizado de cualquier otra forma, para publicar el material en cuestión y para otorgar a Sounditi la licencia indicada en el epígrafe 8.1 siguiente.<br />
              6.7 Al tener conocimiento de cualquier incumplimiento de los éstos Términos y Condiciones, Sounditi se reserva el derecho (si bien no estará obliga) a decidir si el Contenido cumple con los requisitos de contenido estipulados en los presentes Términos y Condiciones, y podrá eliminar dicho Contenido y/o cancelar el acceso de cualquier Usuario para cargar Contenido que infrinja los presentes Términos y Condiciones en cualquier momento, sin necesidad de preaviso y a su elección exclusiva.<br />
              6.8 Ud. renuncia a ejercitar cualquier derecho o acción legal o en equidad que pueda corresponderle contra Sounditi con respecto a cualquier Contenido.<br />
              7. Derechos que Ud. otorga bajo licencia</p>

              <p>7.1 Al cargar o publicar Contenido en Sounditi, Ud. estará otorgando:<br />
              a favor de Sounditi, una licencia mundial, no exclusiva, exenta de royalties y transferible (con derecho de sub-licencia) para utilizar, reproducir, distribuir, realizar obras derivadas de, mostrar y ejecutar ese Contenido en relación con la prestación de los Servicios y con el funcionamiento del Servicio y de la actividad de Sounditi, incluyendo sin limitación alguna, a efectos de promoción y redistribución de la totalidad o de una parte del Servicio (y de sus obras derivadas) en cualquier formato y a través de cualquier canal de comunicación;<br />
              7.2 Las anteriores licencias otorgadas por Ud. con respecto a los Videos de Usuario quedarán canceladas cuando Ud. elimine o borre sus Videos de Usuario del Sitio Web. Las anteriores licencias otorgadas por Ud. con respecto a los Comentarios de Usuario son de carácter perpetuo e irrevocable, sin perjuicio de sus derechos de propiedad, cuya titularidad le corresponderá a Ud. en todo momento conforme a lo estipulado en el epígrafe 6.2 anterior.<br />
              8. Contenidos de Sounditi en el Sitio Web</p>

              <p>8.1 A excepción del Contenido proporcionado al Servicio por Ud., todos los demás Contenidos del Servicio serán propiedad de Sounditi, o le corresponderán en virtud de una licencia, y estarán sujetos a derechos de autor, derechos de marca y otros derechos de propiedad intelectual e industrial de Sounditi o de los licenciantes de Sounditi. Todas las marcas comerciales y de servicio de otros terceros presentes en los Contenidos no subidos o publicados por Ud. serán marcas comerciales y de servicio de sus respectivos propietarios.<br />
              8.2 Dicho Contenidos no podrán ser descargados, copiados, reproducidos, distribuidos, transmitidos, difundidos, mostrados, vendidos, otorgados bajo licencia o explotados para ningún otro fin sin el previo consentimiento por escrito de Sounditi, o de los licenciantes de Sounditi, en caso de que fuera aplicable. Sounditi y sus licenciantes se reservan todos los derechos que no hayan sido expresamente otorgados con respecto a su Contenido.<br />
              9. Vínculos en Sounditi</p>

              <p>9.1 Los Servicios podrán incluir hipervínculos a otros sitios web que no serán propiedad de Sounditi ni estarán controlados por ésta. Sounditi no controla los contenidos, las políticas de privacidad o las prácticas de los sitios web de otros terceros ni asume responsabilidad alguna por éstos.<br />
              9.2 Ud. reconoce y acepta que Sounditi no será responsable de la disponibilidad de los sitios web o recursos externos, y no suscribe ningún tipo de publicidad, productos u otros materiales ofrecidos a través dichos sitios web o recursos.<br />
              9.3 Ud. reconoce y acepta que Sounditi no será responsable de las pérdidas o daños en que Ud. pueda incurrir a consecuencia de la disponibilidad de los mencionados sitios web o recursos externos, o a consecuencia de la credibilidad que Ud. otorgue a la exhaustividad, precisión o existencia de cualquier tipo de publicidad, productos u otros materiales ofrecidos a través de dicho sitios web o recursos.<br />
              9.4 Sounditi recomienda que preste atención cuando Ud. abandone los Servicios y que lea los términos y condiciones y la política de privacidad de todos los demás sitios web que visite.<br />
              10. Finalización de su relación con Sounditi</p>

              <p>10.1 Los Términos y Condiciones seguirán siendo de aplicación hasta su resolución a instancias de Ud. o de Sounditi conforme a las disposiciones siguientes.<br />
              10.2 Si desea resolver su contrato legal con Sounditi, podrá hacerlo (a) notificándolo a Sounditi en cualquier momento y (b) cerrando su cuenta Sounditi. Su notificación deberá ser enviada, por escrito, a la dirección de Sounditi indicada al inicio de los presentes Términos y Condiciones.<br />
              10.3 Sounditi podrá resolver su contrato legal con Ud. en cualquier momento si:<br />
              Ud. incumple cualquiera de las disposiciones incluidas en los Términos y Condiciones (o actúa de forma que claramente indique que pretende hacerlo o que no será capaz de cumplir con lo estipulado en los Términos y Condiciones); o<br />
              Sounditi viene obligada a ello por Ley (por ejemplo, cuando la prestación de los Servicios a Ud. sea, o pase a ser, ilegal).<br />
              10.4 Sounditi podrá resolver su contrato legal con Ud. si:<br />
              Sounditi deja de prestar los Servicios a los usuarios de su país de residencia o desde el que Ud. utiliza los Servicios; o<br />
              la prestación de los Servicios a Ud. por parte de Sounditi, deja de ser, en opinión de ésta, comercialmente viable.<br />
              y en el caso de cada uno de los supuestos (1) y (2) de la cláusula 10.4, deberá notificarle razonablemente sobre dicha resolución.<br />
              10.5 A la resolución de los presentes Términos y Condiciones, todos los derechos y obligaciones legales que correspondan tanto a Ud. como a Sounditi (o que se hayan devengado durante la vigencia de los Términos y Condiciones), o cuya vigencia deba continuar expresamente de forma indefinida, no se verán afectados por dicha resolución, y las disposiciones incluidas en el epígrafe 13.5 seguirán siendo de aplicación a dichos derechos y obligaciones con carácter indefinido.<br />
              11. Exclusión de Garantías</p>

              <p>11.1 Nada de lo estipulado en los Términos y Condiciones afectará a los derechos legales que le asistirán en todo momento como consumidor y con respecto a los cuales no podrá pactar contractualmente ninguna modificación o renuncia.<br />
              11.2 Sounditi ha realizado los esfuerzos necesarios para que la información, documentación y comentarios incluidos en el Sitio Web sean lo más exactos y correctos posible, y permitan ser de ayuda para Ud. No obstante, deben ser considerados en todo caso por Ud. como orientativos. En consecuencia, Sounditi no garantiza ni se responsabiliza de la existencia de errores en dichos contenidos, ni se hace responsable, en ningún caso, de su uso por su parte o de la falta de utilidad de estos.<br />
              11.3 En particular, Sounditi no le manifiesta ni garantiza que:<br />
              su utilización de los Servicios cumplirá sus requisitos,<br />
              su utilización de los Servicios será ininterrumpida, puntual, segura o libre de errores,<br />
              la información que obtenga como consecuencia de su utilización de los Servicios será correcta o fiable, y<br />
              se corregirán los defectos del funcionamiento o de las funcionalidades de cualquier software que se le proporcione como parte de los Servicios.<br />
              no existan virus, programas maliciosos o lesivos en el Sitio Web.<br />
              los casos de fuerza mayor, entendidos tales como el fallo, suspensión o interrupción de los Servicios o utilización del Sitio Web como consecuencia de las restricciones de energía, el bloqueo de las telecomunicaciones o de la red de Internet, acciones u omisiones de terceras personas, o cualesquiera otras causas o circunstancias independientes de la voluntad de Sounditi que impidan la utilización normal del Sitio Web.<br />
              11.4 No serán de aplicación a los Servicios ninguna condición o garantía (incluyendo cualesquiera garantías implícitas de calidad satisfactoria y adecuación a un fin concreto o a una descripción) excepto en la medida de lo expresamente estipulado en los Términos y Condiciones.<br />
              12. Limitación de Responsabilidad</p>

              <p>12.1 Nada de lo estipulado en los presentes Términos y Condiciones excluirá o limitará la responsabilidad de Sounditi por pérdidas que no pueda ser legalmente excluidas o limitadas en virtud de la legislación aplicable.<br />
              12.2 Con sujeción a la disposición general incluida en el epígrafe 12.1 anterior, Sounditi no será responsable frente a Ud. por:<br />
              pérdidas indirectas o derivadas en que Ud. pueda incurrir, incluyéndose cualesquiera pérdidas de beneficios (con independencia de que se hayan originado de forma directa o indirecta), pérdidas de fondo de negocio o prestigio empresarial, o pérdidas de datos sufridas por Ud.;<br />
              pérdidas o daños en que Ud. pueda incurrir a consecuencia de:<br />
              la credibilidad que Ud. otorgue a la exhaustividad, precisión o existencia de cualquier material publicitario, o a consecuencia de cualquier relación u operación entre Ud. y cualquier publicista o patrocinador cuya publicidad aparezca en los Servicios;<br />
              cualquier cambio que Sounditi introduzca en relación con los Servicios, o cualquier cese permanente o temporal de la prestación de los Servicios (o de cualquiera de sus funciones);<br />
              la eliminación, corrupción o fallo de almacenamiento de cualquier Contenido u otros datos de comunicación mantenidos o transmitidos a través de su utilización de los Servicios;<br />
              su falta de suministro a Sounditi de información de cuenta precisa;<br />
              su falta de mantenimiento de la seguridad y confidencialidad de su contraseña o información de cuenta Sounditi.<br />
              12.3 Las limitaciones a la responsabilidad de Sounditi frente a Ud. incluidas en el epígrafe 12.2 anterior serán de aplicación con independencia de que Sounditi haya sido advertida o debiera haber sido advertida de la posibilidad del acaecimiento de dichas pérdidas.<br />
              13. Disposiciones generales</p>

              <p>13.1 Los Términos y Condiciones constituyen el acuerdo legal completo entre Ud. y Sounditi, rigen su utilización de los Servicios y sustituyen a todos los anteriores acuerdos entre Ud. y Sounditi en relación con los Servicios.<br />
              13.2 Ud. acepta que Sounditi podrá enviarle notificaciones, incluyendo las relativas a los cambios de los Términos y Condiciones, mediante e-mail, correo ordinario o publicando dichas modificaciones en el Servicio.<br />
              13.3 Ud. acepta que en el supuesto de que Sounditi no ejercite cualquier derecho o acción legal contemplado en los Términos y Condiciones (o que le corresponda en virtud de la legislación aplicable), ello no constituirá una renuncia formal a los derechos de Sounditi, permaneciendo dichos derechos y acciones legales plenamente vigentes para Sounditi.<br />
              13.4 Todas las cláusulas o extremos de los presentes Términos y Condiciones deben ser interpretadas de forma independiente y autónoma. Si un tribunal con jurisdicción competente sobre este asunto determina que cualquiera de las disposiciones de los presentes Términos y Condiciones es inválida, dicha disposición será eliminada de los Términos y Condiciones sin que ello afecte al resto de los mismos permaneciendo las demás disposiciones plenamente vigentes en todos sus aspectos.<br />
              13.5 Ud. reconoce y acepta que todos los miembros del grupo de sociedades del que Sounditi forma parte serán terceros beneficiarios de los Términos y Condiciones y que dichas sociedades tendrán derecho a exigir, directamente, el cumplimiento de cualquier disposición de los Términos y Condiciones donde se contemple un beneficio para éstos (o algún derecho a su favor). Aparte de las mencionadas sociedades, ninguna otra persona o sociedad será un tercer beneficiario de los Términos y Condiciones.<br />
              13.6 Los presentes Términos y Condiciones están sometidos a la legislación española. Las partes, de conformidad al Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, se someten a los Juzgados y Tribunales del lugar de residencia del Usuario.<br />
              13.7 Los Términos y Condiciones y su relación con Sounditi conforme a lo aquí previsto, se regirán por las leyes de España. Sounditi se reserva el derecho a realizar modificaciones en los Términos y Condiciones en cualquier momento, por ejemplo, para tratar cambios de la ley o normativa o cambios en las funcionalidades ofrecidas a través del Servicio<br />
              La versión modificada de los Términos y Condiciones (los “Términos y Condiciones Modificados”) serán publicados en la página web de Sounditi o puestos a su disposición dentro del Servicio (para cualquier término adicional modificado) Si bien la modificación de los Términos y Condiciones le será previamente comunicada. Si Ud. no está de acuerdo con los Términos y Condiciones Modificados Ud. deberá dejar de utilizar el Servicio. Su uso continuado del Servicio después de que los Términos y Condiciones Modificados hayan sido publicados constituirá su aceptación de los Términos y Condiciones Modificados.</p>
            </>
          )}
          {page === "policy-resume" && (
            <>
              <h1>Resumen política de privacidad</h1>
              <p>1. Su relación con Sounditi</p>

              <p>1.1 Su utilización del sitio web de Sounditi (el "Sitio Web") y de todos los productos, canales, software, alimentación de datos y servicios de Sounditi estará sujeto a las condiciones de un acuerdo legal entre Ud. y Sounditi. "Sounditi" significa la entidad que opera el servicio en el Espacio Económico Europeo y Suiza, que es Sounditi S.L. una compañía constituida en España y opera bajo las leyes de España, con CIF. B88021969, con domicilio en Plaza Duque de Pastrana 3, Madrid 28036, España.<br />
              1.2 Su acuerdo legal con Sounditi se compone de los términos y condiciones establecidos en este documento.<br />
              1.3 Los Términos y Condiciones constituyen un contrato legalmente vinculante para Ud. y para Sounditi en relación con su utilización de los servicios. Nuestra Política de Privacidad explica cómo tratamos sus datos personales y cómo protegemos su privacidad cuando utilice el Servicio. Es importante que lea los Términos y Condiciones y la Política de Privacidad detenidamente.<br />
              1.4 Los Términos y Condiciones son de aplicación a todos los usuarios del Servicio, incluyendo aquellos usuarios que aporten igualmente Contenido en el Servicio. “Contenido” incluye el texto, software, guiones, gráficos, fotos, sonidos, música, videos, combinaciones audiovisuales, funcionalidades interactivas y otros materiales que Ud. pueda visualizar en, o acceder a través de, o aportar a, el Servicio.<br />
              2. Aceptación de los Términos y Condiciones</p>

              <p>2.1 Para poder utilizar los servicios, deberá aceptar previamente los Términos y Condiciones. Ud. no podrá utilizar los servicios si no acepta los Términos y Condiciones.<br />
              2.2. Ud. reconoce y acepta que Sounditi considerará que su uso de los servicios constituye la aceptación de los Términos y Condiciones a partir del inicio de dicha utilización.<br />
              2.3 Ud. no podrá utilizar los servicios ni podrá aceptar los Términos y Condiciones si (a) no está legalmente capacitado para celebrar un contrato vinculante con Sounditi, o (b) tiene prohibida o legalmente excluida la recepción o utilización de los servicios, en virtud de la legislación de su país de residencia o desde el que accede o utiliza los servicios.<br />
              2.4 Le recomendamos que imprima o guarde en sus archivos una copia local de los Términos y Condiciones.<br />
              3. Objeto<br />
              A través del presente Sitio Web Sounditi le ofrece una serie de servicios de de branding y marketing digital destinados a completar, mejorar e impulsar su estrategia de marketing (en adelante, “Servicios”) consistentes en la creación de proyectos colaborativos basados en el uso de la neurociencia.<br />
              4. Cuentas Sounditi</p>

              <p>4.1 Para poder acceder a determinadas funciones del Sitio Web u otros elementos de los Servicios, tendrá que crear una cuenta Sounditi. Al crear su cuenta, deberá facilitar determinada información correcta y completa. Es importante que mantenga de forma segura y confidencial su contraseña de cuenta Sounditi.<br />
              4.2 Ud. deberá notificar a Sounditi inmediatamente cualquier incumplimiento de las normas de seguridad o cualquier uso no autorizado de su cuenta Sounditi del que tenga conocimiento.<br />
              4.3 Ud. acepta ser el único responsable (frente a Sounditi y a otros terceros) de toda la actividad que tenga lugar en su cuenta Sounditi.<br />
              5. Restricciones generales a la utilización</p>

              <p>5.1 Sounditi le autoriza a acceder y utilizar los Servicios, con sujeción a las condiciones específicas que a continuación se indican, y Ud. acepta que su inobservancia de cualquiera de estas condiciones constituirá un incumplimiento de los presentes Términos y Condiciones por su parte:<br />
              Ud. se obliga a no distribuir ninguna parte del Sitio Web o de los Servicios, incluyendo a título enunciativo, que no limitativo, el Contenido, a través de ningún medio sin la previa autorización por escrito de Sounditi salvo en caso de que Sounditi ponga a su disposición los medios para dicha distribución a través de una funcionalidad ofrecida por el Servicio (tal como el Visualizador de imágenes o el grabador de video de Sounditi);<br />
              Ud. se obliga a no alterar o modificar ninguna parte del Sitio Web o de los Servicios (incluyendo a título enunciativo, que no limitativo, el Visualizador de imágenes, el grabador de video y sus tecnologías relacionadas);<br />
              Ud. se obliga a no acceder a los Contenidos utilizando cualquier tecnología o medio distintos de las páginas de visualización de imagenes del propio Sitio Web, el grabador de video de Sounditi o cualquier otro medio que Sounditi designe explícitamente a tal efecto;<br />
              Ud. se obliga a no eludir, desactivar o manipular de cualquier otra forma (o tratar de eludir, desactivar o manipular) las funciones de seguridad de los Servicios u otras funciones que (i) impidan o restrinjan la utilización o copia de los Contenidos, o (ii) que aplican las limitaciones a la utilización de los Servicios o de los contenidos que se ofrecen a través de los Servicios;<br />
              Ud. se obliga a no utilizar o activar ningún sistema automatizado (incluyendo, sin limitación alguna, cualquier robot, araña ("spider") o lector offline que acceda al Servicio de modo que envíe más mensajes de solicitud a los servidores de Sounditi en un intervalo de tiempo concreto de los que una persona física podría razonablemente producir en el mismo intervalo de tiempo utilizando un navegador web públicamente disponible y general (es decir, sin modificar);<br />
              Ud. se obliga a no recabar datos personales de ningún usuario del Sitio Web o los Servicios (incluyendo los nombres de cuenta Sounditi);<br />
              Ud. se obliga a no utilizar el Sitio Web o los Servicios (incluyendo los comentarios y las funciones de e-mail del Sitio Web) para ofrecer servicios en relación con una actividad comercial;<br />
              Ud. se obliga a no acceder al Contenido para cualquier finalidad distinta al uso personal, no comercial únicamente conforme a lo pretendido y permitido por el normal funcionamiento de los Servicios.<br />
              5.2 Ud. se obliga a cumplir con todas las demás disposiciones de los Términos y Condiciones y de las Directrices de la Comunidad Sounditi en todo momento mientras utilice los Servicios.<br />
              5.3 Sounditi autoriza a los operadores de motores de búsqueda públicos a utilizar arañas ("spiders") para copiar materiales del sitio con el único fin de crear índices de búsqueda de los materiales disponibles para el público en general, si bien dichos materiales no podrán ser almacenados en memoria caché u otros archivos. Sounditi se reserva el derecho a revocar estas excepciones de forma general o en casos específicos.<br />
              5.4 Sounditi innova de forma constante con el fin de ofrecer a sus usuarios la mejor experiencia posible. Ud. reconoce y acepta que la forma y naturaleza de los Servicios prestados por Sounditi podrá variar en cualquier momento sin necesidad de preaviso.<br />
              5.5 Como parte de este proceso de innovación constante, Ud. reconoce y acepta que Sounditi podrá necesitar interrumpir (de forma permanente o temporal) la prestación de los Servicios (o de cualquier función de éstos) a Ud. o a los usuarios en general,como consecuencia de requisitos operacionales tales como aquellos relacionados con la seguridad de los Servicios, su seguridad como usuario, o a necesidades de mantenimiento de los Servicios. Ud. podrá dejar de utilizar los Servicios en cualquier momento y no vendrá obligado a informar de ello específicamente a Sounditi.<br />
              5.6 Ud. reconoce ser el único responsable (sin que Sounditi asuma responsabilidad alguna frente a Ud. o a cualquier tercero para) del incumplimiento de sus obligaciones previstas en los Términos y Condiciones y de las consecuencias (incluyendo cualesquiera pérdidas o daños en que Sounditi pueda incurrir) derivadas de dicho incumplimiento.<br />
              6. Contenido</p>

              <p>6.1 Como titular de una cuenta Sounditi, Ud. Podrá enviar Contenido a Sounditi . Ud. entiende que con independencia de que dicho Contenido sea publicado, Sounditi no garantiza ningún tipo de confidencialidad con respecto a Contenido.<br />
              6.2 Ud. seguirá siendo el titular de los derechos relativos a su Contenido, si bien deberá otorgar unos derechos de licencia limitados a favor de Sounditi y otros usuarios de los Servicios. Estos derechos se describen en el epígrafe 7 de los presentes Términos y Condiciones (Derechos que Ud. otorga bajo licencia).<br />
              6.3 Ud. reconoce y acepta ser el único responsable de su Contenido y de las consecuencias de su publicación. Sounditi no suscribe ningún Contenido ni ninguna opinión, recomendación o consejo expresado en relación con el mismo, excluyendo expresamente toda responsabilidad relativa al Contenido.<br />
              6.4 Ud. manifiesta y garantiza que es titular (y que lo seguirá siendo mientras utilice los Servicios) de todas licencias y todos los derechos, consentimientos y permisos que se necesitan para permitir a Sounditi utilizar su Contenido a efectos de la prestación del Servicio y para cualquier otro fin con arreglo a la forma contemplada en el Servicio y en los presentes Términos y Condiciones.<br />
              6.5 Ud. se obliga a no publicar o cargar ningún Contenido que contenga material cuya posesión sea ilegal para Ud. en su país de residencia, o cuya utilización o posesión sea ilegal para Sounditi en relación con la prestación de los Servicios.<br />
              6.6 Ud. se obliga a que el Contenido que Ud. proporcional al Servicio no contendrá material sujeto a derechos de propiedad intelectual de terceros o material sujeto a otros derechos de propiedad industrial de terceros(incluyendo derechos de exclusividad o publicidad), salvo que el titular legal de dichos derechos le haya otorgado una licencia o autorización formal, o está legalmente autorizado de cualquier otra forma, para publicar el material en cuestión y para otorgar a Sounditi la licencia indicada en el epígrafe 8.1 siguiente.<br />
              6.7 Al tener conocimiento de cualquier incumplimiento de los éstos Términos y Condiciones, Sounditi se reserva el derecho (si bien no estará obliga) a decidir si el Contenido cumple con los requisitos de contenido estipulados en los presentes Términos y Condiciones, y podrá eliminar dicho Contenido y/o cancelar el acceso de cualquier Usuario para cargar Contenido que infrinja los presentes Términos y Condiciones en cualquier momento, sin necesidad de preaviso y a su elección exclusiva.<br />
              6.8 Ud. renuncia a ejercitar cualquier derecho o acción legal o en equidad que pueda corresponderle contra Sounditi con respecto a cualquier Contenido.<br />
              7. Derechos que Ud. otorga bajo licencia</p>

              <p>7.1 Al cargar o publicar Contenido en Sounditi, Ud. estará otorgando:<br />
              a favor de Sounditi, una licencia mundial, no exclusiva, exenta de royalties y transferible (con derecho de sub-licencia) para utilizar, reproducir, distribuir, realizar obras derivadas de, mostrar y ejecutar ese Contenido en relación con la prestación de los Servicios y con el funcionamiento del Servicio y de la actividad de Sounditi, incluyendo sin limitación alguna, a efectos de promoción y redistribución de la totalidad o de una parte del Servicio (y de sus obras derivadas) en cualquier formato y a través de cualquier canal de comunicación;<br />
              7.2 Las anteriores licencias otorgadas por Ud. con respecto a los Videos de Usuario quedarán canceladas cuando Ud. elimine o borre sus Videos de Usuario del Sitio Web. Las anteriores licencias otorgadas por Ud. con respecto a los Comentarios de Usuario son de carácter perpetuo e irrevocable, sin perjuicio de sus derechos de propiedad, cuya titularidad le corresponderá a Ud. en todo momento conforme a lo estipulado en el epígrafe 6.2 anterior.<br />
              8. Contenidos de Sounditi en el Sitio Web</p>

              <p>8.1 A excepción del Contenido proporcionado al Servicio por Ud., todos los demás Contenidos del Servicio serán propiedad de Sounditi, o le corresponderán en virtud de una licencia, y estarán sujetos a derechos de autor, derechos de marca y otros derechos de propiedad intelectual e industrial de Sounditi o de los licenciantes de Sounditi. Todas las marcas comerciales y de servicio de otros terceros presentes en los Contenidos no subidos o publicados por Ud. serán marcas comerciales y de servicio de sus respectivos propietarios.<br />
              8.2 Dicho Contenidos no podrán ser descargados, copiados, reproducidos, distribuidos, transmitidos, difundidos, mostrados, vendidos, otorgados bajo licencia o explotados para ningún otro fin sin el previo consentimiento por escrito de Sounditi, o de los licenciantes de Sounditi, en caso de que fuera aplicable. Sounditi y sus licenciantes se reservan todos los derechos que no hayan sido expresamente otorgados con respecto a su Contenido.<br />
              9. Vínculos en Sounditi</p>

              <p>9.1 Los Servicios podrán incluir hipervínculos a otros sitios web que no serán propiedad de Sounditi ni estarán controlados por ésta. Sounditi no controla los contenidos, las políticas de privacidad o las prácticas de los sitios web de otros terceros ni asume responsabilidad alguna por éstos.<br />
              9.2 Ud. reconoce y acepta que Sounditi no será responsable de la disponibilidad de los sitios web o recursos externos, y no suscribe ningún tipo de publicidad, productos u otros materiales ofrecidos a través dichos sitios web o recursos.<br />
              9.3 Ud. reconoce y acepta que Sounditi no será responsable de las pérdidas o daños en que Ud. pueda incurrir a consecuencia de la disponibilidad de los mencionados sitios web o recursos externos, o a consecuencia de la credibilidad que Ud. otorgue a la exhaustividad, precisión o existencia de cualquier tipo de publicidad, productos u otros materiales ofrecidos a través de dicho sitios web o recursos.<br />
              9.4 Sounditi recomienda que preste atención cuando Ud. abandone los Servicios y que lea los términos y condiciones y la política de privacidad de todos los demás sitios web que visite.<br />
              10. Finalización de su relación con Sounditi</p>

              <p>10.1 Los Términos y Condiciones seguirán siendo de aplicación hasta su resolución a instancias de Ud. o de Sounditi conforme a las disposiciones siguientes.<br />
              10.2 Si desea resolver su contrato legal con Sounditi, podrá hacerlo (a) notificándolo a Sounditi en cualquier momento y (b) cerrando su cuenta Sounditi. Su notificación deberá ser enviada, por escrito, a la dirección de Sounditi indicada al inicio de los presentes Términos y Condiciones.<br />
              10.3 Sounditi podrá resolver su contrato legal con Ud. en cualquier momento si:<br />
              Ud. incumple cualquiera de las disposiciones incluidas en los Términos y Condiciones (o actúa de forma que claramente indique que pretende hacerlo o que no será capaz de cumplir con lo estipulado en los Términos y Condiciones); o<br />
              Sounditi viene obligada a ello por Ley (por ejemplo, cuando la prestación de los Servicios a Ud. sea, o pase a ser, ilegal).<br />
              10.4 Sounditi podrá resolver su contrato legal con Ud. si:<br />
              Sounditi deja de prestar los Servicios a los usuarios de su país de residencia o desde el que Ud. utiliza los Servicios; o<br />
              la prestación de los Servicios a Ud. por parte de Sounditi, deja de ser, en opinión de ésta, comercialmente viable.<br />
              y en el caso de cada uno de los supuestos (1) y (2) de la cláusula 10.4, deberá notificarle razonablemente sobre dicha resolución.<br />
              10.5 A la resolución de los presentes Términos y Condiciones, todos los derechos y obligaciones legales que correspondan tanto a Ud. como a Sounditi (o que se hayan devengado durante la vigencia de los Términos y Condiciones), o cuya vigencia deba continuar expresamente de forma indefinida, no se verán afectados por dicha resolución, y las disposiciones incluidas en el epígrafe 13.5 seguirán siendo de aplicación a dichos derechos y obligaciones con carácter indefinido.<br />
              11. Exclusión de Garantías</p>

              <p>11.1 Nada de lo estipulado en los Términos y Condiciones afectará a los derechos legales que le asistirán en todo momento como consumidor y con respecto a los cuales no podrá pactar contractualmente ninguna modificación o renuncia.<br />
              11.2 Sounditi ha realizado los esfuerzos necesarios para que la información, documentación y comentarios incluidos en el Sitio Web sean lo más exactos y correctos posible, y permitan ser de ayuda para Ud. No obstante, deben ser considerados en todo caso por Ud. como orientativos. En consecuencia, Sounditi no garantiza ni se responsabiliza de la existencia de errores en dichos contenidos, ni se hace responsable, en ningún caso, de su uso por su parte o de la falta de utilidad de estos.<br />
              11.3 En particular, Sounditi no le manifiesta ni garantiza que:<br />
              su utilización de los Servicios cumplirá sus requisitos,<br />
              su utilización de los Servicios será ininterrumpida, puntual, segura o libre de errores,<br />
              la información que obtenga como consecuencia de su utilización de los Servicios será correcta o fiable, y<br />
              se corregirán los defectos del funcionamiento o de las funcionalidades de cualquier software que se le proporcione como parte de los Servicios.<br />
              no existan virus, programas maliciosos o lesivos en el Sitio Web.<br />
              los casos de fuerza mayor, entendidos tales como el fallo, suspensión o interrupción de los Servicios o utilización del Sitio Web como consecuencia de las restricciones de energía, el bloqueo de las telecomunicaciones o de la red de Internet, acciones u omisiones de terceras personas, o cualesquiera otras causas o circunstancias independientes de la voluntad de Sounditi que impidan la utilización normal del Sitio Web.<br />
              11.4 No serán de aplicación a los Servicios ninguna condición o garantía (incluyendo cualesquiera garantías implícitas de calidad satisfactoria y adecuación a un fin concreto o a una descripción) excepto en la medida de lo expresamente estipulado en los Términos y Condiciones.<br />
              12. Limitación de Responsabilidad</p>

              <p>12.1 Nada de lo estipulado en los presentes Términos y Condiciones excluirá o limitará la responsabilidad de Sounditi por pérdidas que no pueda ser legalmente excluidas o limitadas en virtud de la legislación aplicable.<br />
              12.2 Con sujeción a la disposición general incluida en el epígrafe 12.1 anterior, Sounditi no será responsable frente a Ud. por:<br />
              pérdidas indirectas o derivadas en que Ud. pueda incurrir, incluyéndose cualesquiera pérdidas de beneficios (con independencia de que se hayan originado de forma directa o indirecta), pérdidas de fondo de negocio o prestigio empresarial, o pérdidas de datos sufridas por Ud.;<br />
              pérdidas o daños en que Ud. pueda incurrir a consecuencia de:<br />
              la credibilidad que Ud. otorgue a la exhaustividad, precisión o existencia de cualquier material publicitario, o a consecuencia de cualquier relación u operación entre Ud. y cualquier publicista o patrocinador cuya publicidad aparezca en los Servicios;<br />
              cualquier cambio que Sounditi introduzca en relación con los Servicios, o cualquier cese permanente o temporal de la prestación de los Servicios (o de cualquiera de sus funciones);<br />
              la eliminación, corrupción o fallo de almacenamiento de cualquier Contenido u otros datos de comunicación mantenidos o transmitidos a través de su utilización de los Servicios;<br />
              su falta de suministro a Sounditi de información de cuenta precisa;<br />
              su falta de mantenimiento de la seguridad y confidencialidad de su contraseña o información de cuenta Sounditi.<br />
              12.3 Las limitaciones a la responsabilidad de Sounditi frente a Ud. incluidas en el epígrafe 12.2 anterior serán de aplicación con independencia de que Sounditi haya sido advertida o debiera haber sido advertida de la posibilidad del acaecimiento de dichas pérdidas.<br />
              13. Disposiciones generales</p>

              <p>13.1 Los Términos y Condiciones constituyen el acuerdo legal completo entre Ud. y Sounditi, rigen su utilización de los Servicios y sustituyen a todos los anteriores acuerdos entre Ud. y Sounditi en relación con los Servicios.<br />
              13.2 Ud. acepta que Sounditi podrá enviarle notificaciones, incluyendo las relativas a los cambios de los Términos y Condiciones, mediante e-mail, correo ordinario o publicando dichas modificaciones en el Servicio.<br />
              13.3 Ud. acepta que en el supuesto de que Sounditi no ejercite cualquier derecho o acción legal contemplado en los Términos y Condiciones (o que le corresponda en virtud de la legislación aplicable), ello no constituirá una renuncia formal a los derechos de Sounditi, permaneciendo dichos derechos y acciones legales plenamente vigentes para Sounditi.<br />
              13.4 Todas las cláusulas o extremos de los presentes Términos y Condiciones deben ser interpretadas de forma independiente y autónoma. Si un tribunal con jurisdicción competente sobre este asunto determina que cualquiera de las disposiciones de los presentes Términos y Condiciones es inválida, dicha disposición será eliminada de los Términos y Condiciones sin que ello afecte al resto de los mismos permaneciendo las demás disposiciones plenamente vigentes en todos sus aspectos.<br />
              13.5 Ud. reconoce y acepta que todos los miembros del grupo de sociedades del que Sounditi forma parte serán terceros beneficiarios de los Términos y Condiciones y que dichas sociedades tendrán derecho a exigir, directamente, el cumplimiento de cualquier disposición de los Términos y Condiciones donde se contemple un beneficio para éstos (o algún derecho a su favor). Aparte de las mencionadas sociedades, ninguna otra persona o sociedad será un tercer beneficiario de los Términos y Condiciones.<br />
              13.6 Los presentes Términos y Condiciones están sometidos a la legislación española. Las partes, de conformidad al Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, se someten a los Juzgados y Tribunales del lugar de residencia del Usuario.<br />
              13.7 Los Términos y Condiciones y su relación con Sounditi conforme a lo aquí previsto, se regirán por las leyes de España. Sounditi se reserva el derecho a realizar modificaciones en los Términos y Condiciones en cualquier momento, por ejemplo, para tratar cambios de la ley o normativa o cambios en las funcionalidades ofrecidas a través del Servicio<br />
              La versión modificada de los Términos y Condiciones (los “Términos y Condiciones Modificados”) serán publicados en la página web de Sounditi o puestos a su disposición dentro del Servicio (para cualquier término adicional modificado) Si bien la modificación de los Términos y Condiciones le será previamente comunicada. Si Ud. no está de acuerdo con los Términos y Condiciones Modificados Ud. deberá dejar de utilizar el Servicio. Su uso continuado del Servicio después de que los Términos y Condiciones Modificados hayan sido publicados constituirá su aceptación de los Términos y Condiciones Modificados.</p>
            </>
          )}
          {page === "policy" && (
            <>
              <h1>Política de privacidad</h1>
              <p>SOUNDITI, S.L., (en adelante, el “TITULAR”) informa, a través de la presente Política de Cookies, acerca de la utilización de dispositivos de almacenamiento y recuperación de datos en equipos terminales de los Usuarios.</p>
              <h3>1.- ¿Qué son las cookies?</h3>
              <p>Las cookies son archivos o ficheros que se descargan en el ordenador/smartphone/tablet del Usuario al acceder a determinadas páginas web y aplicaciones que permiten almacenar preferencias de la navegación del Usuario, haciendo que la interacción entre el Usuario y el Sitio Web sea más rápida y fácil.</p>
              <p>La información recogida por las cookies es anónima y no contiene ninguna información sensible ya que no recogen datos que puedan identificar personalmente al Usuario. En cualquier caso, el Usuario podrá acceder a la configuración de su navegador para modificar y/o bloquear la instalación de las Cookies enviadas desde el Sitio Web, sin que ello impida al acceso a los contenidos.</p>
              <h3>2.- ¿Para qué utiliza el TITULAR las cookies?</h3>
              <p>De conformidad con en el Considerando 30 del Reglamento General de Protección de Datos (“RGPD”), el presente Sitio Web utiliza cookies u otros dispositivos de almacenamiento y recuperación de información para realizar un seguimiento de las interacciones de los Usuarios con los servicios ofrecidos en el Sitio Web.</p>
              <p>Las cookies permiten reconocer el navegador de un Usuario, así como el tipo de dispositivo desde el que se accede a el Sitio Web, y se utilizan para facilitar la próxima visita del Usuario y hacer que el Sitio Web resulte más útil.</p>
              <p>El TITULAR utiliza las cookies para:</p>
              <ul>
                <li>1.	Olitimizar la navegación del Usuario mediante el seguimiento de la sesión.</li>
                <li>2.	Recopilar información para optimizar la navegación y mejorar el Sitio Web.</li>
              </ul>
              <p>El Sitio Web utiliza los siguientes tipos de cookies:</p>
              <ul>
                <li>Obligatorias: Cookies estrictamente necesarias para prestar un servicio o contenido solicitado por el usuario. Éstas se encargan de aspectos técnicos como identificar registros y gestionar preferencias de herramientas en el uso, interacción y localización del acceso a los contenidos.</li>
                <li>Permitir la identificación anónima de los Usuarios navegantes y por lo tanto la contabilización aproximada del número de visitantes.</li>
                <li>Identificar de forma anónima los contenidos más visitados.</li>
                <li>Saber si el Usuario que está accediendo es nuevo o repite visita.</li>
              </ul>

              {/*
              Nombre
              Finalidad
              Caducidad
              Proveedor

              sounditi-token
              Identificar al usuario en los servidores de sounditi
              1 semana
              Sounditi S.L.

              sounditi-profile
              Acceso rápido a la información pública del perfil sounditi.
              1 semana
              Sounditi S.L.
              sounditi-expiration
              Acceso rápido a la fecha en la que los tokens expiran.
              1 semana
              Sounditi S.L.
              _snd_a-*
              Esta Cookie se usa para distinguir la session del usuario
              1 semana
              Sounditi S.L.
              _snd_g-*
              Esta Cookie se usa para distiguir los dispositivos.
              1 semana
              Sounditi S.L.
              _snd_lead-*
              Esta Cookie se usa para distinguir la procedencia del usuario.
              1 semana
              Sounditi S.L.
              */}

              <table>
                <tr>
                  <th>Nombre</th>
                  <th>Finalidad</th>
                  <th>Caducidad</th>
                  <th>Proveedor</th>
                </tr>
                <tr>
                  <td>sounditi-token</td>
                  <td>Identificar al usuario en los servidores de sounditi</td>
                  <td>1 semana</td>
                  <td>Sounditi S.L.</td>
                </tr>
                <tr>
                  <td>sounditi-profile</td>
                  <td>Acceso rápido a la información pública del perfil sounditi.</td>
                  <td>1 semana</td>
                  <td>Sounditi S.L.</td>
                </tr>
                <tr>
                  <td>sounditi-expiration</td>
                  <td>Acceso rápido a la fecha en la que los tokens expiran</td>
                  <td>1 semana</td>
                  <td>Sounditi S.L.</td>
                </tr>
                <tr>
                  <td>_snd_a-*</td>
                  <td>Esta Cookie se usa para distinguir la session del usuario</td>
                  <td>1 semana</td>
                  <td>Sounditi S.L.</td>
                </tr>
                <tr>
                  <td>_snd_g-*</td>
                  <td>Esta Cookie se usa para distiguir los dispositivos.</td>
                  <td>1 semana</td>
                  <td>Sounditi S.L.</td>
                </tr>
                <tr>
                  <td>_snd_lead-*</td>
                  <td>Esta Cookie se usa para distinguir la procedencia del usuario.</td>
                  <td>1 semana</td>
                  <td>Sounditi S.L.</td>
                </tr>
              </table>

              <h3>4.- ¿Cómo deshabilitar las cookies?</h3>
              <p>Aquellas cookies que no sean obligatorias para la navegación del presente Sitio Web podrán deshabilitarse en la configuración del Sitio Web.</p>
              <p>Estos ajustes se encuentran en el pie de página del Sitio Web. Además, todos los navegadores permiten hacer cambios para desactivar la configuración de las cookies.</p>
              <p>Este es el motivo por el que la mayoría de navegadores ofrecen la posibilidad de administrar las cookies, para obtener un control más preciso sobre la privacidad.</p>
              <p>Estos ajustes se encuentran ubicados en las "opciones" o "preferencias" del menú de su navegador.</p>
              <p>A continuación, podrá encontrar los links de cada navegador para deshabilitar las cookies siguiendo las instrucciones:</p>
              <ul>
                <li>
                  <h3>Internet Explorer (https://goo.gl/iU2wh2 )</h3>
                  <ul>
                    <li>En el menú de herramientas, selecciones “Opciones de Internet”.</li>
                    <li>Haga clic en la pestaña de privacidad.</li>
                    <li>Podrá configurar la privacidad con un cursor con seis posiciones que le permite controlar la cantidad de cookies que se instalarán: Bloquear todas las cookies, Alta, Media Alto, Media (nivel por defecto), Baja y Aceptar todas las cookies.</li>
                  </ul>
                </li>
                <li>
                  <h3>Mozilla Firefox (http://goo.gl/QXWYmv)</h3>
                  <ul>
                    <li>En la parte superior de la venta de Firefox hacer clic en el menú Herramientas.</li>
                    <li>Seleccionar Opciones.</li>
                    <li>Seleccionar el panel Privacidad.</li>
                    <li>En la opción Firefox podrá elegir Usar una configuración personalizada de su historial, así como otras cuestiones relacionadas con su Privacidad.</li>
                  </ul>
                </li>
                <li>
                  <h3>Google Chrome (http://goo.gl/fQnkSB)</h3>
                  <ul>
                    <li>Hacer clic en el menú situado en la barra de herramientas.</li>
                    <li>Seleccionar Configuración.</li>
                    <li>Hacer clic en Mostar opciones avanzadas.</li>
                    <li>En la selección “Privacidad” hacer clic en el botón Configuración de contenido.</li>
                    <li>En la selección de Cookies, se pueden configurar las opciones.</li>
                  </ul>
                </li>
                <li>
                  <h3>Safari (https://goo.gl/PcjEm3; https://goo.gl/dQywEo)</h3>
                  <ul>
                    <li>En el menú de configuración seleccione la opción de “Preferencias”.</li>
                    <li>Abra la pestaña de privacidad.</li>
                    <li>Seleccione la opción que quiera de la sección de “bloquear cookies”.</li>
                    <li>Recuerde que ciertas funciones y la plena funcionalidad del Sitio Web pueden no estar disponibles después de deshabilitar las cookies.</li>
                  </ul>
                </li>
              </ul>
              <p>Si no desea ser rastreado por las cookies, Google ha desarrollado un complemento para instalar en su navegador al que puede acceder en el siguiente enlace:  http://goo.gl/up4ND.</p>

              <h3>5.- Cookies en los dispositivos móviles</h3>
              <p>El titular del Sitio Web también usa cookies u otros dispositivos de almacenamiento en dispositivos móviles.</p>
              <p>Aquellas cookies que no sean obligatorias para la navegación del presente Sitio Web podrán deshabilitarse en la configuración del Sitio Web.</p>
              <p>Estos ajustes se encuentran en el pie de página del Sitio Web. Además, al igual que sucede en los navegadores de ordenadores, lo navegadores de los dispositivos móviles permiten realizar cambios en las opciones o ajustes de privacidad para desactivar o eliminar las cookies.</p>
              <p>Si desea modificar las opciones de privacidad siga las instrucciones especificadas por el desarrollador de su navegador para dispositivo móvil.</p>
              <p>A continuación, podrá encontrar algunos ejemplos de los links que le guiarán para modificar las opciones de privacidad en su dispositivo móvil:</p>
              <ul>
                <li>IOS: (http://goo.gl/61xevS)</li>
                <li>Windows Phone: (https://goo.gl/tKyb0y)</li>
                <li>Chrome Mobile: (http://goo.gl/XJp7N)</li>
                <li>Opera Mobile: (http://goo.gl/Nzr8s7)</li>
              </ul>
              <h3>6.- Aceptación de cookies</h3>
              <p>El presente Sitio Web instala nuestras cookies en los dispositivos de los usuarios una vez que se genera una cuenta de usuario.</p>
              <p>Le informamos que en el caso de no aceptar la instalación de cookies o de deshabilitar alguna de estas en la configuración, es posible que ciertos servicios no estén disponibles sin la utilización de estas o que no pueda acceder a determinados servicios ni tampoco aprovechar por completo todo lo que este Sitio Web le ofrece.</p>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default PolicyAndConditions