import React from 'react';
import { connect } from 'react-redux';
import Link from '../components/Link';
import DangerIcon from '../assets/icon-warning-big.png';
import T from '../components/Translate';

import { setFullWidth } from '../reducers/navigation';

import {
  URL_CAMERA_CALIBRATION
} from '../config/urls';

import 'react-activity/dist/react-activity.css';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setFullWidth: val => dispatch(setFullWidth(val)),
})

class DesktopDisclaimer extends React.Component {
  componentWillMount() {
    const { setFullWidth } = this.props;

    setFullWidth(false);
  }

  render() {
    return (
      <div className="screen ios-disclaimer instructions">
        <div className="screen-inner-wrapper">
          <div className="flexible-top">
            <div className="flexible-center">
              <div>
                {/* <img className="icon-camera-big" src={DangerIcon} alt="Camera icon" /> */}
                <h1><T text="DesktopIncompatibleDisclaimer_Title" /></h1>
                <p><T text="DesktopIncompatibleDisclaimer_Description" /></p>
                <a href="https://www.google.com/chrome/" target="_blank"><T text="DesktopIncompatibleDisclaimer_Link" /></a>
              </div>
            </div>
          </div>
          {/*
          <div className="fixed-bottom">
            {/* <Link to={URL_CAMERA_CALIBRATION}><T text="IOSDisclaimer_CTA" /></Link>
          </div>
          */}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DesktopDisclaimer)
